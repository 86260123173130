import React, { useState, useEffect } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { TabPanel, TabList, Tabs, Tab } from "react-tabs";

import Offcanvas from "react-bootstrap/Offcanvas";
import {
  getComplaintsList,
  compaintStatusUpdate,
} from "../../action/complaints";
import Pagination from "../../components/pagination/paginationView";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { Loader } from "../../components/loader";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { SingleErrorMessage } from "../singleErrorMessage";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { complaintStatusEnum } from "../../enumTypes/enums";
import { getEnumIndexByValue, getKeyByValue } from "../../helper/helper";
import { Link } from "react-router-dom";

const ComplaintList = () => {
  const payloadAcceptComplaint = {
    status: getKeyByValue(complaintStatusEnum, complaintStatusEnum.ACCEPT),
    estimatedTime: "",
  };
  const payloadCompleteComplaint = {
    status: getKeyByValue(complaintStatusEnum, complaintStatusEnum.COMPLETE),
    remark: "",
  };
  const payloadRejectComplaint = {
    status: getKeyByValue(complaintStatusEnum, complaintStatusEnum.REJECT),
    remark: "",
  };

  const [complaintList, setComplaintList] = useState({
    loading: false,
    data: [],
    totalRecords: 0,
  });
  const [acceptStatus, setAcceptStatus] = useState({
    showModel: false,
    loading: false,
    payload: payloadAcceptComplaint,
    id: "",
  });
  const [showImage, setShowImage] = useState(null);
  const [completeStatus, setCompleteStatus] = useState({
    showModel: false,
    loading: false,
    payload: payloadCompleteComplaint,
    id: "",
  });
  const [rejectStatus, setRejectStatus] = useState({
    showModel: false,
    loading: false,
    payload: payloadRejectComplaint,
    id: "",
  });
  const [error, setError] = useState({ message: "", status: "" });
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const params = useParams();
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const getComplaintList = async (status) => {
    setComplaintList((k) => ({
      ...k,
      loading: true,
    }));
    const response = await getComplaintsList(
      pageSize,
      pageNo,
      getKeyByValue(complaintStatusEnum, status)
    );
    if (response.status) {
      setComplaintList((k) => ({
        ...k,
        loading: false,
        data: response.data.list,
        totalRecords: response.data.count,
      }));
    }
  };
  // getKeyByValue(complaintStatusEnum, status)
  const handleUpdateStatus = (id, handler) => {
    handler((k) => ({
      ...k,
      showModel: true,
      id,
    }));
  };

  const closeAndResetComplaintStatusModel = (type) => {
    console.log(type, "kkkkk");
    if (type === complaintStatusEnum.ACCEPT) {
      setAcceptStatus({
        showModel: false,
        loading: false,
        payload: payloadAcceptComplaint,
        id: "",
      });
    } else if (type === complaintStatusEnum.COMPLETE) {
      setCompleteStatus({
        showModel: false,
        loading: false,
        payload: payloadCompleteComplaint,
        id: "",
      });
    } else if (type === complaintStatusEnum.REJECT) {
      setRejectStatus({
        showModel: false,
        loading: false,
        payload: payloadRejectComplaint,
        id: "",
      });
    }
    setError({ message: "", status: "" });
  };
  const isValidatedUpdateForm = (state) => {
    for (const key in state.payload) {
      // Check if the key is empty or undefined
      if (!state.payload[key]) {
        setError({
          message: `Required field`, // Custom error message based on the missing key
          status: state.payload.status,
        });
        return false;
      }
    }
    return true;
  };
  const handleComplaintStatus = async (state) => {
    console.log(state, "pay");
    if (!isValidatedUpdateForm(state)) {
      return;
    }

    const response = await compaintStatusUpdate(state.id, state.payload);
    if (response.status) {
      getComplaintList(params.status);
      closeAndResetComplaintStatusModel(
        complaintStatusEnum[`${state.payload.status}`]
      );
      toast.custom(
        <SingleErrorMessage
          data={"Status Updated Successfully"}
          error={false}
          bColor="#175FC9"
          onClose={onCloseToaste}
        />
      );
    } else {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
  };

  const onCloseToaste = () => {
    toast.dismiss();
  };

  const handleTabChange = (index) => {
    const status = Object.values(complaintStatusEnum)[index];
    if (status) {
      navigate(`/complaints/${status}`);
    }
  };

  useEffect(() => {
    getComplaintList(params.status);
    setTabIndex(getEnumIndexByValue(complaintStatusEnum, params.status));
  }, [pageNo, pageSize, params.status]);

    return (
        <div
            className="payout_data-content"
            style={{ height: "100%" }}>
            <div className="payout_data-head">
                <div className="payout_data-head-top mb-2">
                    <div className="payout_data-left">
                        <div className="payout_data_ttl">
                            {" "}
                            <Breadcrumb paths={[{ name: "Complaints" }, { name: params.status }]} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="tab_box">
                <Tabs selectedIndex={tabIndex} onSelect={(index) => handleTabChange(index)}>
                    <TabList>
                        {Object.keys(complaintStatusEnum).map((key) => (
                            <Tab key={key}>{complaintStatusEnum[key]}</Tab>
                        ))}
                    </TabList>
                    <div className="table-responsive">
                    <table className="payout_table upi-mandate_table">
                        <thead>
                            <tr className="tableHead">
                                <th className="thHeader">
                                    <div className="payoutHead hndIcon">Ticket ID</div>
                                </th>
                                <th className="thHeader">
                                    <div className="payoutHead hndIcon">Department</div>
                                </th>
                                <th className="thHeader">
                                    <div className="payoutHead hndIcon">Complaint Title</div>
                                </th>
                                <th className="thHeader">
                                    <div className="payoutHead hndIcon">Complaint Count</div>
                                </th>
                                <th className="thHeader">
                                    <div className="payoutHead hndIcon">Created Date</div>
                                </th>
                                <th className="thHeader">
                                    <div className="payoutHead hndIcon">Attachment</div>
                                </th>
                                {complaintStatusEnum.ACCEPT === params.status
                                    &&
                                    <th className="thHeader">
                                        <div className="payoutHead hndIcon">Estimated Date to Complete</div>
                                    </th>}
                                {(complaintStatusEnum.PENDING === params.status || complaintStatusEnum.ACCEPT === params.status)
                                    &&
                                    <th className="thHeader">
                                        <div className="payoutHead hndIcon">Action</div>
                                    </th>}
                                {(complaintStatusEnum.COMPLETE === params.status || complaintStatusEnum.REJECT === params.status)
                                    &&
                                    <th className="thHeader">
                                        <div className="payoutHead hndIcon">Remarks</div>
                                    </th>}


              </tr>
            </thead>
            <tbody>
              {complaintList.loading ? (
                <tr>
                  <td
                    colSpan="10"
                    >
                    <Loader />
                  </td>
                </tr>
              ) : (
                <>
                  {complaintList.data?.length > 0 ? (
                    complaintList.data?.map((item) => {
                      return (
                        <tr key={item._id}>
                          <td>{item?.ticket}</td>
                          <td>{item?.departmentDetails?.name}</td>
                          <td>{item?.feed?.title?.en || "-"}</td>
                          <td>{item?.totalComplains ? <Link to={`/complaint/history/${item._id}`}>{item.totalComplains}</Link> : "0"}</td>
                          <td>{item?.createdAt ? moment(item.createdAt).format("DD MMM YYYY") : "-"}</td>
                          <td>{item?.complaints?.length
                            ?
                            item?.complaints.map((el, i) => {
                              return <div key={`attach-${i}`}>
                                <span style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => setShowImage(process.env.REACT_APP_S3_URL + "/" + el.image)}>File {i + 1}</span>
                              </div>
                            })
                            : ""}</td>
                          {complaintStatusEnum.ACCEPT === params.status
                            &&
                            <td>{item?.estimatedTime ? moment(item.estimatedTime).format("DD MMM YYYY") : "-"}</td>}
                          {(complaintStatusEnum.PENDING === params.status || complaintStatusEnum.ACCEPT === params.status)
                            &&
                            <td>
                              <div className="flex_btn">
                                {complaintStatusEnum.PENDING === params.status && <button className="btn btn-sm btn-primary" onClick={() => handleUpdateStatus(item._id, setAcceptStatus)}>Accept</button>}
                                {complaintStatusEnum.ACCEPT === params.status && <button className="btn btn-sm btn-primary" onClick={() => handleUpdateStatus(item._id, setCompleteStatus)}>Complete</button>}
                                {(complaintStatusEnum.PENDING === params.status || complaintStatusEnum.ACCEPT === params.status) && <button className="btn btn-sm btn-dark" onClick={() => handleUpdateStatus(item._id, setRejectStatus)}>Reject</button>}
                              </div>
                            </td>}
                          {(complaintStatusEnum.COMPLETE === params.status || complaintStatusEnum.REJECT === params.status)
                            &&
                            <td>
                              {item.remark}
                            </td>}

                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td
                                                colSpan="10"
                                                className="text_center">
                                                No data available
                                            </td>
                                        </tr>
                                    )}
                                </>
                            )}
                        </tbody>
                    </table>
                    </div>
                </Tabs>
                {complaintList.data?.length > 0 ? (
                    <Pagination
                        currentPage={pageNo}
                        totalCount={complaintList.totalRecords}
                        pageSize={pageSize}
                        onPageChange={(page) => setPageNo(page)}
                        changePageSize={(page) => {
                            setPageSize(page);
                            setPageNo(1);
                        }}
                    />
                ) : (
                    <></>
                )}

        <Modal
          show={acceptStatus.showModel}
          centered
          onHide={() => {
            closeAndResetComplaintStatusModel(complaintStatusEnum.ACCEPT);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Accept</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="input-box">
              <div className="input-label">Estimated Date</div>
              <input
                className="input"
                type="date"
                value={acceptStatus?.payload?.estimatedTime || ""}
                min={new Date().toISOString().split("T")[0]}
                max="2099-12-31"
                onChange={(e) => {
                  setError({ message: "", status: "" });
                  setAcceptStatus((k) => {
                    k.payload.estimatedTime = e.target.value;
                    return { ...k };
                  });
                }}
              />
              {error.status ===
                getKeyByValue(
                  complaintStatusEnum,
                  complaintStatusEnum.ACCEPT
                ) && (
                  <span className="text-small text-danger">{error.message}</span>
                )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              variant="secondary"
              onClick={() => {
                closeAndResetComplaintStatusModel(complaintStatusEnum.ACCEPT);
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-dark"
              variant="primary"
              onClick={() => {
                handleComplaintStatus(acceptStatus);
              }}
            >
              Submit
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={completeStatus.showModel}
          centered
          onHide={() => {
            closeAndResetComplaintStatusModel(complaintStatusEnum.COMPLETE);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Complete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="input-box">
              <div className="input-label">Remarks</div>
              <textarea
                className="textarea_input"
                rows={9}
                cols={20}
                name="remarks"
                placeholder="Please enter remarks"
                value={completeStatus?.payload?.remark || ""}
                onChange={(e) => {
                  setError({ message: "", status: "" });
                  setCompleteStatus((k) => {
                    k.payload.remark = e.target.value;
                    return { ...k };
                  });
                }}
              ></textarea>
              {error.status ===
                getKeyByValue(
                  complaintStatusEnum,
                  complaintStatusEnum.COMPLETE
                ) && (
                  <span className="text-small text-danger">{error.message}</span>
                )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              variant="secondary"
              onClick={() => {
                closeAndResetComplaintStatusModel(complaintStatusEnum.COMPLETE);
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-dark"
              variant="primary"
              onClick={() => {
                handleComplaintStatus(completeStatus);
              }}
            >
              Submit
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={rejectStatus.showModel}
          centered
          onHide={() => {
            closeAndResetComplaintStatusModel(complaintStatusEnum.REJECT);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Reject</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="input-box">
              <div className="input-label">Remarks</div>
              <textarea
                className="textarea_input"
                rows={9}
                cols={20}
                name="remarks"
                placeholder="Please enter remarks"
                value={rejectStatus?.payload?.remark || ""}
                onChange={(e) => {
                  setError({ message: "", status: "" });
                  setRejectStatus((k) => {
                    k.payload.remark = e.target.value;
                    return { ...k };
                  });
                }}
              ></textarea>
              {error.status ===
                getKeyByValue(
                  complaintStatusEnum,
                  complaintStatusEnum.REJECT
                ) && (
                  <span className="text-small text-danger">{error.message}</span>
                )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              variant="secondary"
              onClick={() => {
                closeAndResetComplaintStatusModel(complaintStatusEnum.REJECT);
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-dark"
              variant="primary"
              onClick={() => {
                handleComplaintStatus(rejectStatus);
              }}
            >
              Submit
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showImage}
          centered
          onHide={() => {
            setShowImage(null);
          }}
        >
          <Modal.Body>
            <div
              className="input-box"
              style={{ maxHeight: "60vh", overflow: "hidden" }}
            >
              <img src={showImage} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className={"btn btn-dark"}
              onClick={() => {
                setShowImage(null);
              }}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default ComplaintList;
