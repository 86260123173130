import React, { useState, useEffect, useRef } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import moment from "moment";
import {
    fetchWarehouseBids,
} from "../../action/warehouse";
import { toast } from "react-hot-toast";
import { getProducts, getunits } from "../../action/users";
import {
    handleBuyerTitle,
    handleCurrency,
} from "../../helper/helper";
import { Loader } from "../../components/loader";
import Pagination from "../../components/pagination/paginationView";
import { useNavigate, useParams } from "react-router-dom";
export const WarehouseAppliedList = ({ status, search }) => {
    const navigate = useNavigate();
    const [bidList, setBidList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [paginationValue, setPaginationValue] = useState(10);
    const [isLoading, setIsLoading] = useState(true);
    const fetchWarehouseBidList = async (searchValue = "") => {
        setIsLoading(true);
        const response = await fetchWarehouseBids(
            currentPage,
            paginationValue,
            status,
            searchValue
        );
        if (response.status) {
            setBidList(response.data.list);
            setTotalRecords(response.data.count);
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (status == 4) {
            fetchWarehouseBidList(search)
        }
    }, [currentPage, paginationValue, search]);
    const onCloseToaste = () => {
        toast.dismiss();
    };


    useEffect(() => {
        const handleWheel = (event) => {
            if (event.target.type === "number") {
                event.preventDefault();
            }
        };

        // Add event listener to the entire document
        document.addEventListener("wheel", handleWheel, { passive: false });

        return () => {
            // Clean up the event listener
            document.removeEventListener("wheel", handleWheel);
        };
    }, []);
    return (<>
        <div className="overflow_auto">
            <table className="payout_table upi-mandate_table">
                <thead>
                    <tr className="tableHead">
                        <th className="thHeader">
                            <div className="payoutHead hndIcon">Order ID</div>
                        </th>
                        <th className="thHeader">
                            <div className="payoutHead hndIcon">
                                {handleBuyerTitle()} Name
                            </div>
                        </th>
                        <th className="thHeader">
                            <div className="payoutHead hndIcon">Role</div>
                        </th>
                        <th className="thHeader">
                            <div className="payoutHead">Delivery Address</div>
                        </th>
                        <th className="thHeader">
                            <div className="payoutHead">MSP</div>
                        </th>

                        <th className="thHeader">
                            <div className="payoutHead">Product</div>
                        </th>
                        <th className="thHeader">
                            <div className="payoutHead">Variety</div>
                        </th>
                        <th className="thHeader">
                            <div className="payoutHead">Grade</div>
                        </th>
                        <th className="thHeader">
                            <div className="payoutHead">Price </div>
                        </th>
                        <th className="thHeader">
                            <div className="payoutHead">Quantity </div>
                        </th>
                        <th className="thHeader">
                            <div className="payoutHead">Total Price </div>
                        </th>
                        <th className="thHeader">
                            <div className="payoutHead">Delivery Date</div>
                        </th>
                    </tr>
                </thead>

                {isLoading ? (
                    <tr>
                        <td colSpan="14">
                            <Loader />
                        </td>
                    </tr>
                ) : bidList.length > 0 ? (
                    bidList.map((item) => {
                        return (
                            <tr key={item._id}>
                                <td>{item?.bidDetails?.orderId}</td>
                                <td className="phone_number">{item?.buyer?.name}</td>
                                <td className="Status">{item?.roleDetails?.name}</td>
                                <td className="Status">
                                    {item?.warehouseAddress
                                        ? item?.warehouseAddress?.location?.address
                                        : item?.warehouseDefaultAddress[0]?.location
                                            ?.address}

                                </td>
                                <td className="table_amount">
                                    <div>
                                        {item?.mspDetails?.minimum_selling_price
                                            ? item?.mspDetails?.minimum_selling_price +
                                            " /" +
                                            item?.mspDetails?.unit?.shortcode
                                            : "-"}
                                    </div>
                                </td>
                                <td className="table_amount">{item?.product}</td>
                                <td className="table_amount ">
                                    {item?.veriety ?? "-"}
                                </td>
                                <td className="">{item?.grade}</td>

                                <td className="Status bold_txt">
                                    <div>
                                        {handleCurrency()} {item?.price ?? 0}
                                    </div>
                                </td>
                                <td className="Status bold_txt">
                                    <div>
                                        {item?.quantity} {item?.unit ? item?.unit : ""}
                                    </div>
                                </td>
                                <td className="bold_txt">
                                    <div>
                                        {handleCurrency()}{" "}
                                        {(item?.price ?? 0) *
                                            (item?.quantity ?? 0) *
                                            (item?.conversion ?? 0)}
                                    </div>
                                </td>
                                <td>
                                    {moment(item?.deliveryDate).format("DD-MM-YYYY")}
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <>
                        <tr>
                            <td colSpan="15" className="text-align-center">
                                No data available
                            </td>
                        </tr>
                    </>
                )}
            </table>{" "}
        </div>
        {bidList.length > 0 ? <Pagination
            currentPage={currentPage}
            totalCount={totalRecords}
            pageSize={paginationValue}
            onPageChange={(page) => setCurrentPage(page)}
            changePageSize={(page) => {
                setPaginationValue(page);
                setCurrentPage(1);
            }}
        /> : ""}
    </>
    );
};
