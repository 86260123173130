import { requestWithToken, requestWithoutToken } from "../helper/helper";

const getunits = async () => {
  try {
    const response = await requestWithToken("GET", `/user/units`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getProducts = async () => {
  try {
    const response = await requestWithToken("GET", `/category/parents`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getGrades = async () => {
  try {
    const response = await requestWithToken("GET", `/admin/grade`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getVeriety = async (id) => {
  try {
    const response = await requestWithToken("GET", `/category/veriety/${id}`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getMe = async () => {
  try {
    const response = await requestWithToken("GET", `/user/me`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getProductList = async () => {
  try {
    const response = await requestWithToken("GET", `/admin/categories?type=1`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getCountryList = async () => {
  try {
    const response = await requestWithToken("GET", `/admin/country`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getNotifications = async (pageNo, pageSize) => {
  try {
    const response = await requestWithToken(
      "GET",
      // `/notification/list?pageNo=${pageNo}&pageSize=${pageSize}`
      `/notification/oderid-list?pageNo=${pageNo}&pageSize=${pageSize}`
    );

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const fetchUserNotifications = async (pageNo, pageSize) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/notification/list?pageNo=${pageNo}&pageSize=${pageSize}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const AddUserNotification = async (payload) => {
  try {
    const response = await requestWithToken("POST", `/admin/notification/`, payload);
    return response;
  } catch (err) {
    return err.response.data;
  }
};

const putNotifications = async (id) => {
  try {
    const response = await requestWithToken("PUT", `/notification/${id}`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getNews = async (pageNo, pageSize) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/news/admin?pageNo=${pageNo}&pageSize=${pageSize}`
    );

    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const AddNews = async (payload) => {
  try {
    const response = await requestWithToken("POST", `/news/`, payload);
    return response;
  } catch (err) {
    return err.response.data;
  }
};
const EditNews = async (id, payload) => {
  try {
    const response = await requestWithToken("PUT", `/news/${id}`, payload);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const DeleteNews = async (id) => {
  try {
    const response = await requestWithToken("DELETE", `/news/feedNews/${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  DeleteNews,
  EditNews,
  AddNews,
  getNews,
  getunits,
  getProducts,
  getGrades,
  getVeriety,
  getMe,
  getProductList,
  getCountryList,
  getNotifications,
  putNotifications,
  fetchUserNotifications,
  AddUserNotification
};
