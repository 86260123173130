// src/Breadcrumb.js
import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ paths }) => {
  const role = localStorage.getItem("role");
  // let homeUrl
  // if()
  return (
    <>
      <nav style={{ "--bs-breadcrumb-divider": "'>'" }} aria-label="breadcrumb">
        <ol className="breadcrumb">
          {/* <li className="breadcrumb-item">
            
            <Link to="/gaon">Home</Link>
          </li> */}
          {paths.map((path, index) => (
            <li
              key={index}
              className={`breadcrumb-item${
                index === paths.length - 1 ? " active" : ""
              }`}
            >
              {index === paths.length - 1 ? (
                path.name
              ) : (
                <>
                  <Link to={path.link} state={path.state}>
                    {path.name}
                  </Link>
                </>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </>
  );
};

export default Breadcrumb;
