import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/style.css";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { getFarmerBidDetails } from "../../action/warehouse";
import { handleCurrency } from "../../helper/helper";
import moment from "moment";
import { ImagePopup } from "../../components/common/ImagePopup.js";
import { Link } from "react-router-dom";
import leftArrow from "../../assets/images/navigate_left.svg";
export const DetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [bidDetails, setBidDetails] = useState();
  const [selectedImage, setSelectedImage] = useState(null);

  const fetchDetails = async () => {
    const response = await getFarmerBidDetails(id);
    if (response.status) {
      setBidDetails(response.data[0]);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, [id]);

  const closeModal = () => {
    setSelectedImage(null);
  };
  console.log("bid detail ==>", bidDetails?.status);
  return (
    <div className="payout_data-content request_detail">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left p-0">
            <div className="payout_data_ttl">
              <Breadcrumb
                paths={[
                  {
                    name: "Bid List",
                    link: `/farmerBid/${bidDetails?.bidDetails?._id}`,
                    state: bidDetails?.status || "",
                  },
                  { name: "Request Details", link: `/farmerBid/details/${id}` },
                ]}
              />
            </div>
          </div>
          <div className="payout_data-right">
            <button
              onClick={() => {
                // navigate(-1);
                navigate(`/farmerBid/${bidDetails?.bidDetails?._id}`, {
                  state: bidDetails?.status,
                });
              }}
              className="back_btnn"
            >
              {" "}
              <img
                src={leftArrow}
                alt="Arrow"
                className="navi_left"
              /> Back{" "}
            </button>
          </div>
        </div>
      </div>
      <div className="payout_data-content mb-3 user_detail">
        <h4>User Details</h4>
        <div>
          <span>Name : </span> {bidDetails?.userDetails?.name}
        </div>
        <div>
          <span>Mobile : </span> {bidDetails?.userDetails?.mobile}
        </div>
        <div>
          <span>Address : </span> {bidDetails?.location?.address}
        </div>
        {bidDetails?.userDetails?.email ? (
          <div>
            <span>Email : </span> {bidDetails?.userDetails?.email}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="payout_data-content bid_detail">
        <h4>Bid Details</h4>
        <div>
          <span>Product : </span> {bidDetails?.product}
        </div>
        <div>
          <span>Variety : </span> {bidDetails?.variety}
        </div>
        <div>
          <span>Quantity : </span>{" "}
          {bidDetails?.status == 4
            ? bidDetails?.quantity
            : bidDetails?.acceptedQty}{" "}
          {bidDetails?.unitDetails?.shortcode}
        </div>
        <div>
          <span>Delivery Address : </span> {bidDetails?.location?.address}
        </div>
        <div>
          <span>Grade : </span> {bidDetails?.grade}
        </div>
        <div>
          <span>Price : </span> {handleCurrency()} {bidDetails?.price}
        </div>
        <div>
          <span>Delivery Date : </span>{" "}
          {bidDetails?.deliveryDate
            ? moment(bidDetails?.deliveryDate).format("DD-MM-YYYY")
            : ""}
        </div>
        <div className="uploaded_img">
          <ul>
            {bidDetails?.pics &&
              bidDetails?.pics.map((url, index) => {
                return (
                  <li key={index} className="img_list_item">
                    <ImagePopup
                      className="img_click"
                      src={`${process.env.REACT_APP_S3_URL}/${url}`}
                      alt="product"
                    />
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
      {/* {selectedImage && (
        <div
          className="image-modal"
          onClick={closeModal}>
          <div
            className="image-modal-content"
            onClick={(e) => e.stopPropagation()}>
            <span
              className="close-btn"
              onClick={closeModal}>
              &times;
            </span>
            <img
              src={selectedImage}
              alt="popup"
              className="popup-image"
            />
          </div>
        </div>
      )} */}
    </div>
  );
};
