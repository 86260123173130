import React, { useState, useEffect } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import trashicon from "../../assets/images/trash-03.svg";
import EditIcon from "../../assets/images/edit_btn.svg";
import { Button, Modal, Offcanvas } from "react-bootstrap";
import ThumbnailPicker from "../../components/ThumbnailPicker/ThumbnailPicker";
import uploadImage from "../../action/imageUpload";
import plusWhite from "../../assets/images/plus-white.svg";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import {
    AddNews,
    AddUserNotification,
    DeleteNews,
    EditNews,
    fetchUserNotifications,
    getNews,
    geUserNotifications,
    putNotifications,
} from "../../action/users";

import moment from "moment";
import { SingleErrorMessage } from "../singleErrorMessage";
import { Loader } from "../../components/loader";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Pagination from "../../components/pagination/paginationView";
export const News = () => {
    const navigate = useNavigate();

    const [list, setList] = useState([]);
    const [saveSelect, setSaveSelect] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [paginationValue, setPaginationValue] = useState(10);
    const [isLoading, setIsLoading] = useState(true);
    const [isOpenCanvas, setIsOpenCanvas] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null); // State for selected image
    const [showImageModal, setShowImageModal] = useState(false); // State for modal visibility
    const [newsId, setNewsId] = useState("");
    const [editModel, setEditModel] = useState(false);
    const [showDeleteModel, setShowDeleteModel] = useState(false);

    const getnewslist = async () => {
        setIsLoading(true);
        const response = await getNews(currentPage, paginationValue);
        setList(response.data.details);
        setTotalRecords(response.data.count);
        console.log(response.data.details);
        setIsLoading(false);
    };
    useEffect(() => {
        getnewslist();
    }, [currentPage, paginationValue]);

    const handleDelete = async () => {
        try {
            const res = await DeleteNews(newsId);
            if (res.status) {
                toast.custom(
                    <SingleErrorMessage
                        data={res.message}
                        error={false}
                        bColor="#175FC9"
                    />
                );

                setShowDeleteModel(false);
                getnewslist();
            }
        } catch (error) {
            console.error("Failed to delete category", error);
        }
    };
  
    const uploadMediaImage = async (file, type) => {
        const formData = new FormData();
        formData.append("file", file);
        const res = await uploadImage(formData, "gaon");
        if (res.status) {
            addFormik.setFieldValue("image", res.data[0]);
        }
    };

    const handleEdit = async (id) => {
        try {
          const selectedNews = list.find((item) => item.extras.newsId === id); 
          if (selectedNews) {
            editCategoryFormik.setValues({
              title: selectedNews.title?.en || "", 
              url: selectedNews.extras?.url || "",
              source: selectedNews.extras?.source || "",
              image: selectedNews.assets?.[0]?.url || "",
            });
            setNewsId(id); 
            setEditModel(true); 
          }
        } catch (error) {
          console.error("Failed to load news data for editing", error);
        }
      };
  
      
    const editCategoryFormik = useFormik({
        initialValues: {
            title: "",
            url: "",
            source: "",
            image: "",
        },
        onSubmit: async (values) => {
          try {
            const res = await EditNews(newsId, values);
            if (res.status) {
              toast.custom(
                <SingleErrorMessage
                  data={"News updated successfully"}
                  error={false}
                  bColor="#175FC9"
                  onClose={onCloseToaste}
                />
              );
            } else {
              toast.custom(
                <SingleErrorMessage
                  data={res.message ? res.message : res}
                  error={true}
                  bColor="#D92D20"
                  onClose={onCloseToaste}
                />
              );
            }
            getnewslist();
            setEditModel(false);
            
            editCategoryFormik.handleReset();
          } catch (error) {
            console.error("Failed to add category", error);
          }
        },
      });


    const onCloseToaste = () => {
        toast.dismiss();
    };
    const addFormik = useFormik({
        initialValues: {
            title: "",
            url: "",
            source: "",
            image: "",
        },
        onSubmit: async (values, { resetForm }) => {
            const response = await AddNews(values);
            if (response?.status) {
                getnewslist(); // refresh the banner list after adding
                setIsOpenCanvas(false);
                setSaveSelect("");
                toast.custom(
                    <SingleErrorMessage
                        data={"News Created successfully"}
                        error={false}
                        bColor="#175FC9"
                        onClose={onCloseToaste}
                    />
                );
                resetForm();
            } else {
                toast.custom(
                    <SingleErrorMessage
                        data={response.message}
                        error={true}
                        bColor="#D92D20"
                        onClose={onCloseToaste}
                    />
                );
            }
        },
    });


    const handleViewImage = (image) => {
        const imageUrl = `${process.env.REACT_APP_S3_URL}/${image}`; // Construct the image URL
        console.log("Selected Image URL:", imageUrl); // Debugging: Check the constructed image URL
        setSelectedImage(imageUrl); // Set the selected image URL
        setShowImageModal(true); // Show the modal
    };

    return (
        <div className="payout_data-content">
            <div className="payout_inner_content">
                <div className="payout_data-head">
                    <div className="payout_data-head-top">
                        <div className="payout_data-left">
                            <div className="payout_data_ttl">
                                <Breadcrumb
                                    paths={[
                                        {
                                            name: `Dashboard`,
                                            link: `/`,
                                        },
                                        {
                                            name: "News",
                                            link: `/News`,
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                        <Button
                            color="info"
                            tag="a"
                            type="button"
                            onClick={() => {
                                setIsOpenCanvas(true);
                            }}
                        >
                            Add News
                            <img src={plusWhite} alt="" className="payout_down" />
                        </Button>
                    </div>
                </div>
                <table className="payout_table upi-mandate_table">
                    <tr className="tableHead">
                        <th className="thHeader">
                            <div className="payoutHead hndIcon">Title</div>
                        </th>
                        <th className="thHeader">
                            <div className="payoutHead hndIcon">Source</div>
                        </th>
                        <th className="thHeader">
                            <div className="payoutHead hndIcon">Created On</div>
                        </th>
                        <th className="thHeader">
                            <div className="payoutHead hndIcon">News Link</div>
                        </th>
                        <th className="thHeader">
                            <div className="payoutHead hndIcon">Image</div>
                        </th>
                        <th className="thHeader">
                            <div className="payoutHead hndIcon">Actions</div>
                        </th>

                    </tr>
                    {isLoading ? (
                        <tr>
                            <td colSpan="9">
                                <Loader />
                            </td>
                        </tr>
                    ) : (
                        <>
                            {list.length > 0 ? (
                                list.map((item) => {
                                    return (
                                        <tr>
                                            <td>{item?.title?.en}</td>
                                            <td>{item?.extras?.source}</td>
                                            <td> {moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                                            <td>
                                                {item?.extras?.url ? (
                                                    <a href={item.extras.url} className="news_link" target="_blank" rel="noopener noreferrer">
                                                        Read Here
                                                    </a>
                                                ) : (
                                                    "-"
                                                )}
                                            </td>
                                            <td>{item?.assets?.[0]?.url ? (
                                                <button
                                                    className="not_img view_img"
                                                    onClick={() => handleViewImage(item?.assets?.[0]?.url)}
                                                >
                                                    View Image
                                                </button>
                                            ) : (
                                                "-"
                                            )}
                                             </td>
                                            <td>
                                                {" "}
                                                <div className="flex_btn">
                                                    <img
                                                        onClick={() => handleEdit(item.extras.newsId)}
                                                        src={EditIcon}
                                                        alt="Edit"
                                                        className="edit_icon"
                                                    />

                                                    <img
                                                        src={trashicon}
                                                        alt="Delete"
                                                        className="edit_icon"
                                                        onClick={() => {
                                                            setShowDeleteModel(true);
                                                            setNewsId(item._id);
                                                        }}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <>
                                    <tr>
                                        <td colSpan="11" className="text_center">
                                            No data available
                                        </td>
                                    </tr>
                                </>
                            )}
                        </>
                    )}
                </table>{" "}
                <Modal
                    show={showImageModal}
                    onHide={() => setShowImageModal(false)}
                    className="notification_image"
                >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        {selectedImage && (
                            <img
                                src={selectedImage}
                                alt="Selected"
                                style={{ width: "100%" }}
                            />
                        )}
                    </Modal.Body>
                </Modal>
            </div>

            <Pagination
                currentPage={currentPage}
                totalCount={totalRecords}
                pageSize={paginationValue}
                onPageChange={(page) => setCurrentPage(page)}
                changePageSize={(page) => {
                    setPaginationValue(page);
                    setCurrentPage(1);
                }}
            />

            <Offcanvas
                show={isOpenCanvas}
                onHide={() => {
                    setIsOpenCanvas(false);
                }}
                placement="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Add News</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="add_buyer_content">
                        <form onSubmit={addFormik.handleSubmit}>
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* Title Field */}
                                    <div className="input-box">
                                        <div className="input-label">Title</div>
                                        <div className="input_field">
                                            <input
                                                type="text"
                                                className="input"
                                                name="title"
                                                placeholder="Enter Notification Title"
                                                value={addFormik.values.title}
                                                onChange={addFormik.handleChange}
                                                onBlur={addFormik.handleBlur}
                                                required
                                            />
                                        </div>
                                        {addFormik.touched.title && addFormik.errors.title && (
                                            <div className="error">{addFormik.errors.title}</div>
                                        )}
                                    </div>
                                    <div className="input-box">
                                        <div className="input-label">Source</div>
                                        <div className="input_field">
                                            <input
                                                type="text"
                                                className="input"
                                                name="source"
                                                maxLength={50}
                                                placeholder="Enter Source"
                                                value={addFormik.values.source}
                                                onChange={addFormik.handleChange}
                                                onBlur={addFormik.handleBlur}
                                                required
                                            />
                                        </div>

                                    </div>
                                    {/* Body Field */}
                                    <div className="input-box">
                                        <div className="input-label">Url</div>
                                        <div className="input_field">
                                            <input
                                                type="url"
                                                className="input"
                                                name="url"
                                                placeholder="Enter new url"
                                                value={addFormik.values.url}
                                                onChange={addFormik.handleChange}
                                                onBlur={addFormik.handleBlur}
                                                required
                                            />
                                        </div>

                                    </div>


                                </div>
                                <div
                                    className="option-card"
                                    style={{ display: "flex", marginTop: "36px" }}
                                >
                                    <ThumbnailPicker
                                        width="60%"
                                        height="250px"
                                        onFileSelect={(file) => {
                                            const _URL = window.URL || window.webkitURL;

                                            const img = new Image();
                                            uploadMediaImage(file, 1);

                                            img.src = _URL.createObjectURL(file);
                                        }}
                                        image={addFormik.values.image}
                                        required
                                    />
                                </div>
                            </div>

                            {/* Submit Button */}
                            <div className="addBuyer_submit-btn">
                                <button className="btn btn-success" type="submit">
                                    Send
                                </button>
                            </div>
                        </form>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
            <Offcanvas
                show={editModel}
                onHide={() => {
                    setEditModel(false);
                    editCategoryFormik.handleReset();
                }}
                placement="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Edit News</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="add_buyer_content">
                        <form onSubmit={editCategoryFormik.handleSubmit}>
                        <div className="row">
                                <div className="col-lg-12">
                                    {/* Title Field */}
                                    <div className="input-box">
                                        <div className="input-label">Title</div>
                                        <div className="input_field">
                                            <input
                                                type="text"
                                                className="input"
                                                name="title"
                                                placeholder="Enter Notification Title"
                                                value={editCategoryFormik.values.title}
                                                onChange={editCategoryFormik.handleChange}
                                                onBlur={editCategoryFormik.handleBlur}
                                                required
                                            />
                                        </div>
                                        {editCategoryFormik.touched.title && editCategoryFormik.errors.title && (
                                            <div className="error">{editCategoryFormik.errors.title}</div>
                                        )}
                                    </div>
                                    <div className="input-box">
                                        <div className="input-label">Url</div>
                                        <div className="input_field">
                                            <input
                                                type="text"
                                                className="input"
                                                name="source"
                                                placeholder="Enter Source"
                                                value={editCategoryFormik.values.source}
                                                onChange={editCategoryFormik.handleChange}
                                                onBlur={editCategoryFormik.handleBlur}
                                                required
                                            />
                                        </div>

                                    </div>
                                    {/* Body Field */}
                                    <div className="input-box">
                                        <div className="input-label">Url</div>
                                        <div className="input_field">
                                            <input
                                                type="url"
                                                className="input"
                                                name="url"
                                                placeholder="Enter new url"
                                                value={editCategoryFormik.values.url}
                                                onChange={editCategoryFormik.handleChange}
                                                onBlur={editCategoryFormik.handleBlur}
                                                required
                                            />
                                        </div>

                                    </div>


                                </div>
                                <div
                                    className="option-card"
                                    style={{ display: "flex", marginTop: "36px" }}
                                >
                                    <ThumbnailPicker
                                        width="60%"
                                        height="250px"
                                        onFileSelect={(file) => {
                                            const _URL = window.URL || window.webkitURL;

                                            const img = new Image();
                                            uploadMediaImage(file, 1);

                                            img.src = _URL.createObjectURL(file);
                                        }}
                                        image={editCategoryFormik.values.image}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="addBuyer_submit-btn">
                                <button className="btn btn-success" type="submit">
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
            <Modal
                show={showDeleteModel}
                onHide={() => {
                    setShowDeleteModel(false);
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>News</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this News?</Modal.Body>
                <Modal.Footer>
                    <button
                    className="btn btn-dark"
                        variant="secondary"
                        onClick={() => {
                            setShowDeleteModel(false);
                        }}
                    >
                        No
                    </button>
                    <button
                      className="btn btn-success"
                        variant="primary"
                        onClick={() => {
                            handleDelete();
                        }}
                    >
                        Yes
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
