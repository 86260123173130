import { Field, Form, Formik, useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchModulecategory,
  fetchModuleDetails,
  updateModule,
} from "../../action/contest";
import moment from "moment";
import ThumbnailPicker from "../../components/ThumbnailPicker/ThumbnailPicker";
import uploadImage from "../../action/imageUpload";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { SingleErrorMessage } from "../singleErrorMessage";
import { toast } from "react-hot-toast";
import { gaonTextBasedOnRoleTYpe } from "../../helper/constants";
import { Link } from "react-router-dom";
import { convertToDatetimeWithTimezone } from "../../helper/helper";
import leftArrow from "../../assets/images/navigate_left.svg";

const ModuleEdit = () => {
  const [isModuleTypeOpen, setIsModuleTypeOpen] = useState(false);
  const [category, setCategory] = useState([]);
  const [startdate, setStartdate] = useState();
  const [endeddate, setEndeddate] = useState();
  const [tDate] = useState(new Date().toJSON().slice(0, 10));
  // const [startdate, setStartdate] = useState(new Date().toJSON().slice(0, 10));  const [selectStartdate, setSelectStartdate] = useState(handleStartDate());
  const [selectStartdate, setSelectStartdate] = useState();
  // { if (moduleStart > tDate ? moduleStart : }
  const handleEndDate = () => {
    if (selectStartdate > tDate) {
      return selectStartdate
    } else if (tDate > selectStartdate) {
      return tDate
    } else {
      return tDate
    }
  }
  console.log('selectStartdate', selectStartdate);

  const handleStartDate = () => {
    if (startdate > tDate) {
      return startdate
    } else if (tDate > startdate) {
      return tDate
    } else {
      return tDate
    }
  }

  const dropdownRef = useRef(null);
  const moduleCategory = async () => {
    const response = await fetchModulecategory();
    if (response.status) {

      setCategory(response.data)
    }
  };
  useEffect(() => {
    moduleCategory();
  }, []);

  const { id } = useParams();
  const navigate = useNavigate();

  const [level] = useState([
    { name: "Global", value: "GLOBAL" },
    // { name: "State", value: "STATE" },
    // { name: "District", value: "DISTRICT" },
    // { name: "Sub-District", value: "SUB_DISTRICT" },
    // { name: gaonTextBasedOnRoleTYpe(), value: "GAON" },
  ]);

  const [moduleType] = useState([
    { name: "Text", value: "TEXT" },
    { name: "Video", value: "VIDEO" },
    { name: "Image", value: "IMAGE" },
  ]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsModuleTypeOpen(false); // Close dropdown if clicked outside
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const [timer] = useState([
    { name: "5", value: 5 },
    { name: "10", value: 10 },
    { name: "15", value: 15 },
    { name: "20", value: 20 },
  ]);

  const [type] = useState([
    { name: "Gyaan game", value: 0 },
    // { name: "Live", value: 1 },
  ]);

  const addContestFormik = useFormik({
    initialValues: {
      title: { en: "", hi: "", mr: "" },
      level: "",
      levelIds: [],
      startDate: "",
      endDate: "",
      moduleType: [],
      assets: { src: "", w: 200, h: 200 },
      price: "",
      type: "",
      passing: 100,
      timer: 0,
      totalWinners: 0,
      coins_per_question: 0,
      isPopular: false,
      moduleCategoryId: "",
    },
    onSubmit: async (values) => {
      if (values.moduleType.length == 0) {
        toast.custom(
          <SingleErrorMessage
            data={"Please select quiz type"}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
        return;
      }
      // if (values.totalWinners > 5) {
      //   toast.custom(
      //     <SingleErrorMessage
      //       data={"The winner count should be 5 or below."}
      //       error={true}
      //       bColor="#D92D20"
      //       onClose={onCloseToaste}
      //     />
      //   );
      //   return;
      // }
      console.log("addContestFormik?.startDate", addContestFormik.values?.startDate)
      console.log("addContestFormik?.endDate", addContestFormik.values?.endDate)
      if (addContestFormik.values?.startDate > addContestFormik.values?.endDate) {
        toast.custom(
          <SingleErrorMessage
            data={"End date must be same or later than start date."}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
        return;
      }
      if (values.moduleType === "IMAGE") {
        if (values.assets.src === "") {
          toast.custom(
            <SingleErrorMessage
              data={"Image is required"}
              error={true}
              bColor="#D92D20"
              onClose={onCloseToaste}
            />
          );
          return;
        } else {
          let payload = {
            ...values,
            startDate: convertToDatetimeWithTimezone(values.startDate, 'start'),
            endDate: convertToDatetimeWithTimezone(values.endDate, 'end')
          }
          const res = await updateModule(id, payload);
          if (res.status) {
            toast.custom(
              <SingleErrorMessage
                data={res.message}
                error={false}
                bColor="#175FC9"
                onClose={onCloseToaste}
              />
            );
            navigate("/module/list");
          } else {
            toast.custom(
              <SingleErrorMessage
                data={res.message}
                error={true}
                bColor="#D92D20"
                onClose={onCloseToaste}
              />
            );
          }
        }
      } else {
        const res = await updateModule(id, values);
        if (res.status) {
          toast.custom(
            <SingleErrorMessage
              data={res.message}
              error={false}
              bColor="#175FC9"
              onClose={onCloseToaste}
            />
          );
          navigate("/module/list");
        } else {
          toast.custom(
            <SingleErrorMessage
              data={res.message}
              error={true}
              bColor="#D92D20"
              onClose={onCloseToaste}
            />
          );
        }
      }
    },
  });

  const onCloseToaste = () => {
    toast.dismiss();
  };

  const getModuleDetails = async () => {
    const res = await fetchModuleDetails(id);
    if (res.status) {
      addContestFormik.setFieldValue("title.en", res.data[0].title.en);
      addContestFormik.setFieldValue("title.hi", res.data[0].title.hi);
      addContestFormik.setFieldValue("title.mr", res.data[0].title.mr);

      addContestFormik.setFieldValue("assets.src", res.data[0].assets.src);

      addContestFormik.setFieldValue("level", res.data[0].level);
      addContestFormik.setFieldValue("price", res.data[0].price);
      addContestFormik.setFieldValue("passing", res.data[0].passing);
      addContestFormik.setFieldValue("totalWinners", res.data[0].totalWinners);
      addContestFormik.setFieldValue("type", res.data[0].type);
      addContestFormik.setFieldValue("moduleType", res.data[0].moduleType);
      addContestFormik.setFieldValue(
        "startDate",
        moment(res.data[0].startDate).format("YYYY-MM-DD")
      );
      setStartdate(moment(res.data[0].startDate).format("YYYY-MM-DD"))
      addContestFormik.setFieldValue(
        "endDate",
        moment(res.data[0].endDate).format("YYYY-MM-DD")
      );
      setEndeddate(moment(res.data[0].endDate).format("YYYY-MM-DD"))
      setSelectStartdate(moment(res.data[0].startDate).format("YYYY-MM-DD"))
      addContestFormik.setFieldValue(
        "coins_per_question",
        res.data[0].coins_per_question
      );
      addContestFormik.setFieldValue("timer", res.data[0].timer);
      // addContestFormik.setFieldValue("isPopular", res.data[0].isPopular);
      addContestFormik.setFieldValue("moduleCategoryId", res.data[0]?.moduleCategoryId);
    }
  };

  useEffect(() => {
    getModuleDetails();
  }, [id]);

  const uploadMediaImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const res = await uploadImage(formData, "module");
    if (res.status) {
      addContestFormik.setFieldValue(`assets.src`, res.data[0]);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsModuleTypeOpen(false); // Close dropdown if clicked outside
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  const handleCheckboxChange = (e, value) => {
    const newModuleTypes = e.target.checked
      ? [...addContestFormik.values.moduleType, value]
      : addContestFormik.values.moduleType.filter(
        (moduleType) => moduleType !== value
      );
    addContestFormik.setFieldValue("moduleType", newModuleTypes);
  };
  useEffect(() => {
    const handleWheel = (event) => {
      if (event.target.type === "number") {
        event.preventDefault();
      }
    };

    // Add event listener to the entire document
    document.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      // Clean up the event listener
      document.removeEventListener("wheel", handleWheel);
    };
  }, []);
  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top mb-2">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              {" "}
              <Breadcrumb
                paths={[
                  { name: "Contest", link: "/module/list" },
                  { name: "Edit Contest", link: "/module/edit" },
                ]}
              />
            </div>
          </div>
          <button
            onClick={() => {
              navigate(-1);
            }}
            className="back_btnn"
          >
            {" "}
            <img
              src={leftArrow}
              alt="Arrow"
              className="navi_left"
            /> Back{" "}
          </button>
        </div>
      </div>
      <div className="tab_box">
        <Formik>
          <Form
            onSubmit={addContestFormik.handleSubmit}
            className="m-4">
            <ul className="form-user-grid">
              <li>
                <div className="input-box">
                  <div className="input-label">Contest Title (English)</div>
                  <div className="input_field">
                    <input
                      type="text"
                      className="input"
                      name="title.en"
                      placeholder="Enter title"
                      value={addContestFormik.values?.title?.en}
                      onChange={addContestFormik.handleChange}
                    />
                  </div>
                </div>
              </li>
              <li>
                <div className="input-box">
                  <div className="input-label">Contest Title (Hindi)</div>
                  <div className="input_field">
                    <input
                      type="text"
                      className="input"
                      name="title.hi"
                      placeholder="Enter title"
                      value={addContestFormik.values?.title?.hi}
                      onChange={addContestFormik.handleChange}
                    />
                  </div>
                </div>
              </li>
              <li>
                <div className="input-box">
                  <div className="input-label">Contest Title (Marathi)</div>
                  <div className="input_field">
                    <input
                      type="text"
                      className="input"
                      name="title.mr"
                      placeholder="Enter title"
                      value={addContestFormik.values?.title?.mr}
                      onChange={addContestFormik.handleChange}
                    />
                  </div>
                </div>
              </li>
              <li>
                <div className="input-box">
                  <div className="input-label">Contest Reach</div>
                  <div className="input_field">
                    <select
                      type="text"
                      className="input"
                      placeholder="Level"
                      name="zone"
                      value={addContestFormik?.values?.level}
                      onChange={(e) => {
                        addContestFormik.setFieldValue("level", e.target.value);
                      }}>
                      <option value="">Select Level</option>
                      {level.map((value) => {
                        return (
                          <option value={value.value}>{value.name}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </li>
              <li>
                <div className="input-box">
                  <div className="input-label">Winning Price Pool</div>
                  <div className="input_field">
                    <input
                      type="number"
                      className="input"
                      name="price"
                      placeholder="Enter price"
                      value={addContestFormik.values?.price}
                      onChange={addContestFormik.handleChange}
                    />
                  </div>
                </div>
              </li>
              <li>
                <div className="input-box">
                  <div className="input-label">Maximum Number Of Winners</div>
                  <div className="input_field">
                    <input
                      required
                      type="number"
                      className="input"
                      name="totalWinners"
                      placeholder="Enter maximum number of winners"
                      value={addContestFormik.values.totalWinners}
                      onChange={addContestFormik.handleChange}
                    />
                  </div>
                </div>
              </li>
              <li>
                <div
                  className="input-box"
                >
                  <div className="input-label">Quiz Type</div>
                  <div className="input_field custom_input" ref={dropdownRef}>
                    <div
                      className="dropdown-header"
                      onClick={() => setIsModuleTypeOpen(!isModuleTypeOpen)}
                    >
                      {Array.isArray(addContestFormik?.values?.moduleType) &&
                        addContestFormik?.values?.moduleType?.length > 0
                        ? addContestFormik?.values?.moduleType.join(", ")
                        : "Select Module Type"}
                    </div>
                    {isModuleTypeOpen && (
                      <div className="dropdown-menuu">
                        {moduleType.map((value) => (
                          <label key={value.value} className="dropdown-itemm">
                            <Field
                              type="checkbox"
                              name="moduleType"
                              value={value.value}
                              checked={addContestFormik?.values?.moduleType.includes(
                                value.value
                              )}
                              onChange={(e) =>
                                handleCheckboxChange(e, value.value)
                              }
                            />
                            {value.name}
                          </label>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </li>
              <li>
                <div className="input-box">
                  <div className="input-label">Contest Type</div>
                  <div className="input_field">
                    <select
                      type="text"
                      className="input"
                      placeholder="Level"
                      name="type"
                      value={addContestFormik?.values?.type}
                      onChange={(e) => {
                        addContestFormik.setFieldValue("type", e.target.value);
                      }}>
                      <option value="">Select Type</option>
                      {type.map((value) => {
                        return (
                          <option value={value.value}>{value.name}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </li>
              <li>
                <div className="input-box">
                  <div className="input-label">Start Date</div>
                  <div className="input_field">
                    <input
                      type="date"
                      className="input"
                      name="startDate"
                      placeholder="Enter startDate"
                      min={handleStartDate()}
                      // max={startdate}
                      // min={new Date().toJSON().slice(0, 10)}
                      value={addContestFormik?.values?.startDate}
                      onChange={
                        (e) => {
                          setSelectStartdate(e.target.value);
                          addContestFormik.handleChange(e)
                        }
                      }
                      onKeyDown={(e) => e.preventDefault()}

                    />
                  </div>
                </div>
              </li>
              <li>
                <div className="input-box">
                  <div className="input-label">End Date</div>
                  <div className="input_field">
                    <input
                      type="date"
                      className="input"
                      name="endDate"
                      placeholder="End Date"
                      min={handleEndDate()}
                      value={addContestFormik.values.endDate}
                      onChange={addContestFormik.handleChange}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  </div>
                </div>
              </li>
              <li>
                <div className="input-box">
                  <div className="input-label">Timer</div>
                  <div className="input_field">
                    <select
                      required
                      type="text"
                      className="input"
                      placeholder="Level"
                      name="timer"
                      value={addContestFormik?.values?.timer}
                      onChange={(e) => {
                        addContestFormik.setFieldValue("timer", e.target.value);
                      }}
                    >
                      <option value="">Select Timer</option>
                      {timer.map((value) => {
                        return (
                          <option value={value.value}>{value.name}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </li>
              <li>
                <div className="input-box">
                  <div className="input-label">Coins</div>
                  <div className="input_field">
                    <input
                      type="number"
                      className="input"
                      name="coins_per_question"
                      placeholder="Enter coins"
                      value={addContestFormik.values.coins_per_question}
                      onChange={addContestFormik.handleChange}
                    />
                  </div>
                </div>
              </li>
              <li>
                <div className="input-box">
                  <div className="input-label">Module Category</div>
                  <div className="input_field">
                    <select
                      required
                      type="text"
                      className="input"
                      placeholder="Level"
                      name="timer"
                      value={addContestFormik?.values?.moduleCategoryId}
                      onChange={(e) => {
                        addContestFormik.setFieldValue(
                          "moduleCategoryId",
                          e.target.value
                        );
                      }}
                    >
                      <option value="">Select category</option>
                      {category.map((value) => {
                        return <option value={value._id}>{value.name}</option>;
                      })}
                    </select>
                  </div>
                </div>
              </li>
            </ul>

            <div>
              <div>
                <div className="input-box ">
                  <div className="flex_btn">
                    <Link
                      className="btn"
                      style={{ color: "white", backgroundColor: "red" }}
                      color="red"
                      to={"/module/list"}>
                      Cancel
                    </Link>
                    <button className="btn btn-success" type="submit">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ModuleEdit;
