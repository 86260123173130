import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "@fortawesome/fontawesome-free/css/all.css";
import "react-notifications-component/dist/theme.css";
import { ReactNotifications } from "react-notifications-component";
import { Toaster } from 'react-hot-toast';
const theme = createTheme({
  typography: {
    fontFamily: "poppins",
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ReactNotifications />
    <Toaster  position="top-right"/>
    <App />
  </React.StrictMode>
);
