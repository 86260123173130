import React, { useEffect, useState } from "react";
import arr1 from "../assets/images/v_single.svg";
import arr2 from "../assets/images/v_dbl.svg";
// import arr1 from "../assets/images/v_single.png";
// import arr2 from "../assets/images/v_dbl.png";
import { CelenderCard } from "./celenderCard";
import moment from "moment";

export const DeleveryCelender = ({ data, fetchDeliveryCalenderList, viewDetails }) => {
  // Set initial state to the current date
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(currentDate);
  // Function to get the start of the current week (Sunday)
  const getWeekStart = (date) => {
    const day = date.getDay();
    const diff = date.getDate() - day;
    return new Date(date.setDate(diff));
  };

  // Calculate current week's start date
  const startOfWeek = getWeekStart(new Date(currentDate));

  // Generate dates for the current week (Sunday to Saturday)
  const getWeekDates = () => {
    return Array.from({ length: 7 }, (_, i) => {
      const date = new Date(startOfWeek);
      date.setDate(startOfWeek.getDate() + i);
      return date;
    });
  };

  // Navigation functions
  const goToPreviousWeek = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() - 7);
      return newDate;
    });
  };

  const goToNextWeek = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + 7);
      return newDate;
    });
  };

  const goToPreviousMonth = () => {
    setCurrentDate((prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1));
  };

  const goToNextMonth = () => {
    setCurrentDate((prevDate) => new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1));
  };

  const weekDates = getWeekDates();
  useEffect(() => {
    const sd = new Date(selectedDate)
    const cd = new Date(currentDate)
    if (sd.getMonth() !== cd.getMonth()) {
      setCurrentDate(new Date(selectedDate))
    }
    fetchDeliveryCalenderList(moment(selectedDate).format("YYYY-MM-DD"))
  }, [selectedDate])

  return (
    <>
      <div className="card1-box">
        <div className="card1">
          <div className="card1_head">
            <div className="card1_ttl">Delivery Calendar</div>
          </div>
          <div className="card1_top_cont">
            <div className="card1_top_cont-head">
              {currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
              <div className="celender_btn prev2" onClick={goToPreviousMonth}>
                <img src={arr2} alt="previous_month" />
              </div>
              <div className="celender_btn prev1" onClick={goToPreviousWeek}>
                <img src={arr1} alt="previous_week" />
              </div>
              <div className="celender_btn next1" onClick={goToNextWeek}>
                <img src={arr1} alt="next_week" />
              </div>
              <div className="celender_btn next2" onClick={goToNextMonth}>
                <img src={arr2} alt="next_month" />
              </div>
            </div>
            <div className="card1_top_cont-days">
              {weekDates.map((date, index) => (
                <div
                  key={index}
                  onClick={() => setSelectedDate(new Date(date))}
                  className={`card1_top_cont-day ${date.toDateString() === new Date(selectedDate).toDateString() ? 'active' : ''
                    }`}
                >
                  <span>{date.toLocaleString('default', { weekday: 'short' })}</span>
                  <span>{String(date.getDate()).padStart(2, '0')}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="card1_cont p_020">
            {data?.length
              ?
              data.map(el => <CelenderCard key={el._id} data={el} viewDetails={viewDetails} />)
              : <div className="text-center">No data found</div>}
          </div>
        </div>
      </div>
    </>
  );
};
