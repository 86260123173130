import moment from "moment";
import { Offcanvas } from "react-bootstrap";
import { handleCurrency } from "../../helper/helper";
import { useEffect, useState } from "react";
import { fetchDashboardBidDetails } from "../../action/dashboard";
import { useLocation } from "react-router-dom";

export default function ViewBidDetails({
  type,
  setType,
  isOpenModal,
  setIsOpenModal,
  warehouseId,
  setwarehouseId,
  addressId,
  setaddressId,
  setViewBidDetails,
  viewBidDetails,
  afterRead
}) {


  useEffect(() => {
    if (isOpenModal) {
      fetchBidDetails(isOpenModal, type, warehouseId, addressId);
    }
  }, [isOpenModal]);
  useEffect(() => {
    if (!isOpenModal) {
      setViewBidDetails({
        loading: false,
        data: null,
      });
    }
  }, [isOpenModal]);
  const fetchBidDetails = async (id, type) => {
    setViewBidDetails({ loading: true, data: null });
    const result = await fetchDashboardBidDetails(
      id,
      type,
      warehouseId,
      addressId,
    );
    setViewBidDetails({ loading: false, data: result?.data || null });
  };

  return (
    <Offcanvas
      show={isOpenModal !== 0}
      onHide={afterRead}
      placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>View Details</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="add_buyer_content">
          {viewBidDetails.loading ? (
            <div className="loader text-center">Please wait...</div>
          ) : (
            <form>
              <div className="row">
                <div className="col-lg-12">
                  <div className="input-box">
                    <div className="input-label">Order Id</div>
                    <div className="input_field">
                      <div className="input">
                        {viewBidDetails?.data?.orderId || ""}
                      </div>
                    </div>
                  </div>
                  <div className="input-box">
                    <div className="input-label">
                      {type == 1 ? "Request Date" : "Delivery Date"}
                    </div>
                    {type == 1 ? (
                      <div className="input_field">
                        <div className="input">
                          {viewBidDetails?.data?.createdAt
                            ? moment(viewBidDetails?.data?.createdAt).format(
                              "DD/MM/YYYY"
                            )
                            : ""}
                        </div>
                      </div>
                    ) : type == 2 ? (
                      <div className="input_field">
                        <div className="input">
                          {viewBidDetails?.data?.deliveryDate
                            ? moment(viewBidDetails?.data?.deliveryDate).format(
                              "DD/MM/YYYY"
                            )
                            : ""}
                        </div>
                      </div>
                    ) :
                      type == 3 ?
                        <div className="input_field">
                          <div className="input">{viewBidDetails?.data?.warehouseBidDetails[0]?.deliveryDate
                            ? moment(
                              viewBidDetails?.data?.warehouseBidDetails[0]?.deliveryDate
                            ).format("DD/MM/YYYY")
                            : ""}
                          </div>
                        </div>
                        : (
                          <div className="input_field">
                            {warehouseId && addressId ? (
                              <div className="input">
                                {viewBidDetails?.data?.warehouseBidDetails
                                  ?.deliveryDate
                                  ? moment(
                                    viewBidDetails?.data?.warehouseBidDetails
                                      ?.deliveryDate
                                  ).format("DD/MM/YYYY")
                                  : ""}
                              </div>
                            ) : (
                              <div className="input">
                                {viewBidDetails?.data?.warehouseDetails
                                  ?.acceptedQty &&
                                  viewBidDetails?.data?.warehouseDetails
                                    ?.acceptedQty !== 0
                                  ? viewBidDetails?.data?.warehouseDetails
                                    ?.deliveryDate
                                    ? moment(
                                      viewBidDetails?.data?.warehouseDetails
                                        ?.deliveryDate
                                    ).format("DD/MM/YYYY")
                                    : ""
                                  : viewBidDetails?.data?.deliveryDate
                                    ? moment(
                                      viewBidDetails?.data?.deliveryDate
                                    ).format("DD/MM/YYYY")
                                    : ""}
                              </div>
                            )}
                          </div>
                        )}
                  </div>
                  {type == 1 ? (
                    ""
                  ) : (
                    <div className="input-box">
                      <div className="input-label">Order Date</div>
                      <div className="input_field">
                        {type == 2 ? (
                          <div className="input">
                            {viewBidDetails?.data?.bidDetails?.createdAt
                              ? moment(
                                viewBidDetails?.data?.bidDetails?.createdAt
                              ).format("DD/MM/YYYY")
                              : ""}
                          </div>
                        ) :
                          type == 3 ? <div className="input">{viewBidDetails?.data?.warehouseBidDetails[0]?.createdAt
                            ? moment(
                              viewBidDetails?.data?.warehouseBidDetails[0]?.createdAt
                            ).format("DD/MM/YYYY")
                            : ""}</div>
                            :
                            (
                              <div className="input">
                                {viewBidDetails?.data?.createdAt
                                  ? moment(viewBidDetails?.data?.createdAt).format(
                                    "DD/MM/YYYY"
                                  )
                                  : ""}
                              </div>
                            )}
                      </div>
                    </div>
                  )}
                  <div className="input-box">
                    <div className="input-label">Address</div>
                    <div className="input_field">
                      {(type == 1 || type == 3) ? (
                        <div className="input">
                          {viewBidDetails?.data?.gaons?.name}
                        </div>
                      ) : type == 2 ? (
                        <div className="input">
                          {viewBidDetails?.data?.location?.address}
                        </div>
                      ) : (
                        <div className="input">
                          {warehouseId && addressId
                            ? viewBidDetails?.data?.warehouseAddress?.location
                              ?.address
                            : viewBidDetails?.data?.addressDetails?.location
                              ?.address}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="input-box">
                    <div className="input-label">Product</div>
                    <div className="input_field">
                      <div className="input">
                        {type == 1
                          ? viewBidDetails?.data?.product[0]?.verietyDetails[0]
                            ?.name?.en
                          : type == 2
                            ? viewBidDetails?.data?.product
                            : type == 3 ? viewBidDetails?.data?.product[0]?.verietyDetails[0]?.name?.en
                              : viewBidDetails?.data?.productDetails?.name?.en ||
                              ""}
                      </div>
                    </div>
                  </div>
                  {type !== 1 ? (
                    <div className="input-box">
                      <div className="input-label">Variety</div>
                      <div className="input_field">
                        <div className="input">
                          {type == 1
                            ? viewBidDetails?.data?.product[0]
                              ?.verietyDetails[0]?.name?.en
                            : type == 2
                              ? viewBidDetails?.data?.variety
                              : type == 3 ? viewBidDetails?.data?.product[0]?.verietyDetails[0]?.productDetails?.name?.en
                                : viewBidDetails?.data?.verietyDetails?.name?.en}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="input-box">
                    <div className="input-label">Grade</div>
                    <div className="input_field">
                      <div className="input">
                        {type == 1
                          ? viewBidDetails?.data?.grade
                          : type == 2 || type == 3
                            ? viewBidDetails?.data?.grade

                            : viewBidDetails?.data?.gradeDetails?.name || ""}
                      </div>
                    </div>
                  </div>
                  <div className="input-box">
                    <div className="input-label">Price</div>

                    <div className="input_field">
                      {warehouseId && addressId ? (
                        <div className="input">
                          {handleCurrency()}{" "}
                          {viewBidDetails?.data?.warehouseBidDetails?.price ||
                            ""}
                        </div>
                      ) : (
                        <div className="input">
                          {type == 2 ?
                            <>
                              {handleCurrency()}{" "}
                              {viewBidDetails?.data?.price}
                            </> :
                            type == 3 ?
                              <>
                                {handleCurrency()}{" "}
                                {viewBidDetails?.data?.warehouseBidDetails[0]?.price}
                              </>
                              :

                              <>
                                {handleCurrency()}{" "}
                                {viewBidDetails?.data?.status == 2 ? viewBidDetails?.data?.warehouseBidDetails[0]?.price : viewBidDetails?.data?.warehouseDetails
                                  ?.acceptedQty &&
                                  viewBidDetails?.data?.warehouseDetails
                                    ?.acceptedQty !== 0
                                  ? viewBidDetails?.data?.warehouseDetails?.price ||
                                  " "
                                  : viewBidDetails?.data?.price || " "}</>}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="input-box">
                    <div className="input-label">Quantity</div>
                    <div className="input_field">
                      {type == 1 ? (
                        <div className="input">
                          {/* {viewBidDetails?.data?.status == 4 ? viewBidDetails?.data?.warehouseBidDetails[0]?.quantity : viewBidDetails?.data?.quantity}{" "} */}
                          {(viewBidDetails?.data?.status == 4 || viewBidDetails?.data?.status == 2) ? viewBidDetails?.data?.warehouseBidDetails[0]?.quantity : viewBidDetails?.data?.quantity}{" "}
                          {/* {viewBidDetails?.data?.quantity || " "}{" "} */}
                          {viewBidDetails?.data?.perUnit}
                        </div>
                      ) : type == 2 ? (
                        <div className="input">
                          {viewBidDetails?.data?.status == 5 ? <> {viewBidDetails?.data?.acceptedQty || " "}{" "}</> : <> {viewBidDetails?.data?.quantity || " "}{" "}</>}
                          {viewBidDetails?.data?.unitDetails?.shortcode}
                        </div>

                      ) :
                        type == 3 ?
                          <div className="input">
                            {viewBidDetails?.data?.warehouseBidDetails[0]?.quantity}{" "}
                            {viewBidDetails?.data?.warehouseBidDetails[0]?.unitDetails?.shortcode}
                          </div>
                          : warehouseId && addressId ? (
                            <div className="input">
                              {viewBidDetails?.data?.warehouseBidDetails
                                ?.acceptedQty !== 0
                                ? viewBidDetails?.data?.warehouseBidDetails
                                  ?.acceptedQty
                                : viewBidDetails?.data?.warehouseBidDetails
                                  ?.quantity}{" "}
                              {viewBidDetails?.data?.perUnit}
                              {viewBidDetails?.data?.unitDetails?.shortcode || ""}
                            </div>
                          ) : (
                            <div className="input">
                              {viewBidDetails?.data?.warehouseDetails
                                ?.acceptedQty &&
                                viewBidDetails?.data?.warehouseDetails
                                  ?.acceptedQty !== 0
                                ? viewBidDetails?.data?.warehouseDetails
                                  ?.acceptedQty
                                : viewBidDetails?.data?.quantity || " "}{" "}
                              {viewBidDetails?.data?.perUnit}
                              {viewBidDetails?.data?.unitDetails?.shortcode || ""}
                            </div>
                          )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-3 flex_btn">
                <button
                  onClick={afterRead}
                  className="btn btn-dark"
                  type="button">
                  Close
                </button>
              </div>
            </form>
          )}
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
