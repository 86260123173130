import React from "react";
import { Offcanvas } from "react-bootstrap";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import showNotification from "../../components/notification/showNotification";
import { AddDailyEvent } from "../../action/contest";

const AddEvent = ({ isOpenCanvas, setIsOpenCanvas, selectedDate, setSelectedDate, fetchEventsForDate }) => {
    const addFormik = useFormik({
        initialValues: {
            objective: "",
            objective_img_prompt: "",
            date: "",
        },
        onSubmit: async (values, { resetForm }) => {
            try {
                const response = await AddDailyEvent({
                    objective: values.objective,
                    objective_img_prompt: values.objective_img_prompt,
                    date: moment(selectedDate).format("YYYY-MM-DD"),
                });

                if (response?.data?.status === 1) {
                    fetchEventsForDate(selectedDate);
                    setIsOpenCanvas(false);
                    showNotification("Daily Post", response.data.message, "success");
                    resetForm();
                } else {
                    showNotification("Daily Post", response.data.message, "danger");
                }
            } catch (error) {
                console.error("Error adding event:", error);
                showNotification("Daily Post", "An unexpected error occurred while adding the event.", "danger");
            }
        },
    });

    return (
        <Offcanvas show={isOpenCanvas} onHide={() => setIsOpenCanvas(false)} placement="end">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Add Event</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
            <div className="add_buyer_content">
                <form onSubmit={addFormik.handleSubmit}>
                    <div className="input-box">
                        <div className="input-label">Objective</div>
                        <div className="input_field">
                        <input
                            type="text"
                            className="input"
                            name="objective"
                            placeholder="Enter Objective"
                            value={addFormik.values.objective}
                            onChange={addFormik.handleChange}
                            required
                        />
                        </div>
                    </div>

                    <div className="input-box">
                        <div className="input-label">Objective Image Prompt</div>
                        <div className="input_field">
                        <input
                            type="text"
                            className="input"
                            name="objective_img_prompt"
                            placeholder="Enter Objective Image Prompt"
                            value={addFormik.values.objective_img_prompt}
                            onChange={addFormik.handleChange}
                            required
                        />
                        </div>
                    </div>

                    <div className="input-box">
                        <div className="input-label">Selected Date</div>
                        <div className="date-picker-comp ">
                        <DatePicker
                            selected={selectedDate}
                            onChange={(date) => {
                                setSelectedDate(date);
                                addFormik.setFieldValue("date", date.toISOString().split("T")[0]);
                            }}
                            dateFormat="yyyy-MM-dd"
                            className="date-picker"
                            showYearDropdown
                            showMonthDropdown
                            scrollableYearDropdown
                            onKeyDown={(e) => e.preventDefault()}
                        />
                        </div>
                    </div>

                    <div className="addBuyer_submit-btn">
                                <button className="btn btn-success" type="submit">
                                    Save
                                </button>
                    </div>
                </form>
            </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default AddEvent;
