import React, { useState, useEffect } from "react";
import Pagination from "../../components/pagination/paginationView";
import searchIcon from "../../assets/images/search-lg.svg";
import filerIcon from "../../assets/images/Up_Down.svg";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import plusWhite from "../../assets/images/plus-white.svg";
import moment from "moment";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
  createUser,
  editAdmin,
  fetchAdmin,
  fetchUsersList,
  fetchUserDepartmentListByRoleId,
  assignDepartmentToUser,
  fetchAppUsersList,
  notregistereduser,
} from "../../action/userApi";

import { Button, Dropdown, Modal } from "react-bootstrap";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { Loader } from "../../components/loader";
import infoBtn from "../../assets/images/info-circle.svg";
import { useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import { SingleErrorMessage } from "../singleErrorMessage";
import {
  fetchAppUserCount,
  fetchCountry,
  fetchDistricts,
  fetchGaons,
  fetchStates,
  fetchSubDistricts,
} from "../../action/location";

import { gaonTextBasedOnRoleTYpe } from "../../helper/constants";
import AsyncSelect from "react-select/async";
import { SearchBox } from "../../components/SearchBox";
import AppUsersList from "../../components/usersComponents/appUsersList";
import OtherUsersList from "../../components/usersComponents/otherUsersList";
import NotRegisteredUser from "../../components/usersComponents/notRegisterUsersList";

const UserList = () => {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const roleTyped = localStorage.getItem("roleType");
  const [userList, setUserList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [tabIndex, setTabIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState();
  const [totalAppData, setTotalAppData] = useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [roleData, setRoleData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");
  const [roleId, setRoleId] = useState("");
  const [isOpenCanvas, setIsOpenCanvas] = useState(false);
  const [gaonList, setGaonList] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [stateId, setStateId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [blockId, setBlockId] = useState("");
  const [countryId, setCountryId] = useState("");
  const [isnotregisterd, setIsNotregistered] = useState([]);
  const [appUserCount, setAppUserCount] = useState(0);
  const [assignDepartment, setAssignDepartment] = useState({
    loading: false,
    showModel: false,
    currentUserTypeDetails: [],
    roles: [],
    _id: "",
  });
  const getAdmin = async () => {
    const res = await fetchAdmin();
    if (res.status) {
      setRoleData(res.data);
    }
  };
  const formatDepartmentListForSelect = (values) => {
    return values?.length
      ? values.map((k) => ({ label: k.name, value: k._id }))
      : [];
  };
  useEffect(() => {
    getAdmin();
  }, []);

  const loadAssignDepartmentList = async (search) => {
    const roleId = assignDepartment?.roles?.[0];
    if (roleId) {
      const result = await fetchUserDepartmentListByRoleId(roleId);
      return result?.data?.length
        ? result.data
          .filter((k) => k.name.toLowerCase().includes(search.toLowerCase()))
          .map((el) => ({ label: el.name, value: el._id }))
        : [];
    } else {
      return [];
    }
  };

  const resetAndCloseAssignmentDepartment = () => {
    setAssignDepartment({
      loading: false,
      showModel: false,
      currentUserTypeDetails: [],
      roles: [],
      _id: "",
    });
  };

  const onCloseToaste = () => {
    toast.dismiss();
  };

  const handleReset = () => {
    const resetSearchValue = ""; // Reset search value
    const resetRoleValue = "";
    setSearch("");
    setRoleId("");
    // getUserData(resetSearchValue, resetRoleValue);
    // getAppUserData(resetSearchValue, resetRoleValue);
  };

  useEffect(() => {
    // getUserData();
    // getAppUserData();
  }, [pageNo, pageSize, sort, order]);
  useEffect(() => {
    // getUserData();
  }, [roleId]);

  const handleUpdate = async () => {
    setIsLoading(true);
    const response = await editAdmin({
      userId: selectedUser,
      roleId: selectedRole,
    });
    if (response.status) {
      // getUserData();
      toast.custom(
        <SingleErrorMessage
          data={"Role Updated Successfully"}
          error={false}
          bColor="#007500"
        />
      );
    } else {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
    setIsOpenModal(false);
    setIsLoading(false);
  };

  const handleRoleChange = (userId, roleId) => {
    setSelectedUser(userId);
    setSelectedRole(roleId);
    setIsOpenModal(true);
  };

  const userFormik = useFormik({
    initialValues: {
      name: "",
      mobile: "",
      email: "",
      roleId: "",
      roleType: "",
      gaonId: "",
      blockId: "",
      districtId: "",
      stateId: "",
      countryId: "",
      address: "",
      coordinates: [0, 0],
      postalCode: "",
      location: "",
    },

    onSubmit: async (values) => {
      const response = await createUser(values);
      if (response.status) {
        setIsOpenCanvas(false);
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
        setIsLoading(false);
      } else {
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
      }
    },
  });

  const fetchGaonList = async () => {
    setIsLoading(true);
    const response = await fetchGaons(blockId);
    if (response.status) {
      const arr = response?.data.map((item) => {
        return { value: item._id, label: item.name };
      });
      setGaonList(arr);
    }
  };

  const getCountry = async () => {
    const res = await fetchCountry();
    if (res.status) {
      const arr = res?.data?.map((item) => {
        return { value: item._id, label: item.name };
      });
      setCountries(arr);
    }
  };
  const getUserCount = async () => {
    const res = await fetchAppUserCount();
    if (res.status) {
      setAppUserCount(res.data?.count);
    }
  };

  const getState = async () => {
    const res = await fetchStates(countryId);
    if (res.status) {
      const arr = res?.data?.map((item) => {
        return { value: item._id, label: item.name };
      });
      setStates(arr);
    }
  };

  const getDistrict = async () => {
    const res = await fetchDistricts(stateId);
    if (res.status) {
      const arr = res?.data?.map((item) => {
        return { value: item._id, label: item.name };
      });
      setDistricts(arr);
    }
  };

  const getSubDistrict = async () => {
    const res = await fetchSubDistricts(districtId);
    if (res.status) {
      const arr = res?.data?.map((item) => {
        return { value: item._id, label: item.name };
      });
      setBlocks(arr);
    }
  };

  useEffect(() => {
    if (blockId) fetchGaonList();
  }, [blockId]);

  useEffect(() => {
    getCountry();
    getUserCount();
  }, []);
  useEffect(() => {
    // getUserData();
    // getAppUserData();
  }, [search]);

  useEffect(() => {
    getState();
    // if (countryId) getState();
  }, [countryId]);

  useEffect(() => {
    if (stateId) getDistrict();
  }, [stateId]);

  useEffect(() => {
    if (districtId) getSubDistrict();
  }, [districtId]);

  const handleAssignDepartmentUpdate = async () => {
    const payload = {
      userTypeId: assignDepartment?.currentUserTypeDetails?.length
        ? assignDepartment.currentUserTypeDetails.map((k) => k._id)
        : [],
      roleId: assignDepartment.roles[0],
    };
    if (payload.userTypeId.length > 0) {
      const result = await assignDepartmentToUser(
        assignDepartment._id,
        payload
      );
      if (result.status) {
        toast.custom(
          <SingleErrorMessage
            data={result.message}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
        resetAndCloseAssignmentDepartment();
        // getUserData();
      } else {
        toast.custom(
          <SingleErrorMessage
            data={result.message}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
      }
    }
  };
  return (
    <>
      <div className="payout_inner_content">
        <div className="payout_data-head">
          <div className="payout_data-head-top">
            <div className="payout_data-left p-0">
              <div className="payout_data_ttl1">Users</div>
            </div>
            {tabIndex == 1 ? (
              role == "SUPERADMIN" && (
                <div className="users_count_">
                  User registrations in last 24 hours :
                  <span>{appUserCount}</span>
                  <img src={plusWhite} alt="" className="payout_down" />
                </div>
              )
            ) : (
              <button
                color="info"
                tag="a"
                type="button"
                onClick={() => {
                  // setIsOpenCanvas(true)
                  navigate("/user/add");
                }}
                // to="/user/add"
                className="btn btn-info addbbtn"
              >
                Add New User
                <img src={plusWhite} alt="" className="payout_down" />
              </button>
            )}

            {role == "ADMIN" ? (
              <button
                color="info"
                tag="a"
                type="button"
                onClick={() => {
                  navigate("/user/add");
                }}
                className="btn btn-info addbbtn"
              >
                Add New User
                <img src={plusWhite} alt="" className="payout_down" />
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="payout_data-content tablePage_content_header">
          {/* <div className="payout_data-head">
            <div className="payout_data-head-top">
              <div className="payout_data-left">
                <div className="payout_data_ttl">
                  <Breadcrumb paths={[{ name: "Users" }]} />
                </div>

              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="col-lg-3">
              <div className="input-label">Search</div>
              <SearchBox search={search} setSearch={setSearch} />
            </div>
            <div className="col-lg-3">
              <div className="input-box mt-0">
                <div className="input-label">Role</div>
                <select
                  className="custom_dropdn"
                  onChange={(e) => setRoleId(e.target.value)}
                  value={roleId}
                >
                  <option value="">Select Role</option>
                  {roleData.map((role) => (
                    <option key={role._id} value={role._id}>
                      {role.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-3 d-flex align-items-end">
              <button
                className="btn_submit btn btn-dark"
                type="button"
                onClick={() => {
                  handleReset();
                }}
                disabled={!search && !roleId}
              >
                Reset
              </button>
            </div>
            {/* <div className="col-lg-3 submit_btn_grp ">
              <button
                className="btn_submit btn btn-dark"
                type="button"
                onClick={() => {
                  handleReset();
                }}
                disabled={!search && !roleId}
              >
                Reset
              </button>
              <button
                className={`btn btn-success btn_submit ${search ? "active" : ""
                  }`}
                type="submit"
                disabled={!search && !roleId}
                onClick={() => {
                  // if (tabIndex == 1) {
                  //   role == "SUPERADMIN" ? getAppUserData() : getUserData();
                  // } else {
                  //   getUserData();
                  // }
                }}
              >
                Search
              </button>
            </div> */}
          </div>
        </div>

        <div className="tab_box">
          <Tabs>
            <TabList>
              {role == "SUPERADMIN" ? (
                <>
                  <Tab
                    onClick={() => {
                      setTabIndex(1);
                    }}
                  >
                    App Users
                  </Tab>
                  <Tab
                    onClick={() => {
                      setTabIndex(2);
                    }}
                  >
                    Other Users
                  </Tab>
                  <Tab
                    onClick={() => {
                      setTabIndex(3);
                    }}
                  >
                    Not registered user
                  </Tab>
                </>
              ) : (
                <Tab
                  onClick={() => {
                    setTabIndex(2);
                  }}
                >
                  Users
                </Tab>
              )}
            </TabList>
            {role == "SUPERADMIN" && (
              <TabPanel>
                <AppUsersList
                  searchValue={search}
                  sort={sort}
                  setSort={setSort}
                  order={order}
                  setOrder={setOrder}
                  handleRoleChange={handleRoleChange}
                  roleData={roleData}
                  setAssignDepartment={setAssignDepartment}
                />
              </TabPanel>
            )}
            <TabPanel>
              <OtherUsersList
                searchRole={roleId}
                searchValue={search}
                sort={sort}
                setSort={setSort}
                order={order}
                setOrder={setOrder}
                handleRoleChange={handleRoleChange}
                roleData={roleData}
                setAssignDepartment={setAssignDepartment}
              />
            </TabPanel>
            <TabPanel>
              <NotRegisteredUser
                searchRole={roleId}
                searchValue={search}
                sort={sort}
                setSort={setSort}
                order={order}
                setOrder={setOrder}
              />
            </TabPanel>
          </Tabs>

          <Modal show={isOpenModal} onHide={() => setIsOpenModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Update Role</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to change the role of this user?
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-dark"
                variant="secondary"
                onClick={() => setIsOpenModal(false)}
              >
                No
              </button>
              <button
                className="btn btn-success"
                variant="primary"
                onClick={handleUpdate}
              >
                Yes
              </button>
            </Modal.Footer>
          </Modal>

          {/* off canvas to create warehouse new request to farmer*/}
          <Offcanvas
            show={isOpenCanvas}
            onHide={() => {
              setIsOpenCanvas(false);
            }}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>New Request</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="add_buyer_content">
                <form action="" onSubmit={userFormik.handleSubmit}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="input-box">
                        <div className="input-label">
                          Name
                          <span>*</span>
                        </div>
                        <div className="input_field">
                          <input
                            type="text"
                            className="input"
                            placeholder="Enter name"
                            id="beneficiary"
                            name="name"
                            onChange={(e) => {
                              userFormik.setFieldValue("name", e.target.value);
                            }}
                            value={userFormik.values.name}
                            required
                          />
                        </div>
                      </div>
                      <div className="input-box">
                        <div className="input-label">
                          Mobile
                          <span>*</span>
                        </div>
                        <div className="input_field">
                          <input
                            type="text"
                            className="input"
                            placeholder="Enter contact no."
                            id="beneficiary"
                            name="mobile"
                            onChange={(e) => {
                              userFormik.setFieldValue(
                                "mobile",
                                e.target.value
                              );
                            }}
                            value={userFormik.values.mobile}
                            required
                          />
                        </div>
                      </div>
                      <div className="input-box">
                        <div className="input-label">
                          Email Id
                          <span>*</span>
                        </div>
                        <div className="input_field">
                          <input
                            type="text"
                            className="input"
                            placeholder="Enter email"
                            id="beneficiary"
                            name="email"
                            onChange={(e) => {
                              userFormik.setFieldValue("email", e.target.value);
                            }}
                            value={userFormik.values.email}
                            required
                          />
                        </div>
                      </div>
                      <div className="input-box">
                        <div className="input-label">
                          Role
                          <span>*</span>
                        </div>
                        <div className="input_field">
                          <select
                            className="input"
                            size="sm"
                            ariaLabel="Role"
                            onChange={(e) => {
                              userFormik.setFieldValue(
                                "roleId",
                                e.target.value
                              );
                            }}
                          >
                            <option value="">Select Role</option>
                            {roleData.map((i) => (
                              <option key={i._id} value={i._id}>
                                {i.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="input-box">
                        <div className="input-label">
                          Country
                          <span>*</span>
                        </div>

                        <select
                          name="countryId"
                          options={countries.filter(
                            (remove) => remove.label !== "India"
                          )}
                          onChange={(selectedOptions) => {
                            const id = Array.isArray(selectedOptions)
                              ? selectedOptions.map((option) => option.value)
                              : selectedOptions
                                ? [selectedOptions.value]
                                : [];
                            userFormik.setFieldValue("countryId", id);
                            setCountryId(id);
                          }}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          required
                        />
                      </div>
                      <div className="input-box">
                        <div className="input-label">
                          State
                          <span>*</span>
                        </div>

                        <select
                          name="stateId"
                          options={states}
                          onChange={(selectedOptions) => {
                            const id = Array.isArray(selectedOptions)
                              ? selectedOptions.map((option) => option.value)
                              : selectedOptions
                                ? [selectedOptions.value]
                                : [];
                            userFormik.setFieldValue("stateId", id);
                            setStateId(id);
                          }}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          required
                        />
                      </div>
                      <div className="input-box">
                        <div className="input-label">
                          District
                          <span>*</span>
                        </div>

                        <select
                          name="districtId"
                          options={districts}
                          onChange={(selectedOptions) => {
                            const id = Array.isArray(selectedOptions)
                              ? selectedOptions.map((option) => option.value)
                              : selectedOptions
                                ? [selectedOptions.value]
                                : [];
                            userFormik.setFieldValue("districtId", id);
                            setDistrictId(id);
                          }}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          required
                        />
                      </div>
                      <div className="input-box">
                        <div className="input-label">
                          Sub District
                          <span>*</span>
                        </div>

                        <select
                          name="blockId"
                          options={blocks}
                          onChange={(selectedOptions) => {
                            const id = Array.isArray(selectedOptions)
                              ? selectedOptions.map((option) => option.value)
                              : selectedOptions
                                ? [selectedOptions.value]
                                : [];
                            userFormik.setFieldValue("blockId", id);
                            setBlockId(id);
                          }}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          required
                        />
                      </div>
                      <div className="input-box">
                        <div className="input-label">
                          {gaonTextBasedOnRoleTYpe()}
                          <span>*</span>
                        </div>

                        <select
                          name="gaonId"
                          options={gaonList}
                          onChange={(selectedOptions) => {
                            const id = Array.isArray(selectedOptions)
                              ? selectedOptions.map((option) => option.value)
                              : selectedOptions
                                ? [selectedOptions.value]
                                : [];
                            userFormik.setFieldValue("gaonId", id);
                          }}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          required
                        />
                      </div>
                      <div className="input-box">
                        <div className="input-label">
                          Address
                          <span>*</span>
                        </div>
                        <div className="input_field">
                          <input
                            type="text"
                            className="input"
                            placeholder="Enter address"
                            id="beneficiary"
                            name="address"
                            onChange={(e) => {
                              userFormik.setFieldValue(
                                "address",
                                e.target.value
                              );
                            }}
                            value={userFormik.values.address}
                            required
                          />
                        </div>
                      </div>
                      {roleTyped !== "FARMLINK" && (
                        <div className="input-box">
                          <div className="input-label">
                            Postal Code
                            <span>*</span>
                          </div>
                          <div className="input_field mt-3">
                            <input
                              type="text"
                              className="input"
                              placeholder="Enter address"
                              id="beneficiary"
                              name="postalCode"
                              onChange={(e) => {
                                userFormik.setFieldValue(
                                  "postalCode",
                                  e.target.value
                                );
                              }}
                              value={userFormik.values.postalCode}
                              required
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="flex_btn mt-3 ">
                    <button className="btn btn-success" type="submit">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </Offcanvas.Body>
          </Offcanvas>

          <Modal
            show={assignDepartment.showModel}
          // onHide={() => resetAndCloseAssignmentDepartment()}
          >
            <Modal.Header>
              <Modal.Title>Assign Department</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="mb-3">
                Please select a department to assign to the user
              </div>
              <AsyncSelect
                cacheOptions
                defaultOptions
                onChange={(g) =>
                  setAssignDepartment((k) => ({
                    ...k,
                    currentUserTypeDetails: [{ _id: g.value, name: g.label }],
                  }))
                }
                value={formatDepartmentListForSelect(
                  assignDepartment?.currentUserTypeDetails || []
                )}
                loadOptions={loadAssignDepartmentList}
              />
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-dark"
                variant="secondary"
                onClick={() => resetAndCloseAssignmentDepartment()}
              >
                Cancel
              </button>
              <button
                className="btn btn-success"
                variant="primary"
                onClick={handleAssignDepartmentUpdate}
              >
                Submit
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default UserList;
