import React, { useState, useEffect } from "react";
import plusWhite from "../../assets/images/plus-white.svg";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { TabPanel, Tabs } from "react-tabs";
import trashIcon from "../../assets/images/trash-03.svg";
import editIcon from "../../assets/images/edit-02.svg";
import contestIcon from "../../assets/images/dashboard copy.svg";

import Pagination from "../../components/pagination/paginationView";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { Loader } from "../../components/loader";
import {
  deleteModule,
  getModuleList,
  updateContestStatus,
} from "../../action/contest";
import moment from "moment";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { SingleErrorMessage } from "../singleErrorMessage";
import { Button, Modal } from "react-bootstrap";

const ModuleList = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalData, setTotalData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [startStatusLoading, setStartStatusLoading] = useState(false);
  const [moduleId, setModuleId] = useState("");
  const [deleteModel, setDeleteModel] = useState(false);

  const getCategoryPage = async (page) => {
    try {
      setIsLoading(true);
      const response = await getModuleList(page, pageSize); // Adjust 10 to the desired number of items per page
      if (response.status) {
        setCategoryList(response.data.details);
        setTotalData(response.data.count);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch category data", error);
    }
  };

  const updateContestStartStatus = async (id, status) => {
    try {
      setStartStatusLoading(id);
      const response = await updateContestStatus(id, !status); // Adjust 10 to the desired number of items per page
      if (response.status) {
        const r2 = await getModuleList(pageNo, pageSize); // Adjust 10 to the desired number of items per page
        if (r2.status) {
          setCategoryList(r2.data.details);
          setTotalData(r2.data.count);
        }
        setStartStatusLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch category data", error);
    }
  };

  const onCloseToaste = () => {
    toast.dismiss();
  };

  const deleteModuledata = async () => {
    const response = await deleteModule(moduleId);
    if (response.status) {
      toast.custom(
        <SingleErrorMessage
          data={"Contest deleted Successfully!"}
          error={false}
          bColor="#175FC9"
          onClose={onCloseToaste}
        />
      );
      setDeleteModel(false);
      getCategoryPage(pageNo);
    } else {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
  };
  useEffect(() => {
    getCategoryPage(pageNo);
  }, [pageNo, pageSize]);

  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top mb-2">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              {" "}
              <Breadcrumb paths={[{ name: "Contest" }]} />
            </div>
          </div>
          <Link className="btn btn-info addbbtn" color="info" to={"/module/add"}>
            Create New
            <img src={plusWhite} alt="" className="payout_down" />
          </Link>
        </div>
      </div>
      <div className="tab_box">
        <Tabs>
          <TabPanel>
            <div className="">
              <table className="payout_table upi-mandate_table">
                <thead>
                  <tr className="tableHead">
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Title</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Start Date</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">End Date</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Prize</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Coins</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Type</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Question Count</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">
                        Total Participants
                      </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">
                        Correct Answers By
                      </div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Is Started</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="15">
                        <Loader />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {categoryList?.length > 0 ? (
                        categoryList?.map((item) => (
                          <tr key={item._id}>
                            <td>{item?.title?.en}</td>
                            <td>
                              {moment(item?.startDate).format("DD/MM/YYYY")}
                            </td>
                            <td>
                              {moment(item?.endDate).format("DD/MM/YYYY")}
                            </td>
                            <td>{item?.price}</td>
                            <td>{item?.coins_per_question ?? 0}</td>
                            <td>
                              {" "}
                              {typeof item?.moduleType === "string"
                                ? item?.moduleType
                                : item?.moduleType?.map((type, index) => {
                                  let comma =
                                    index < item?.moduleType?.length - 1
                                      ? ", "
                                      : "";
                                  return type + comma;
                                })}
                            </td>
                            <td>{item?.contestCount}</td>
                            <td>{item?.totalParticipants}</td>
                            <td>{item?.correctAnswerCount}</td>
                            <td>
                              <div className="d-flex">
                                {item?.isDisplay ? "Yes" : "No"}
                                {startStatusLoading === item._id ? (
                                  <div className="spinner ms-4"></div>
                                ) : (
                                  <div className="toggle-switch ms-4">
                                    <input
                                      type="checkbox"
                                      id={"toggle" + item._id}
                                      className="toggle-checkbox"
                                      checked={item.isDisplay}
                                      onChange={() =>
                                        updateContestStartStatus(
                                          item._id,
                                          item?.isDisplay
                                        )
                                      }
                                    />
                                    <label
                                      for={"toggle" + item._id}
                                      className="toggle-label"
                                    ></label>
                                  </div>
                                )}
                              </div>
                            </td>
                            <td className="three_btn">
                              <Link to={`/contest/list/${item._id}`}>
                                <img src={contestIcon} alt="/" />
                              </Link>
                              <Link to={`/module/edit/${item._id}`}>
                                <img src={editIcon} alt="Edit icon" />
                              </Link>
                              <Link
                                className="dlt_btn"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setDeleteModel(true);
                                  setModuleId(item._id);
                                }}
                              >
                                <img
                                  src={trashIcon}
                                  style={{
                                    height: "26px",
                                  }}
                                  alt="delete icon"
                                />
                              </Link>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="15" className="text_center">
                            No data available
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </TabPanel>
        </Tabs>

        {categoryList.length > 0 && (
          <>
            <Pagination
              currentPage={pageNo}
              totalCount={totalData}
              pageSize={pageSize}
              onPageChange={(page) => setPageNo(page)}
              changePageSize={(page) => {
                setPageSize(page);
                setPageNo(1);
              }}
            />
          </>
        )}
      </div>
      <Modal
        show={deleteModel}
        onHide={() => {
          setDeleteModel(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete the contest</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this contest?</Modal.Body>
        <Modal.Footer>
          <button
            variant="secondary"
             className="btn btn-dark"
            onClick={() => {
              setDeleteModel(false);
            }}
          >
            No
          </button>
          <button
            className="btn btn-success"
            variant="primary"
            onClick={() => {
              deleteModuledata();
            }}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModuleList;
