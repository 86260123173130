/* eslint-disable no-unreachable */
import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { TabPanel, Tabs } from "react-tabs";
import { Loader } from "../../components/loader";
import Pagination from "../../components/pagination/paginationView";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { getMe, getNotifications, putNotifications } from "../../action/users";
import infoBtn from "../../assets/images/info-circle.svg";
import ViewBidDetails from "../../components/popup/viewBidDetails";
import SvgTypeH2 from "./../../components/icon/bootstrap/TypeH2";
import { Link } from "react-router-dom";
import leftArrow from "../../assets/images/navigate_left.svg";
import Collapsible from "react-collapsible";
export const Notiifcations = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [type, setType] = useState(0);
  const [warehouseId, setwarehouseId] = useState("");
  const [addressId, setaddressId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [id, setId] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(0);
  const fetchBuyerList = async ({ loading }) => {
    setIsLoading(loading);
    const response = await getNotifications(currentPage, paginationValue);
    if (response.status) {
      setList(response?.data?.list);
      setTotalRecords(response?.data?.count);
      setIsLoading(false);
    }
  };

  // const viewNotificationData = (data) => {
  //   console.log("notification called");
  //   if (type == 1) {
  //     console.log("notification works");
  //     setIsOpenModal(1);
  //   }
  // };
  const readNotification = async (id, bidId, type, warehouseId, addressId) => {
    setIsOpenModal(1);
    setType(type);
    setwarehouseId(warehouseId);
    setaddressId(addressId);
    const response = await putNotifications(id);
    if (response.status) {
      setIsOpenModal(bidId);
      // if (role == "WAREHOUSE") {
      //   navigate(`/bidWarehouse`);
      // } else {
      //   navigate(`/buyerReqest/list`);
      // }
    }
  };
  useEffect(() => {
    fetchBuyerList({ loading: true });
  }, [currentPage, paginationValue]);
  const [viewBidDetails, setViewBidDetails] = useState({
    loading: false,
    data: null,
  });
  const afterRead = () => {
    setIsOpenModal(0);
    setType(0);
    setViewBidDetails((c) => ({ ...c, data: null }));
    fetchBuyerList({ loading: false });
  };
  const hasUnRead = (item) => {
    return item.list.find((el) => !el.isRead);
  };
  return (
    <>
      <div className="payout_data-content">
        <div className="payout_data-head">
          <div className="payout_data-head-top">
            <div className="payout_data-left p-0">
              <div className="payout_data_ttl">
                <Breadcrumb
                  paths={[
                    {
                      name: `Dashboard`,
                      link: `/`,
                    },
                    {
                      name: "Notifications",
                      link: `/notification/list`,
                    },
                  ]}
                />
              </div>
            </div>
            <div className="payout_data-right">
              <button
                onClick={() => {
                  navigate(-1);
                }}
                className="back_btnn"
              >
                {" "}
                <img
                  src={leftArrow}
                  alt="Arrow"
                  className="navi_left"
                /> Back{" "}
              </button>
            </div>
          </div>
        </div>
        <div className="tab_box">
          <div className="scroll_v">
            <div className="payout_table  border">
              <div className="tableHead">
                <div className="thHeader">
                  <div className="payoutHead hndIcon notification_head_ttl">
                    Notification
                  </div>
                </div>
                {/* <th className="thHeader">
                  <div className="payoutHead headerAction">Action </div>
                </th> */}
              </div>

              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <Accordion defaultActiveKey="0">
                    {list.map((itm, index) => {
                      return (
                        <Accordion.Item eventKey={itm?._id}>
                          <Accordion.Header>
                            <div
                              className={hasUnRead(itm) ? "unreadOrderID" : ""}
                            >{`Order Id: ${itm?._id}`}</div>
                          </Accordion.Header>
                          <Accordion.Body>
                            {itm?.list.map((item) => {
                              return (
                                <>
                                  <div
                                    className={
                                      item?.isRead
                                        ? "ntfc_body"
                                        : "ntfc_body unread"
                                    }
                                  >
                                    <div>{item?.body}</div>
                                    <div>
                                      <div className="action_btns">
                                        <button
                                          type="button"
                                          className="btn btn-success"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            readNotification(
                                              item?._id,
                                              item?.payload?.id || "",
                                              item?.payload?.type || "",
                                              item?.payload?.warehouseId || "",
                                              item?.payload?.addressId || ""
                                            );
                                          }}
                                          style={{ whiteSpace: "nowrap" }}
                                        >
                                          View Details
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                </>
              )}
            </div>{" "}
          </div>

          <Pagination
            currentPage={currentPage}
            totalCount={totalRecords}
            pageSize={paginationValue}
            onPageChange={(page) => setCurrentPage(page)}
            changePageSize={(page) => {
              setPaginationValue(page);
              setCurrentPage(1);
            }}
          />
        </div>
      </div>
      <ViewBidDetails
        type={type}
        setType={setType}
        addressId={addressId}
        setaddressId={setaddressId}
        warehouseId={warehouseId}
        setwarehouseId={setwarehouseId}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        viewBidDetails={viewBidDetails}
        setViewBidDetails={setViewBidDetails}
        afterRead={afterRead}
      />
    </>
  );
};
