import moment from "moment";
import React from "react";
import { handleCurrency } from "../../../helper/helper";
const CommonTableBody = React.forwardRef(
  ({ value, index, showAction, setShowAction, role, viewDetails }, ref) => {
    const handlePriceByRole = (value) => {
      if (
        role == "BUYER" ||
        role == "EXPORTER" ||
        role == "RETAILER" ||
        role == "FARMLINK" ||
        role == "ADMIN" ||
        role == "SUPERADMIN" ||
        role == "MANUFACTURER"
      ) {
        return value?.price;
      } else return value?.warehouseDetails?.price;
    };
    const handleTotalPriceByRole = (value) => {
      if (
        role == "BUYER" ||
        role == "EXPORTER" ||
        role == "RETAILER" ||
        role == "FARMLINK" ||
        role == "ADMIN" ||
        role == "SUPERADMIN"
      ) {
        return (
          (value?.price ?? 0) *
          (value?.quantity ?? 0) *
          (value?.conversionUnit ?? 0)
        );
      } else {
        return (
          (value?.warehouseDetails?.price ?? 0) *
          (value?.warehouseDetails?.quantity ?? 0) *
          (value?.warehouseDetails?.conversionUnit ?? 0)
        );
      }
    };
    const handleQuantity = (value) => {
      if (role == "WAREHOUSE") {
        if (value?.warehouseDetails?.acceptedQty) {
          return (value?.warehouseDetails?.acceptedQty) * (value?.warehouseDetails?.conversionUnit)
        }
        else {
          return value?.warehouseDetails?.conversionUnit
        }
      } else {
        return value?.conversionUnit
      }

    }
    return (
      <tr key={index}>
        <td>{value.orderId}</td>
        <td>{moment(value.createdAt).format("DD/MM/YYYY")}</td>
        <td>{value?.addressDetails?.location?.address || ""}
          {/* {item?.warehouseAddress ? item?.warehouseAddress?.location?.address : item?.warehouseDefaultAddress[0]?.location?.address} */}
        </td>
        <td>{value?.productDetails?.name?.en || ""}</td>
        <td>{value?.verietyDetails?.name?.en || ""}</td>
        <td>{value?.gradeDetails?.name || ""}</td>
        <td>
          {handleCurrency()} {handlePriceByRole(value)}
        </td>
        <td>
          {role == "WAREHOUSE" ? <>  {value?.warehouseDetails?.acceptedQty ? value?.warehouseDetails?.acceptedQty : value?.warehouseDetails?.quantity} </> : value?.quantity}{" "}
          {value?.unitDetails?.shortcode || ""}
        </td>
        <td>
          {handleCurrency()} {handlePriceByRole(value) * handleQuantity(value)}
          {/* {priceConversion × quantity × conversion} */}
          {/* {handleCurrency()} {handlePriceByRole(value) * value.quantity} */}
        </td>
        <td>{moment(value.deliveryDate).format("DD/MM/YYYY")}</td>
        {role !== "SUPERADMIN" && role !== "ADMIN" && (
          <td>
            <div
              className="action_drop-box"
              ref={ref} // Using the forwarded ref here for dropdown
            >
              <div className="">
                <button
                  className="actionBtn"
                  onClick={() => viewDetails(value._id)}
                >
                  View
                </button>
              </div>
              {/* <div
              className="action_drop-box_button"
              onClick={() => {
                setShowAction(index); // Set the dropdown action for this row
              }}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div
              className={`action_drop-box_dropdown ${
                showAction === index ? "show" : "hide"
              }`}>
           
            </div>*/}
            </div>
          </td>
        )}
      </tr>
    );
  }
);

export default CommonTableBody;
