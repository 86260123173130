import React, { useState, useEffect, useRef } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import leftArrow from "../../assets/images/navigate_left.svg";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";


import {
  handleBuyerTitle,
} from "../../helper/helper";
import { useNavigate, useParams } from "react-router-dom";
import { SearchBox } from "../../components/SearchBox";
import { WarehouseNewRequestList } from "../../components/WarehouseRequestListComponents/WarehouseNewRequestList";
import { WarehouseCompleteList } from "../../components/WarehouseRequestListComponents/WarehouseCompleteList";
import { WarehouseAppliedList } from "../../components/WarehouseRequestListComponents/WarehouseAppliedList";
import { WarehouseComfirmList } from "../../components/WarehouseRequestListComponents/WarehousecomfirmList";

export const WarehouseBidList = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const param = useParams();
  const [status, setStatus] = useState(0);
  useEffect(() => {
    if (param.status == 4) {
      setTabIndex(1);
      handleTabChange(4);
    } else if (param.status == 1) {
      setTabIndex(2);
      handleTabChange(1);
    }
  }, [param]);
  const handleTabChange = (status) => {
    setStatus(status);
  };
  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left p-0">
            <div className="payout_data_ttl">
              {handleBuyerTitle()} Request List
            </div>
          </div>
          <div className="payout_data-right">
            <button
              onClick={() => {
                navigate(-1);
              }}
              className="back_btnn"
            >
              {" "}
              <img
                src={leftArrow}
                alt="Arrow"
                className="navi_left"
              /> Back{" "}
            </button>
          </div>
        </div>
      </div>
      <div className="search_container">
        <SearchBox search={search} setSearch={setSearch} />
      </div>
      <div className="tab_box">
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            <Tab
              onClick={() => {
                handleTabChange(0)
              }}
            >
              New Requests
            </Tab>
            <Tab
              onClick={() => {
                handleTabChange(4);
              }}
            >
              Applied Requests
            </Tab>
            <Tab
              onClick={() => {
                handleTabChange(1);
              }}
            >
              {" "}
              Confirmed Requests
            </Tab>
            <Tab
              onClick={() => {
                handleTabChange(2);
              }}
            >
              Completed Requests
            </Tab>
          </TabList>
          <TabPanel>
            <WarehouseNewRequestList search={search} status={status} setTabIndex={setTabIndex} setStatus={setStatus} />
          </TabPanel>
          <TabPanel>
            <WarehouseAppliedList search={search} status={status} />
          </TabPanel>
          <TabPanel>
            <WarehouseComfirmList search={search} status={status} />
          </TabPanel>
          <TabPanel>
            <WarehouseCompleteList search={search} status={status} />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};
