import React, { useState, useEffect } from "react";
import searchIcon from "../../assets/images/search-lg.svg";
import trashicon from "../../assets/images/trash-03.svg";
import filerIcon from "../../assets/images/Up_Down.svg";
import Pagination from "../../components/pagination/paginationView";
import { TabPanel, Tabs } from "react-tabs";
import EditIcon from "../../assets/images/edit_btn.svg";
import {
  addCategoryData,
  deleteCategoryData,
  editCategory,
  getCategoryData,
  getCategoryDetails,
} from "../../action/category";
import { useParams } from "react-router-dom";

import Offcanvas from "react-bootstrap/Offcanvas";
import { useFormik } from "formik";
import { SingleErrorMessage } from "../singleErrorMessage";
import { toast } from "react-hot-toast";
import { Modal } from "react-bootstrap";
import plusWhite from "../../assets/images/plus-white.svg";
import { SearchBox } from "../../components/SearchBox";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
const ProductList = () => {
  const { name, id } = useParams();

  const [list, setList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [order, setOrder] = useState(1);
  const [parentData, setParentData] = useState([]);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [productId, setProductId] = useState("");
  const [editModel, setEditModel] = useState(false);
  const [show, setShow] = useState(false);

  const getProductList = async (searchData = "") => {
    try {
      setIsLoading(true);
      const response = await getCategoryData(
        pageNo,
        pageSize,
        id,
        searchData,
        order
      );
      if (response.status) {
        setList(response.data.list);
        setTotalData(response.data.count);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch category data", error);
    }
  };
  useEffect(() => {
    getProductList(search);
  }, [pageNo, pageSize, id, search, order]);

  const addCategoryFormik = useFormik({
    initialValues: {
      name: { en: "" },
      pic: "",
      parentId: id,
    },
    onSubmit: async (values) => {
      if (values.parentId == "") {
        toast.custom(
          <SingleErrorMessage
            data={"Category required"}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
        return;
      }
      if (values.name.en == "") {
        toast.custom(
          <SingleErrorMessage
            data={"Please enter product name"}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
        return;
      }
      try {
        const res = await addCategoryData(values);
        if (res.status) {
          getProductList();
          toast.custom(
            <SingleErrorMessage
              data={res.message}
              error={false}
              bColor="#175FC9"
              onClose={onCloseToaste}
            />
          );
        } else {
          getProductList();
          setEditModel(false);
          toast.custom(
            <SingleErrorMessage
              data={res.message ? res.message : res}
              error={true}
              bColor="#D92D20"
              onClose={onCloseToaste}
            />
          );
        }
        setShow(false);
        addCategoryFormik.handleReset();
      } catch (error) {
        console.error("Failed to add category", error);
      }
    },
  });

  const editCategoryFormik = useFormik({
    initialValues: {
      name: { en: "" },
    },
    onSubmit: async (values) => {
      if (values.name.en == "") {
        toast.custom(
          <SingleErrorMessage
            data={"Please enter product name"}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
        return;
      }
      try {
        const res = await editCategory(productId, values);
        if (res.status) {
          toast.custom(
            <SingleErrorMessage
              data={res.message}
              error={false}
              bColor="#175FC9"
              onClose={onCloseToaste}
            />
          );
        } else {
          toast.custom(
            <SingleErrorMessage
              data={res.message ? res.message : res}
              error={true}
              bColor="#D92D20"
              onClose={onCloseToaste}
            />
          );
        }
        getProductList();
        setEditModel(false);
        editCategoryFormik.handleReset();
      } catch (error) {
        console.error("Failed to add category", error);
      }
    },
  });

  const handleShow = () => setShow(true);

  const onCloseToaste = () => {
    toast.dismiss();
  };

  const handleDelete = async () => {
    try {
      const res = await deleteCategoryData(productId);
      if (res.status) {
        toast.custom(
          <SingleErrorMessage
            data={res.message}
            error={false}
            bColor="#175FC9"
          />
        );
        getProductList();
        setShowDeleteModel(false);
      }
    } catch (error) {
      console.error("Failed to delete category", error);
    }
  };

  const fetchProductDetails = async (id) => {
    const res = await getCategoryDetails(id);
    if (res.status) {
      editCategoryFormik.setFieldValue("name.en", res.data?.name?.en);
    }
  };

  useEffect(() => {
    if (id) getProductList();
  }, [pageNo, pageSize, order]);

  const handleReset = () => {
    setSearch("");
    getProductList("");
  };

  // const fetchParentId = async () => {
  //   const res = await getParentId();
  //   if (res.status) {
  //     setParentData(res.data);
  //   }
  // };

  // useEffect(() => {
  //   fetchParentId();
  // }, []);

  return (
    <>
      <div className="payout_inner_content">
        {/* <div className="payout_data-content tablePage_content_header">
          <div className="row">
            <div className="col-lg-3">
              <div className="input-box mt-0">
                <div className="input-label">Search</div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    placeholder="Type any keyword"
                    id="beneficiary"
                    name="search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                  <div className="build_search">
                    <img src={searchIcon} alt="Search Icon" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="submit_btn_grp">
                <button
                  className="btn_submit btn btn-dark"
                  type="button"
                  onClick={() => {
                    handleReset();
                  }}
                  disabled={!search}
                >
                  Reset
                </button>
                <button
                  className={`btn btn-success btn_submit ${search ? "active" : ""
                    }`}
                  type="submit"
                  disabled={!search}
                  onClick={() => {
                    getProductList(search);
                  }}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div> */}
        <div className="payout_data-head">
          <div className="payout_data-head-top">
            <div className="payout_data-left p-0">

              <Breadcrumb
                paths={[
                  { name: "Products", link: "/product/list" },
                  { name: name, link: "" },
                ]}
              />
            </div>
            <button color="info" tag="a" onClick={handleShow} className="btn btn-info addbbtn">
              Create New
              <img src={plusWhite} alt="" className="payout_down" />
            </button>
          </div>
        </div>
        <div className="search_container mb-4">
          <SearchBox search={search} setSearch={setSearch} />
        </div>
        <div className="tab_box">
          <Tabs>
            <TabPanel>
              <div className="">
                <table className="payout_table upi-mandate_table">
                  <thead>
                    <tr className="tableHead">
                      <th className="thHeader">
                        <div className="payoutHead hndIcon">
                          Name
                          <img
                            src={filerIcon}
                            onClick={() => {
                              setOrder(order == 1 ? -1 : 1);
                            }}
                            alt="filter"
                          />
                        </div>
                      </th>

                      <th className="thHeader">
                        <div className="payoutHead hndIcon">Action</div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {list?.map((item) => {
                      return (
                        <tr>
                          <td>{item?.name?.en}</td>
                          <td>
                            {" "}
                            <div className="flex_btn">
                              <img
                                onClick={() => {
                                  fetchProductDetails(item._id);
                                  setProductId(item._id);
                                  setEditModel(true);
                                }}
                                src={EditIcon}
                                alt="Edit"
                                className="edit_icon"
                              />

                              <img
                                src={trashicon}
                                alt="Edit"
                                className="edit_icon "
                                onClick={() => {
                                  setProductId(item._id);
                                  setShowDeleteModel(true);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </TabPanel>
          </Tabs>
          <Pagination
            currentPage={pageNo}
            totalCount={totalData}
            pageSize={pageSize}
            onPageChange={(page) => setPageNo(page)}
            changePageSize={(page) => {
              setPageSize(page);
              setPageNo(1);
            }}
          />
        </div>
        <Offcanvas
          show={show}
          onHide={() => {
            setShow(false);
            addCategoryFormik.handleReset();
          }}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Add Product</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="add_buyer_content">
              <form onSubmit={addCategoryFormik.handleSubmit}>
                <div className="row">
                  <div className="input-box">
                    <div className="input-label">Product Name</div>
                    <div className="input_field">
                      <input
                        type="text"
                        className="input"
                        name={`name.en`}
                        placeholder="Enter product name"
                        value={addCategoryFormik.values.name.en || ""}
                        onChange={addCategoryFormik.handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="addBuyer_submit-btn">
                  <button className="btn btn-success" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
        <Offcanvas
          show={editModel}
          onHide={() => {
            setEditModel(false);
            editCategoryFormik.handleReset();
          }}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Edit Product</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="add_buyer_content">
              <form onSubmit={editCategoryFormik.handleSubmit}>
                <div className="input-box">
                  <div className="input-label">Product Name</div>
                  <div className="input_field">
                    <input
                      type="text"
                      className="input"
                      name={`name.en`}
                      placeholder="Enter product name"
                      value={editCategoryFormik.values.name.en || ""}
                      onChange={editCategoryFormik.handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="addBuyer_submit-btn">
                  <button className="btn btn-success" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
        <Modal
          show={showDeleteModel}
          onHide={() => {
            setShowDeleteModel(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this product?</Modal.Body>
          <Modal.Footer>
            <button
              variant="secondary"
               className="btn btn-dark"
              onClick={() => {
                setShowDeleteModel(false);
              }}
            >
              No
            </button>
            <button
              className="btn btn-success"
              variant="primary"
              onClick={() => {
                handleDelete();
              }}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default ProductList;
