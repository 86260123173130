import { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Pagination from "../pagination/paginationView";
import { fetchAppUsersList, fetchUsersList } from "../../action/userApi";
import filerIcon from "../../assets/images/Up_Down.svg";
import infoBtn from "../../assets/images/info-circle.svg";
import { Loader } from "../../components/loader";

const OtherUsersList = ({
  searchRole,
  searchValue,
  sort,
  setSort,
  setOrder,
  order,
  handleRoleChange,
  roleData,
  setAssignDepartment,
}) => {
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState();
  const [loading, setLoading] = useState(false);

  const getUserData = async () => {
    setLoading(true);
    const response = await fetchUsersList(
      pageNo,
      pageSize,
      searchValue,
      sort,
      order,
      searchRole
    ); // Adjust 10 to the desired number of items per page
    setLoading(false);
    if (response.status) {
      setUserList(response.data.data);
      setTotalData(response.data.count);
    }
  };

  useEffect(() => {
    getUserData();
  }, [pageNo, pageSize, searchValue, sort, order, searchRole]);

  return (
    <div className="table-wrapper">
      <table className="payout_table upi-mandate_table">
        <thead>
          <tr className="tableHead">
            <th className="thHeader">
              <div className="payoutHead hndIcon">
                Name
                <img
                  src={filerIcon}
                  onClick={() => {
                    setSort("name");
                    setOrder(order == 1 ? -1 : 1);
                  }}
                  alt="filter"
                />
              </div>
            </th>
            <th className="thHeader">
              <div className="payoutHead hndIcon">Email</div>
            </th>
            <th className="thHeader">
              <div className="payoutHead hndIcon">Mobile No</div>
            </th>
            {/* <th className="thHeader">
                        <div className="payoutHead hndIcon">Place</div>
                      </th> */}
            <th className="thHeader">
              <div className="payoutHead hndIcon">Role</div>
            </th>
            <th className="thHeader">
              <div className="payoutHead hndIcon">Joining Date</div>
            </th>
            <th className="thHeader">
              <div className="payoutHead hndIcon">Action</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={12} className="text_center">
                <Loader />
              </td>
            </tr>
          ) : (
            <>
              {userList?.length > 0 ? (
                userList?.map((item) => (
                  <tr key={item?._id}>
                    <td>{item?.name}</td>
                    <td>{item?.email || "-"}</td>
                    <td>{item?.mobile}</td>
                    {/* <td>{item?.gaon}</td> */}
                    <td>
                      <select
                        size="sm"
                        ariaLabel="Role"
                        onChange={(e) =>
                          handleRoleChange(item._id, e.target.value)
                        }
                        value={item.role}
                      >
                        <option>{item.role}</option>
                        {roleData
                          .filter((data) => data.name !== item.role)
                          .map((i) => (
                            <option key={i._id} value={i._id}>
                              {i.name}
                            </option>
                          ))}
                      </select>
                    </td>
                    <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center">
                        <img
                          src={infoBtn}
                          className="i_button"
                          onClick={() => {
                            navigate(`/user/profile/${item._id}`);
                          }}
                          alt="info"
                        />
                        {item?.role.toLowerCase() === "user" && (
                          <button
                            className="ms-4 btn btn-primary"
                            onClick={() =>
                              setAssignDepartment((f) => ({
                                ...f,
                                showModel: true,
                                currentUserTypeDetails:
                                  item?.userTypeDetails || [],
                                roles: item?.roles || [],
                                _id: item?._id || "",
                              }))
                            }
                          >
                            Assign Department
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan="12">
                    <div className="text-center">
                      No data available</div>
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
      {userList.length > 0 ? (
        <Pagination
          currentPage={pageNo}
          totalCount={totalData}
          pageSize={pageSize}
          onPageChange={(page) => setPageNo(page)}
          changePageSize={(page) => {
            setPageSize(page);
            setPageNo(1);
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default OtherUsersList;
