import emailIcon from "../assets/images/email_icon.svg";

import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { useState } from "react";
import { login, sendOTP } from "../action/auth";
import defaultLogo from "../assets/images/gaon_logo.svg";
import logo from "../assets/images/gaon_logo.svg";
import logo2 from "../assets/images/frmlinkLogo.png";
import user from "../assets/images/user-regular.svg";
import arrow from "../assets/images/arrow-right-solid.svg";
import contLeft from "../assets/images/login-left2.png";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import showNotification from "../components/notification/showNotification";
import { BaseUrl, Farmlink, Gaon } from "../helper/constants";

export function Login() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [otpScreen, setOtpScreen] = useState(false);
  console.log('BaseUrl Farmlink==>', Farmlink());
  console.log('BaseUrl gaon==>', Gaon());
  console.log("domain Name  Login", window.location.hostname);   // Logs the domain
  const loginForm = useFormik({
    initialValues: {
      mobile: "",
      otp: "",
    },
    onSubmit: async () => {
      setIsLoading(true);
      const response = await login(
        loginForm.values.mobile,
        loginForm.values.otp
      );

      if (response.status === 1) {
        const role = response?.data?.role;
        if (role == "GAON_ADMIN" || role == "FARMER") {
          showNotification(
            "Login",
            "Unauthorized user.Please login to app",
            "danger"
          );
          setOtpScreen(false);
        } else {
          if (role == "USER" && !response?.data?.hasDepartment) {
            showNotification(
              "Login",
              "Unauthorized user/Department yet to be assigned",
              "danger"
            );
            setOtpScreen(false);
            return;
          }
          localStorage.setItem("access-token", response?.data?.token);
          localStorage.setItem("role", response?.data?.role);
          localStorage.setItem("roleType", response?.data?.roleType);
          localStorage.setItem("userId", response?.data?.userId);

          showNotification("Login", "Login Successfully", "success");
          navigate("/");
        }

        // navigate("/");
        setIsLoading(false);
      } else {
        showNotification("Login", "Unauthorized user", "danger");
      }
      setIsLoading(false);
    },
  });
  const handleKeyDown = (e) => {
    if (!isLoading) {
      if (e.key === "Enter") {
        console.log("hit Enter")
        e.preventDefault(); // Prevent default form behavior
        if (otpScreen) {
          loginForm.handleSubmit();
        } else {
          sendOtp();
        }
        // Call submit logic
      }
    }
  };
  const HandleLogo = () => {
    if (Farmlink()) {
      return logo2;
    } else {
      return defaultLogo;
    }
  }

  const HandlecopyRight = () => {
    if (Farmlink()) {
      return "copyright@farmlink2025";
    } else {
      return "copyright@theGaon2025";
    }
  }
  const HandleEmail = () => {
    if (Farmlink()) {
      return "info@latitudegeospatial.com";
    } else {
      return "help@thegaon.co";
    }
  }

  console.log("handle logo", HandleLogo())
  const sendOtp = async () => {
    const payload = {
      mobile: loginForm.values.mobile,
    };
    const response = await sendOTP(payload);
    if (response.status) {
      // toast.success("OTP sent");
      setOtpScreen(true);
      showNotification("Login", "OTP Sent Successfully", "success");
    } else {
      // toast.error(response.message);
      showNotification("Login", "Unauthorized user", "danger");
      // alert("Mobile Not valid");
    }
  };

  return (
    <div className="login_page">
      <div className="login_content">
        <div className="login_left">
          <div className="login_left-content">
            {/* <div className="slider-box">
              <img
                src={logImg}
                alt=""
              />
            </div> */}
            <div className="login_logo-box">
              <div className="sidelogo">
                <img src={HandleLogo()} alt="logo" className="login_logo" />
              </div>
            </div>
            <img src={contLeft} alt="" className="leftCont-img" />
          </div>
        </div>
        <div className="login_right">
          <div className="login_right-content">
            <form onSubmit={loginForm.handleSubmit}>
              <div className="login_inner-content">
                <div className="login">Welcome Back, Login!</div>
                {/* <div className="login_desc">
                    Your payouts are just a click away!
                  </div> */}
                {!otpScreen ? (
                  <div className="input-box">
                    <div className="input-label label_text">Mobile</div>
                    <div className="input_field">
                      <input
                        type="text"
                        className="input input_text"
                        id="mobile"
                        placeholder="Enter your registered mobile"
                        value={loginForm.values.mobile}
                        onChange={loginForm.handleChange}
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                    {loginForm.touched.email && loginForm.errors.email && (
                      <div className="danger">{loginForm.errors.email}</div>
                    )}
                  </div>
                ) : (
                  <div className="input-box">
                    <div className="input-label label_text">
                      Enter OTP<span>*</span>
                    </div>
                    <div className="input_field">
                      <input
                        type="text"
                        className="input input_text"
                        placeholder="Enter your OTP"
                        value={loginForm.values.otp}
                        onChange={loginForm.handleChange}
                        id="otp"
                        maxLength={4}
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                    {loginForm.touched.otp && loginForm.errors.otp && (
                      <div className="danger">{loginForm.errors.otp}</div>
                    )}
                  </div>
                )}
                <div className="mt-4">
                  {/* <button
                      className="btn w-100 primary"
                      style={{
                        border: "1px solid grey",
                        borderRadius: "0.8rem",
                        padding: "0.5rem",
                        position: "relative",
                        zIndex: "1",
                      }}
                      disabled={
                        !loginForm.isValid || !loginForm.dirty || isLoading
                      }
                      type="submit"> */}
                  {otpScreen ? (
                    <button
                      className="submit-btn1 txt_20"
                      disabled={loginForm.values.otp.length !== 4}
                      type="submit"
                    >
                      Submit
                      <span>
                        {isLoading ? (
                          <div className="loader_box">
                            <CircularProgress
                              size={50}
                              style={{
                                color: "#e5dada",
                                // position: "absolute",
                                // top: "",
                                // left: "50%",
                              }}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </span>
                    </button>
                  ) : (
                    <button
                      className="submit-btn1 txt_20"
                      onClick={sendOtp}
                      type="button"
                      disabled={
                        loginForm.values.mobile.length < 7 ||
                        loginForm.values.mobile.length > 10
                      }
                    >
                      Send OTP{" "}
                    </button>
                  )}
                </div>
                <div className="sign_up-boxL mb-4 mt-4">
                  <div className="sign_up-line">
                    {/* Don’t have an account? <Link to="/signup">Sign up</Link> */}
                    Don’t have an account Yet?
                    <span className="sign_span">
                      {" "}
                      mail to{" "}
                      {/* <Link className="help_link">help@thegaon.co</Link> */}
                      <Link className="help_link">{HandleEmail()}</Link>
                    </span>
                  </div>
                </div>
                <div className="text-center">
                  <Link className="login_buyer" to="/buyer/registration">
                    <img src={user} alt="user" />
                    Become A Buyer
                    <img src={arrow} alt="user" />
                  </Link>
                </div>
              </div>
            </form>

            <div className="login_right-footer">
              <div className="login_footer-left">{HandlecopyRight()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
