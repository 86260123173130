// export const gaonTextBasedOnRoleTYpe = () => {
//     const roleType = localStorage.getItem("roleType")
//     if (roleType === "FARMLINK") {
//         return "Village"
//     } else {
//         return "Gaon"
//     }
// }
export const gaonTextBasedOnRoleTYpe = () => {
    const roleType = localStorage.getItem("roleType")
    if (roleType === "FARMLINK") {
        return "Village"
    } else {
        return "Gaon"
    }
}
export const Farmlink = () => {
    const domainName = window.location.hostname;
    console.log("domain Name", window.location.hostname);   // Logs the domain

    if (domainName == "farmlink.latitudegeospatial.com") {
        return true
    }
    else if (domainName == "console.farmlink.techind.co") {
        return true
    }
    else if (domainName == "console.farmlink.thegaon.in") {
        return true
    }
    else {
        return false
    }

}
export const Gaon = () => {
    const domainName = window.location.hostname;
    console.log("domain Name", window.location.hostname);   // Logs the domain

    if (domainName == "console.thegaon.in") {
        return true
    }
    else if (domainName == "gconsole.techind.co") {
        return true
    }
    else {
        return false
    }

}