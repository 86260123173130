import { useState, useEffect } from "react";
import Select from "react-select";
import { fetchAddresses } from "../../action/location";

export const DeliveryAddress = ({ onAddressSelect, defaultAddress }) => {
  const [buyerAddresses, setBuyerAddresses] = useState([]);

  const fetchBuyerAddress = async () => {
    const response = await fetchAddresses();
    if (response.status) {
      const arr = response?.list?.map((item) => {
        return {
          value: item._id,
          label: item?.location ? item?.location?.address : item?.address,
          coordinates: item.coordinates,
        };
      });
      setBuyerAddresses(arr);
    }
  };

  useEffect(() => {
    fetchBuyerAddress();
  }, []);

  return (
    <div>
      <Select
        name="address"
        options={buyerAddresses}
        value={buyerAddresses.find((item) => item.value == defaultAddress)}
        onChange={(selectedOptions) => {
          const id = Array.isArray(selectedOptions)
            ? selectedOptions.map((option) => option.value)
            : selectedOptions
              ? [selectedOptions.value]
              : [];
          const coordinates = Array.isArray(selectedOptions)
            ? selectedOptions.map((option) => option.coordinates)
            : selectedOptions
              ? selectedOptions.coordinates
              : [];

          onAddressSelect({ id: id[0], coordinates });
        }}
        className="basic-multi-select"
        classNamePrefix="Enter "
        placeholder="Enter address for delivery"
        required
      />
    </div>
  );
};
