import React, { useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { EditDailyEvent } from "../../action/contest";
import showNotification from "../../components/notification/showNotification";

const EditEvent = ({ editModel, setEditModel, selectedEvent, fetchEventsForDate, selectedDate }) => {
    const editFormik = useFormik({
        initialValues: {
            Objective: selectedEvent?.objective || "",
            objective_img_prompt: selectedEvent?.objective_img_prompt || "",
            date: selectedEvent?.date ? new Date(selectedEvent.date) : new Date(),
        },
        enableReinitialize: true, 
        onSubmit: async (values, { resetForm }) => {
            const payload = {
                objective: values.Objective,
                objective_img_prompt: values.objective_img_prompt,
                date: moment(values.date).format("YYYY-MM-DD"),
            };

            const response = await EditDailyEvent(selectedEvent._id, payload);

            if (response?.status === 1) {
                showNotification("Daily Post", response.message, "success");
                fetchEventsForDate(selectedDate);
                setEditModel(false);
                resetForm();
            }
        },
    });

    return (
        <Offcanvas show={editModel} onHide={() => setEditModel(false)} placement="end">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Edit Event</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
            <div className="add_buyer_content">
                <form onSubmit={editFormik.handleSubmit}>
                    <div className="input-box">
                        <div className="input-label">Objective</div>
                        <div className="input_field"> 
                        <input
                            type="text"
                            className="input"
                            name="Objective"
                            value={editFormik.values.Objective}
                            onChange={editFormik.handleChange}
                            required
                        />
                        </div>
                    </div>

                    <div className="input-box">
                        <div className="input-label">Objective Image Prompt</div>
                        <div className="input_field">
                        <input
                            type="text"
                            className="input"
                            name="objective_img_prompt"
                            value={editFormik.values.objective_img_prompt}
                            onChange={editFormik.handleChange}
                            required
                        />
                        </div>
                    </div>

                    <div className="input-box">
                        <div className="input-label">Selected Date</div>
                        <div className="date-picker-comp ">
                        <DatePicker
                            selected={editFormik.values.date}
                            onChange={(date) => editFormik.setFieldValue("date", date)}
                            dateFormat="yyyy-MM-dd"
                            className="date-picker"
                            showYearDropdown
                            showMonthDropdown
                            scrollableYearDropdown
                            onKeyDown={(e) => e.preventDefault()}
                        />
                        </div>
                    </div>
                    <div className="addBuyer_submit-btn">
                                <button className="btn btn-success" type="submit">
                                    Save
                                </button>
                    </div>
                </form>
            </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default EditEvent;
