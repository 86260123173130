import { requestWithToken } from "../helper/helper";
import { LOGIN_SUCCESS } from "./types";

const fetchUsersList = async (
  currentPage,
  perPage,
  search,
  sort,
  order,
  roleId
) => {
  try {
    const queryParams = [];

    if (currentPage) queryParams.push(`pageNo=${currentPage}`);
    if (perPage) queryParams.push(`pageSize=${perPage}`);
    if (search) queryParams.push(`search=${search}`);
    if (sort) queryParams.push(`sort=${sort}`);
    if (order) queryParams.push(`order=${order}`);
    if (roleId) queryParams.push(`role=${roleId}`);

    // Join the query parameters
    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const response = await requestWithToken(
      "GET",
      `/admin/userList${queryString}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const fetchAppUsersList = async (
  currentPage,
  perPage,
  search,
  sort,
  order,
  roleId
) => {
  try {
    const queryParams = [];

    if (currentPage) queryParams.push(`pageNo=${currentPage}`);
    if (perPage) queryParams.push(`pageSize=${perPage}`);
    if (search) queryParams.push(`search=${search}`);
    if (sort) queryParams.push(`sort=${sort}`);
    if (order) queryParams.push(`order=${order}`);
    if (roleId) queryParams.push(`role=${roleId}`);

    // Join the query parameters
    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const response = await requestWithToken(
      "GET",
      `/admin/userList/app-users${queryString}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
//User Control Panel API
const fetchUserTypeList = async (
  currentPage,
  perPage,
  search,
  sort,
  order,
  roleId
) => {
  try {
    const queryParams = [];

    if (currentPage) queryParams.push(`pageNo=${currentPage}`);
    if (perPage) queryParams.push(`pageSize=${perPage}`);
    if (search) queryParams.push(`search=${search}`);
    if (sort) queryParams.push(`sort=${sort}`);
    if (order) queryParams.push(`order=${order}`);
    if (roleId) queryParams.push(`role=${roleId}`);

    // Join the query parameters
    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const response = await requestWithToken(
      "GET",
      `/admin/userType${queryString}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const deleteUserType = async (roleId) => {
  try {
    const queryParams = [];

    const response = await requestWithToken(
      "DELETE",
      `/admin/userType/${roleId}`
    );
    return response;
  } catch (err) {
    return err.response.data;
  }
};
const editUserTypeList = async (roleId, data) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/admin/userType/${roleId}`,
      data
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const getUserTypeDetails = async (roleId) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/userTypeDetails/${roleId}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const createUserType = async (data) => {
  try {
    const response = await requestWithToken("POST", `/admin/userType/`, data);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const fetchUserDepartmentListByRoleId = async (id) => {
  try {
    const response = await requestWithToken("GET", `/user/role/userType/${id}`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const assignDepartmentToUser = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/admin/assignUserTypeToRole/${id}`,
      payload
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchAdmin = async (id) => {
  try {
    const response = await requestWithToken("GET", `/admin/roles`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchFarmlinkRoles = async () => {
  try {
    const response = await requestWithToken("GET", `/admin/farmlink/roles`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const editAdmin = async (data) => {
  try {
    const response = await requestWithToken("PUT", `/admin/userRole`, data);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const createUser = async (data) => {
  try {
    const response = await requestWithToken("POST", `/admin/create/user`, data);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const createBuyerUser = (data) => async (dispatch) => {
  try {
    const response = await requestWithToken("POST", `/user/buyer/signup`, data);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: response?.data?.data?.token,
    });
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getUserDetails = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/user/profile/details/${id}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const fetchRevolutionList = async (pageNo, pageSize) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/user/webForm/list?pageNo=${pageNo}&pageSize=${pageSize}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const editUserDetails = async (data) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/user/profile/details`,
      data
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const deleteAddress = async (data) => {
  try {
    const response = await requestWithToken("DELETE", `/address`, data);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const notregistereduser = async (pageNo, pageSize, search) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/user/notRegistred?pageNo=${pageNo}&pageSize=${pageSize}&search=${search}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  notregistereduser,
  fetchAdmin,
  editAdmin,
  fetchUsersList,
  createUser,
  getUserDetails,
  editUserDetails,
  deleteAddress,
  fetchUserTypeList,
  deleteUserType,
  editUserTypeList,
  createUserType,
  getUserTypeDetails,
  createBuyerUser,
  fetchFarmlinkRoles,
  fetchUserDepartmentListByRoleId,
  assignDepartmentToUser,
  fetchAppUsersList,
  fetchRevolutionList,
};
