import { useEffect, useState } from "react";
import { Loader } from "../loader";
import moment from "moment";
import infoBtn from "../../assets/images/info-circle.svg";
import { useNavigate } from "react-router-dom";
import { handleCurrency } from "../../helper/helper";
import { fetchBuyerConfirmRequestList } from "../../action/buyer";
import Pagination from "../../components/pagination/paginationView";

export const BuyerConfirmedRequest = ({ search, setSearch }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [reuqstConfirmList, setReuqstConfirmList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [status, setStatus] = useState(0);

  const fetchBuyerConfrimList = async (searchValue = "") => {
    setIsLoading(true);
    const response = await fetchBuyerConfirmRequestList(
      currentPage,
      paginationValue,
      status,
      searchValue
    );
    if (response.status) {
      setReuqstConfirmList(response.data.list);
      setTotalRecords(response.data.count);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchBuyerConfrimList(search);
  }, [currentPage, paginationValue, search, status]);
  return (
    <>
      <div className="overflow_auto">
        <table className="payout_table upi-mandate_table">
          <tr className="tableHead">
            <th className="thHeader">
              <div className="payoutHead hndIcon">Order ID</div>
            </th>
            <th className="thHeader">
              <div className="payoutHead hndIcon">Order Date</div>
            </th>
            <th className="thHeader">
              <div className="payoutHead hndIcon">Address</div>
            </th>
            <th className="thHeader">
              <div className="payoutHead">Product</div>
            </th>
            <th className="thHeader">
              <div className="payoutHead">Variety</div>
            </th>
            <th className="thHeader">
              <div className="payoutHead">Grade</div>
            </th>
            <th className="thHeader">
              <div className="payoutHead">MSP </div>
            </th>
            <th className="thHeader">
              <div className="payoutHead">Price </div>
            </th>
            <th className="thHeader">
              <div className="payoutHead">Quantity </div>
            </th>
            <th className="thHeader">
              <div className="payoutHead">Total Price </div>
            </th>
            <th className="thHeader">
              <div className="payoutHead">Delivery Date</div>
            </th>
            <th className="thHeader">
              <div className="payoutHead headerAction">Action </div>
            </th>
          </tr>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="12">
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {reuqstConfirmList.length > 0 ? (
                  reuqstConfirmList.map((item) => {
                    return (
                      <tr>
                        <td className="noWrap">{item?.orderId}</td>
                        <td className="phone_number noWrap">
                          {moment(item?.createdAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="Status address_td">
                          <div>{item?.location}</div>
                        </td>
                        <td className="table_amount ">{item?.product}</td>
                        <td className="table_amount noWrap">
                          {item?.veriety ?? "-"}
                        </td>
                        <td className="table_amount">{item?.grade}</td>
                        <td className="Status">
                          <div>
                            {item?.mspDetails?.minimum_selling_price ||
                              ""}
                            / {item?.mspDetails?.unit?.shortcode || ""}
                          </div>
                        </td>
                        <td className="Status bold_txt">
                          <div className="noWrap">
                            {handleCurrency()} {item?.price}
                          </div>
                        </td>
                        <td className="Status noWrap bold_txt">
                          <div>
                            {item?.quantity} {item?.unit}
                          </div>
                        </td>
                        <td nowrap className="Status noWrap bold_txt">
                          {handleCurrency()}{" "}
                          {(item?.price ?? 0) *
                            (item?.quantity ?? 0) *
                            (item?.conversion ?? 0)}
                        </td>
                        <td>
                          {" "}
                          <div className="noWrap">
                            {moment(item?.deliveryDate).format(
                              "DD-MM-YYYY"
                            )}
                          </div>
                        </td>
                        <td>
                          <div className="action_payment addBuyer_submit-btn pd_0">
                            <img
                              src={infoBtn}
                              onClick={() => {
                                navigate(
                                  // `/buyerRequest/warehouseBid/${item?._id}`
                                  `/confirmWarehouseRequests/${item?._id}`
                                );
                              }}
                              alt="infoIcon"
                            />
                            {/* <button
                                  color="dark"
                                  isLight="btn btn-primary"
                                  tag="a"
                                  onClick={() => {
                                    setId(item._id);
                                    setIsOpenModal(true);
                                  }}
                                >
                                  Revise Request
                                </button>
                                {"  "}
                                <button
                                  color="dark"
                                  isLight="btn btn-primary"
                                  tag="a"
                                  onClick={() => {
                                    setId(item._id);
                                    setWarehouseId(item?.warehouseDetails?._id);
                                    setIsUpdateOpenModal(true);
                                  }}
                                >
                                  Complete Request
                                </button> */}
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td colspan="11" className="text_center">
                        No data available
                      </td>
                    </tr>
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      {reuqstConfirmList?.length > 0 ? (
        <Pagination
          currentPage={currentPage}
          totalCount={totalRecords}
          pageSize={paginationValue}
          onPageChange={(page) => setCurrentPage(page)}
          changePageSize={(page) => {
            setPaginationValue(page);
            setCurrentPage(1);
          }}
        />
      ) : (
        <></>
      )
      }
    </>
  );
}