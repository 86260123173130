import React, { useRef, useState, useEffect } from "react";
import { AreaChart } from "../AreaChart";
import infoBtn from "../../../assets/images/info-circle.svg";
export const TopCard = ({ title, icon, total, data, color, Ymax, text }) => {
  const [showtext, setShowtext] = useState(false)
  const boxRef = useRef(null);
  const handleClickOutside = (event) => {
    // Check if the clicked target is not inside the box
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setShowtext(false); // Hide the div
    }
  };

  useEffect(() => {
    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="col-lg-3">
        <div className="card1">
          <div className="card_area_head">
            <div className="card_area_head_left">
              <div className="card_area_haed-icon" style={{ backgroundColor: color }}>
                <img
                  src={icon}
                  alt="icon"
                />
              </div>
              <h2 className="graph_card_title">{title}</h2>
            </div>
            <div className="card_area_head_right"
            >
              <div className="card_infoIcon" onClick={() => {
                setShowtext(!showtext)
              }}>
                <img src={infoBtn} alt="info icon" />
                <div className={`cardOutDiv ${showtext ? "active" : ""}`}
                  ref={boxRef}
                >
                  <div className="cardOutDiv_content">
                    <div className="cardOutDiv-text">{text}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card_area_graph">
            <AreaChart total={total} data={data} color={color} Ymax={Ymax} />
          </div>


        </div>
      </div>
    </>
  );
};
