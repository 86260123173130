import React, { useEffect, useState } from "react";
import { fetchReject, InprogressRequests } from "../../action/farmerProducts";
import { Loader } from "../loader";
import moment from "moment";
import { handleCurrency } from "../../helper/helper";
import Pagination from "../pagination/paginationView";
import { Modal } from "react-bootstrap";
import { SingleErrorMessage } from "../../pages/singleErrorMessage";
import toast from "react-hot-toast";
import showNotification from "../notification/showNotification";

function AppliedReq({ handleRequestFunc, search }) {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(10);
  const [ongoingList, setOngoingList] = useState([]);
  const [totalRecords1, setTotalRecords1] = useState(0);
  const [showrejectModel, setShowrejectModel] = useState(false)
  const [title, setTitle] = useState("")
  const [rejectStatus, setRejectStatus] = useState(0)
  const [rejectId, setRejectId] = useState(0)
  const fetchWarehouseInprogressList = async (
    statusValue = 1,
    searchValue = ""
  ) => {
    setIsLoading(true);
    const response = await InprogressRequests(
      currentPage,
      paginationValue,
      statusValue,
      searchValue
    );
    if (response.status) {
      setOngoingList(response?.data?.list);
      setTotalRecords1(response?.data?.count);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchWarehouseInprogressList(4, search);
  }, [currentPage, paginationValue, search]);

  const getMSP = (price, unit) => {
    if (price) {
      return `${price} / ${unit}`;
    }
  };
  const handleReject = async () => {
    const payload = {
      status: rejectStatus,
    };
    console.log("called1", rejectId);
    const response = await fetchReject(rejectId, rejectStatus, payload);
    if (response.status) {
      setShowrejectModel(false);
      fetchWarehouseInprogressList(4);
    } else {
      showNotification(
        "Apply Request",
        response.message,
        "danger",
      )
    }
  }

  const onCloseToaste = () => {
    toast.dismiss();
  };

  return (
    <>
      <div className="overflow_auto">
        <table className="payout_table upi-mandate_table">
          <thead>
            <tr className="tableHead">
              <th className="thHeader">
                <div className="payoutHead hndIcon">Order ID</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead hndIcon">Request Date</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead hndIcon">Farmer Name</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead"> Address</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead">Product</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead">Variety</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead">Grade</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead">MSP</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead">Price </div>
              </th>
              <th className="thHeader">
                <div className="payoutHead">Quantity </div>
              </th>
              <th className="thHeader">
                <div className="payoutHead">Total Price </div>
              </th>
              <th className="thHeader">
                <div className="payoutHead">Reason </div>
              </th>
              <th className="thHeader">
                <div className="payoutHead">Action </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="15">
                  <Loader />
                </td>
              </tr>
            ) : ongoingList.length > 0 ? (
              ongoingList.map((item) => {
                return (
                  <tr key={item._id}>
                    <td className="phone_number noWrap">
                      {item?.farmerDetails?.orderId ?? "-"}
                    </td>
                    <td className="phone_number noWrap">
                      {moment(item?.createdAt).format("DD-MM-YYYY")}
                    </td>
                    <td className="Status">
                      {item?.farmerDetails?.userDetails?.name}
                    </td>
                    <td className="Status">
                      {item?.farmerDetails?.gaonDetails?.name}
                    </td>
                    <td className="table_amount">
                      {
                        item?.product[0]?.verietyDetails[0]?.productDetails?.name
                          .en
                      }
                    </td>

                    <td className="table_amount ">
                      {item?.product[0]?.verietyDetails[0]?.name.en}
                    </td>

                    <td className="">{item?.grade}</td>
                    <td>
                      {getMSP(
                        item?.product[0]?.verietyDetails[0]?.mspDetails
                          ?.minimum_selling_price,
                        item?.product[0]?.verietyDetails[0]?.mspDetails?.unit
                          ?.shortcode
                      )}
                    </td>
                    <td className="Status bold_txt">
                      <div>
                        {handleCurrency()} {item?.price ?? 0} / {item?.farmerDetails?.perUnit}
                      </div>
                    </td>
                    <td className="Status bold_txt">
                      <div>
                        {item?.quantity}
                        {item?.unit ? item?.unit : ""}
                      </div>
                    </td>
                    <td className="bold_txt">
                      <div>
                        {/* {handleCurrency()}{" "}
                        {(item?.price ?? 0) *
                          (item?.quantity ?? 0) *
                          (item?.conversion ?? 0)} */}
                        {handleCurrency()}{" "}
                        {(
                          Math.floor(
                            (item?.farmerDetails?.priceConversion ?? 0) *
                            (item?.quantity ?? 0) *
                            (item?.conversion ?? 0)
                          )
                        ).toFixed(2)}

                      </div>
                    </td>
                    <td>
                      {" "}
                      <div>{item?.reasonDetails?.title}</div>
                    </td>
                    <td>
                      {item?.status == 8 ? (
                        <div className="comform_brn-box">
                          <div
                            class="btn btn-light-dark noWrap"
                            role="button"
                            onClick={() => {
                              setShowrejectModel(true);
                              setRejectStatus(3);
                              setRejectId(item._id);
                              setTitle("Reject");

                            }}
                          >
                            Reject
                          </div>
                          <button
                            className="btn btn-success noWrap"
                            variant="primary"
                            onClick={() => {
                              setShowrejectModel(true);
                              setRejectStatus(9);
                              setRejectId(item._id);
                              setTitle("Accept");
                            }}
                          >
                            Accept
                          </button>
                        </div>
                      ) : (
                        <div>Requested</div>
                      )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <>
                <tr>
                  <td colSpan="15" className="text_center">
                    No data available
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalCount={totalRecords1}
        pageSize={paginationValue}
        onPageChange={(page) => setCurrentPage(page)}
        changePageSize={(size) => setPaginationValue(size)}
        showPageSizeView={totalRecords1 > 5 ? true : false}
      />
      <Modal
        show={showrejectModel}
        onHide={() => {
          setShowrejectModel(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure want to {title} this request?</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-dark"
            variant="secondary"
            onClick={() => {
              setShowrejectModel(false);
            }}
          >
            No
          </button>
          <button
            className="btn btn-success"
            variant="primary"
            onClick={handleReject}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default AppliedReq;
