import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import { Login } from "../pages/login";
import { ProtectedRoute } from "../routes/protectedRoutes";
import { PageNotFound } from "../pages/404Page";
import { BuyerRequestList } from "../pages/Buyer/buyerRequestList";
import { WarehouseBidToBuyerList } from "../pages/Buyer/warehouseBidList";
import { WarehouseBidList } from "../pages/warehouse/bidWarehouseList";
import { RequestToFarmerList } from "../pages/warehouse/requestToFarmer";
import { FarmerBidList } from "../pages/warehouse/bidFarmerList";
import { AddBuyerRequest } from "../pages/Buyer/addBuyerRequest";
import Gaon from "../pages/gaon/Gaon";
import ShowGaon from "../pages/gaon/ShowGaon";
import UserList from "../pages/users/UserList";
import UserControl from "../pages/users/UserControl"; /// User Control
import CategoryListPage from "../pages/category/CategoryListPage";
import EditeGaon from "../pages/gaon/EditeGaon";
import { Dashboard } from "../pages/Dashboard/dashboard";
import { DetailsPage } from "../pages/warehouse/farmerBidDetails";
import ModuleList from "../pages/module/moduleList";
import ModuleAdd from "../pages/module/moduleAdd";
import ModuleEdit from "../pages/module/moduleEdit";
import ContestList from "../pages/module/contest/contestList";
import ContestAdd from "../pages/module/contest/contestAdd";
import ContestEdit from "../pages/module/contest/contestEdit";
import GetUnits from "../pages/unitLIst/units";
import MinimumSellingPrice from "../pages/minimumSellingPrice/minimumSellingPrice";
import { BuyerConfirmRequestList } from "../pages/Buyer/confirmWarehouseRequests";
import GradeList from "../pages/Grade/gradeList";
import { AddReviceBuyerRequest } from "../pages/Buyer/reviceRequest";
import { BuyerInprogressRequestList } from "../pages/Buyer/inprogressRequestsList";
import { BuyerClosedequestList } from "../pages/Buyer/completeWarehouseRequests";
import UserProfile from "../pages/users/profile";
import ProductList from "../pages/category/productList";
import CreateUser from "../pages/users/createUser";
import { Notiifcations } from "../pages/Notifications/notification";
import { FarmerProducts } from "../pages/warehouse/farmerProducts";
import { FarmerProductDetailsPage } from "../pages/warehouse/farmerProductDetails";
import Bannerlist from "../pages/banner/bannerlist";
import ComplaintList from "../pages/complaints/list";
import BuyerRegistration from "../pages/becomeABuyer/buyer-registration";
import ComplaintHistoryList from "../pages/complaints/history";
import WithdrowlList from "../pages/module/withdrawl/withdrowlList";
import Revolution from "../pages/Revolution";
import { UserNotification } from "../pages/Notifications/UserNotification";
import { News } from "../pages/News/News";
import { CalendarEvent } from "../pages/CalendarEvents/CalendarEvent";
const role = localStorage.getItem("role");

const route = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="login" element={<Login />} />
      <Route path="/buyer/registration" element={<BuyerRegistration />} />

      {/* protected routes  */}
      <Route path="/" element={<ProtectedRoute Component={<Dashboard />} />} />
      <Route path="/gaon" element={<ProtectedRoute Component={<Gaon />} />} />
      <Route
        path="/gaon/edit/:id"
        element={<ProtectedRoute Component={<EditeGaon />} />}
      />
      <Route
        path="/gaon/details/:id"
        element={<ProtectedRoute Component={<ShowGaon />} />}
      />
      <Route
        path="/userList"
        element={<ProtectedRoute Component={<UserList />} />}
      />
      <Route
        path="/user_department"
        element={<ProtectedRoute Component={<UserControl />} />}
      />

      <Route
        path="/unitList"
        element={<ProtectedRoute Component={<GetUnits />} />}
      />
      <Route
        path="/product/list"
        element={<ProtectedRoute Component={<CategoryListPage />} />}
      />

      <Route
        path="/buyerReqest/list"
        element={<ProtectedRoute Component={<BuyerRequestList />} />}
      />
      <Route
        path="/bidWarehouse/:status?"
        element={<ProtectedRoute Component={<WarehouseBidList />} />}
      />
      <Route
        path="/warehouseRequest/list"
        element={<ProtectedRoute Component={<RequestToFarmerList />} />}
      />
      <Route
        path="/withdrawl"
        element={<ProtectedRoute Component={<WithdrowlList />} />}
      />
      <Route
        path="/rural-revolution"
        element={<ProtectedRoute Component={<Revolution />} />}
      />
      <Route
        path="/farmerBid/:id"
        element={<ProtectedRoute Component={<FarmerBidList />} />}
      />
      <Route
        path="/buyerRequest/bid/list/:id"
        element={<ProtectedRoute Component={<WarehouseBidToBuyerList />} />}
      />
      <Route
        path="/buyerRequest/add"
        element={<ProtectedRoute Component={<AddBuyerRequest />} />}
      />
      <Route
        path="/farmerBid/details/:id"
        element={<ProtectedRoute Component={<DetailsPage />} />}
      />

      <Route
        path="/module/list"
        element={<ProtectedRoute Component={<ModuleList />} />}
      />
      <Route
        path="/module/add"
        element={<ProtectedRoute Component={<ModuleAdd />} />}
      />
      <Route
        path="/module/edit/:id"
        element={<ProtectedRoute Component={<ModuleEdit />} />}
      />

      <Route
        path="/contest/list/:moduleId"
        element={<ProtectedRoute Component={<ContestList />} />}
      />
      <Route
        path="/contest/add/:moduleId"
        element={<ProtectedRoute Component={<ContestAdd />} />}
      />
      <Route
        path="/contest/edit/:id"
        element={<ProtectedRoute Component={<ContestEdit />} />}
      />
      <Route
        path="/msp/list"
        element={<ProtectedRoute Component={<MinimumSellingPrice />} />}
      />
      <Route
        path="confirmWarehouseRequests/:id"
        element={<ProtectedRoute Component={<BuyerConfirmRequestList />} />}
      />
      <Route
        path="/grade/list"
        element={<ProtectedRoute Component={<GradeList />} />}
      />
      <Route
        path="/buyerRequest/revice/:id"
        element={<ProtectedRoute Component={<AddReviceBuyerRequest />} />}
      />
      <Route
        path="/buyerRequest/inprogress/:id"
        element={<ProtectedRoute Component={<BuyerInprogressRequestList />} />}
      />
      <Route
        path="/buyerRequest/closed/:id"
        element={<ProtectedRoute Component={<BuyerClosedequestList />} />}
      />
      <Route
        path="/user/profile/:id"
        element={<ProtectedRoute Component={<UserProfile />} />}
      />
      <Route
        path="/products/:name/:id"
        element={<ProtectedRoute Component={<ProductList />} />}
      />
      <Route
        path="/user/add"
        element={<ProtectedRoute Component={<CreateUser />} />}
      />
      <Route
        path="/notification/list"
        element={<ProtectedRoute Component={<Notiifcations />} />}
      />
      <Route
        path="/user-notification"
        element={<ProtectedRoute Component={<UserNotification />} />}
      />
      <Route
        path="/news"
        element={<ProtectedRoute Component={<News />} />}
      />
      < Route
        path="/calendar-event"
        element={<ProtectedRoute Component={<CalendarEvent />} />}
      />
      <Route
        path="/banner/list"
        element={<ProtectedRoute Component={<Bannerlist />} />}
      />
      <Route
        path="/farmer/products"
        element={<ProtectedRoute Component={<FarmerProducts />} />}
      />
      <Route
        path="/farmer/products/details/:id"
        element={<ProtectedRoute Component={<FarmerProductDetailsPage />} />}
      />
      <Route
        path="/complaints/:status"
        element={<ProtectedRoute Component={<ComplaintList />} />}
      />
      <Route
        path="/complaint/history/:parentId"
        element={<ProtectedRoute Component={<ComplaintHistoryList />} />}
      />
      <Route
        path="/"
        element={<ProtectedRoute Component={<PageNotFound />} />}
      />
      <Route path="*" element={<PageNotFound />} />
    </Route>
  )
);

export default route;
