import React, { useState, useEffect, useRef } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import moment from "moment";
import {
    createWarehouseRequest,
    fetchrequestUpdated,
    fetchWarehouseBids,
} from "../../action/warehouse";
import { toast } from "react-hot-toast";
import { getProducts, getunits } from "../../action/users";
import {
    handleBuyerTitle,
    handleCurrency,
    handleKeyDown,
} from "../../helper/helper";
import { Loader } from "../loader";
import Pagination from "../pagination/paginationView";
import { useNavigate, useParams } from "react-router-dom";
import { SingleErrorMessage } from "../../pages/singleErrorMessage";
import { useFormik } from "formik";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import showNotification from "../notification/showNotification";
export const WarehouseComfirmList = ({ search, status }) => {
    const navigate = useNavigate();
    const [id, setId] = useState("");
    const [bidList, setBidList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [paginationValue, setPaginationValue] = useState(10);
    const [units, setUnits] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [value, setValue] = useState(0);
    const [buyerReqId, setBuyerReqId] = useState("");


    const handleClose = () => {
        setShow(false);
        requestToFarmerFormik.handleReset();
    };
    const handleRangeChange = (e) => {
        setValue(e.target.value);
    };
    const handleShow = () => setShow(true);

    const requestToFarmerFormik = useFormik({
        initialValues: {
            quantity: "",
            unitId: "",
            price: "",
            deliveryDate: "",
            buyerReqId: "",
            distance: "",
        },
        onSubmit: async (values) => {
            values.deliveryDate = moment(values.deliveryDate).format("YYYY-MM-DD");
            values.buyerReqId = buyerReqId;
            values.distance = Number(value);

            const response = await createWarehouseRequest(values);
            if (response.status) {
  
                showNotification(
                    "Confirm Request",
                    response.message,
                    "success"
                )
                fetchWarehouseBidList();
                setValue(0);
            } else {
  
                showNotification(
                    "Confirm Request",
                    response.message,
                    "danger"
                )
            }
            handleClose();
            requestToFarmerFormik.handleReset();
        },
    });
    const requestUpdated = async (id) => {
        const response = await fetchrequestUpdated(id);
        if (response.status) {

            showNotification(
                "Update Bid Request",
                response.message,
                "success"
            )
            fetchWarehouseBidList();
        } else {

            showNotification(
                "Update Bid Request",
                response.message,
                "danger"
            )
        }
    };
    const fetchWarehouseBidList = async (searchValue = "") => {
        setIsLoading(true);
        const response = await fetchWarehouseBids(
            currentPage,
            paginationValue,
            status,
            searchValue
        );
        if (response.status) {
            setBidList(response.data.list);
            setTotalRecords(response.data.count);
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (status == 1) {
            fetchWarehouseBidList(search)
        }
    }, [currentPage, paginationValue, search]);

    const fetchUnits = async () => {
        const response = await getunits();
        if (response.status) {
            setUnits(response.data);
        } else {
        }
    };


    useEffect(() => {
        fetchUnits();
    }, []);

    const onCloseToaste = () => {
        toast.dismiss();
    };


    useEffect(() => {
        const handleWheel = (event) => {
            if (event.target.type === "number") {
                event.preventDefault();
            }
        };

        // Add event listener to the entire document
        document.addEventListener("wheel", handleWheel, { passive: false });

        return () => {
            // Clean up the event listener
            document.removeEventListener("wheel", handleWheel);
        };
    }, []);
    return (
        <>
            <div className="overflow_auto">
                <table className="payout_table upi-mandate_table">
                    <thead>
                        <tr className="tableHead">
                            <th className="thHeader">
                                <div className="payoutHead hndIcon">Order ID</div>
                            </th>
                            <th className="thHeader">
                                <div className="payoutHead hndIcon">
                                    {handleBuyerTitle()} Name
                                </div>
                            </th>
                            <th className="thHeader">
                                <div className="payoutHead hndIcon">Role</div>
                            </th>
                            <th className="thHeader">
                                <div className="payoutHead">Delivery Address</div>
                            </th>

                            <th className="thHeader">
                                <div className="payoutHead">Product</div>
                            </th>
                            <th className="thHeader">
                                <div className="payoutHead">Variety</div>
                            </th>
                            <th className="thHeader">
                                <div className="payoutHead">MSP</div>
                            </th>
                            <th className="thHeader">
                                <div className="payoutHead">Grade</div>
                            </th>
                            <th className="thHeader">
                                <div className="payoutHead">Price </div>
                            </th>

                            <th className="thHeader">
                                <div className="payoutHead">Quantity </div>
                            </th>

                            <th className="thHeader">
                                <div className="payoutHead">Total Price</div>
                            </th>
                            <th className="thHeader">
                                <div className="payoutHead">Delivery Date</div>
                            </th>
                            <th className="thHeader">
                                <div className="payoutHead">Action </div>
                            </th>
                        </tr>
                    </thead>

                    {isLoading ? (
                        <tr>
                            <td colSpan="14">
                                <Loader />
                            </td>
                        </tr>
                    ) : bidList.length > 0 ? (
                        bidList.map((item) => {
                            return (
                                <tr key={item._id}>
                                    <td className="noWrap">{item?.bidDetails?.orderId}</td>
                                    <td className="phone_number">{item?.buyer?.name}</td>
                                    <td className="Status">{item?.roleDetails?.name}</td>
                                    <td className="Status address_td">
                                        {item?.bidDetails?.addressDetails?.location?.address}

                                    </td>
                                    <td className="table_amount">{item?.product}</td>
                                    <td className="table_amount ">
                                        {item?.veriety ?? "-"}
                                    </td>
                                    <td className="Status noWrap">
                                        <div>
                                            {item?.mspDetails?.minimum_selling_price
                                                ? item?.mspDetails?.minimum_selling_price +
                                                " /" +
                                                item?.mspDetails?.unit?.shortcode
                                                : "-"}
                                        </div>
                                    </td>
                                    <td className="">{item?.grade}</td>

                                    <td className="Status bold_txt">
                                        <div>
                                            {" "}
                                            {handleCurrency()} {item?.price ?? 0}
                                        </div>
                                    </td>

                                    <td className="Status noWrap bold_txt">
                                        <div>
                                            {item?.acceptedQty} {item?.unit ? item?.unit : ""}
                                        </div>
                                    </td>
                                    <td className="bold_txt">
                                        <div>
                                            {handleCurrency()}{" "}
                                            {(item?.price ?? 0) *
                                                (item?.acceptedQty ?? 0) *
                                                (item?.conversion ?? 0)}
                                        </div>
                                    </td>

                                    <td className="noWrap">
                                        {moment(item?.deliveryDate).format("DD-MM-YYYY")}
                                    </td>
                                    <td>
                                        <div className="request_toFarm">
                                            {item?.isWarehouseRequestedToFarmer ? (
                                                <>
                                                    {`Requested to ${item?.requestedToCount
                                                        ? item?.requestedToCount
                                                        : "0"
                                                        } farmers`}
                                                </>
                                            ) : (
                                                <>
                                                    {item?.status == 5 ? (
                                                        "In Stock"
                                                    ) : (
                                                        <>
                                                            <button
                                                                color="dark"
                                                                className="noWrap btn btn-primary"
                                                                isLight="btn btn-primary"
                                                                onClick={() => {
                                                                    let id = item?.warehouseAddress?._id
                                                                        ? item?.warehouseAddress?._id
                                                                        : item?.warehouseDefaultAddress[0]
                                                                            ?._id;
                                                                    setId(item._id);
                                                                    setBuyerReqId(item.bidDetails._id);
                                                                    // Prefill form values using the selected row data
                                                                    requestToFarmerFormik.setValues({
                                                                        quantity: item.acceptedQty,
                                                                        unitId: item.unitId,
                                                                        price: item.price,
                                                                        deliveryDate: moment(
                                                                            item.deliveryDate
                                                                        ).format("YYYY-MM-DD"),
                                                                    });
                                                                    handleShow();
                                                                }}
                                                            >
                                                                Request to Farmer
                                                            </button>
                                                            <button
                                                                color="dark"
                                                                className="noWrap btn btn-success"
                                                                isLight="btn btn-primary"
                                                                onClick={() => {
                                                                    requestUpdated(item?._id);
                                                                }}
                                                            >
                                                                In Stock
                                                            </button>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <>
                            <tr>
                                <td colSpan="15" className="text-align-center">
                                    No data available
                                </td>
                            </tr>
                        </>
                    )}
                </table>{" "}
            </div>
            {bidList.length > 0 ? <Pagination
                currentPage={currentPage}
                totalCount={totalRecords}
                pageSize={paginationValue}
                onPageChange={(page) => setCurrentPage(page)}
                changePageSize={(page) => {
                    setPaginationValue(page);
                    setCurrentPage(1);
                }}
            /> : ""}
            <Offcanvas
                show={show}
                onHide={() => {
                    handleClose();
                    setValue(0);
                }}
                placement="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Request Farmer</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <div className="add_buyer_content">
                        <form action="" onSubmit={requestToFarmerFormik.handleSubmit}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <p className="modal_desc">
                                        Note: This section is for entering the request details to
                                        be sent to the farmer, including the required quantity,
                                        the offered price, and the preferred delivery date for the
                                        products.
                                    </p>

                                    <Form.Label>Distance: {value}(KM)</Form.Label>
                                    <Form.Range
                                        value={value}
                                        onChange={handleRangeChange}
                                        min={0} // Minimum value for the range slider
                                        max={4000} // Maximum value for the range slider
                                        step={1}
                                    />
                                    <div className="input-box">
                                        <div className="input-label">
                                            Quantity
                                            <span>*</span>
                                        </div>
                                        <div className="input_field">
                                            <input
                                                type="number"
                                                className="input"
                                                placeholder="Enter quantity"
                                                id="beneficiary"
                                                name="quantity"
                                                min="1"
                                                max="100000000"
                                                onChange={(e) => {
                                                    requestToFarmerFormik.setFieldValue(
                                                        "quantity",
                                                        Number(e.target.value)
                                                    );
                                                }}
                                                value={
                                                    requestToFarmerFormik?.values?.quantity == 0
                                                        ? ""
                                                        : requestToFarmerFormik?.values?.quantity
                                                }
                                                required
                                                onKeyDown={handleKeyDown}
                                            />
                                        </div>
                                    </div>
                                    <div className="input-box">
                                        <div className="input-label">
                                            Unit
                                            <span>*</span>
                                        </div>
                                        <div className="input_field">
                                            <select
                                                className="input"
                                                onChange={(e) => {
                                                    requestToFarmerFormik.setFieldValue(
                                                        "unitId",
                                                        e.target.value
                                                    );
                                                }}
                                                value={requestToFarmerFormik.values.unitId ?? ""}
                                                required
                                            >
                                                <option value="">Select Unit</option>
                                                {units.map((unit) => {
                                                    return (
                                                        <option
                                                            key={unit._id}
                                                            id={unit._id}
                                                            value={unit._id}
                                                        >
                                                            {unit.shortcode}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="input-box">
                                        <div className="input-label">
                                            Price/kg
                                            <span>*</span>
                                        </div>
                                        <div className="input_field">
                                            <input
                                                type="number"
                                                className="input"
                                                placeholder="Enter price"
                                                id="beneficiary"
                                                name="price"
                                                min="1"
                                                max="10000000"
                                                onChange={(e) => {
                                                    requestToFarmerFormik.setFieldValue(
                                                        "price",
                                                        e.target.value
                                                    );
                                                }}
                                                value={
                                                    requestToFarmerFormik.values.price == 0
                                                        ? ""
                                                        : requestToFarmerFormik.values.price
                                                }
                                                onKeyDown={handleKeyDown}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="input-box">
                                        <div className="input-label">
                                            Select a date before delivery date
                                            <span>*</span>
                                        </div>
                                        <div className="input_field">
                                            <input
                                                className="input"
                                                required
                                                name="deliveryDate"
                                                type="date"
                                                placeholder="select date"
                                                value={requestToFarmerFormik.values.deliveryDate}
                                                min={moment(new Date()).format("YYYY-MM-DD")}
                                                onChange={requestToFarmerFormik.handleChange}
                                                onKeyDown={(e) => e.preventDefault()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex_btn mt-3 ">
                                <button className="btn btn-success" type="submit">
                                    Send Request
                                </button>
                            </div>
                        </form>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};
