import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import { Link } from "react-router-dom";
import plusWhite from "../../../assets/images/plus-white.svg";
import Pagination from "../../../components/pagination/paginationView";
import trashIcon from "../../../assets/images/trash-03.svg";
import editIcon from "../../../assets/images/edit-02.svg";
import { Loader } from "../../../components/loader";
import { getWithdowlList } from "../../../action/contest";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import moment from "moment";

const WithdrowlList = () => {
  const [pageSize, setPageSize] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalData, setTotalData] = useState(null);
  const [WithdrowlDataList, setWithdrowlDataList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [status, setstatus] = useState(0);

  const getwithdowlList = async (page) => {
    try {
      setIsLoading(true);
      const response = await getWithdowlList(page, pageSize, status); // Adjust 10 to the desired number of items per page
      if (response.status) {
        setWithdrowlDataList(response.data);
        setTotalData(response.data.count);
        setIsLoading(false);
        console.log("withdrawl", response.data);
        console.log("withdrawl status", response.data);
      }
    } catch (error) {
      console.error("Failed to fetch category data", error);
    }
  };
  useEffect(() => {
    getwithdowlList(pageNo);
  }, [pageNo, pageSize, status]);
  return (
    <>
      <div className="payout_data-content">
        <div className="payout_data-head">
          <div className="payout_data-head-top mb-2">
            <div className="payout_data-left">
              <div className="payout_data_ttl">
                {" "}
                <Breadcrumb paths={[{ name: "Withdraw List" }]} />
              </div>
            </div>
            {/* <Link
            className="btn"
            color="info"
            to={"/module/add"}>
            Create New
            <img
              src={plusWhite}
              alt=""
              className="payout_down"
            />
          </Link> */}
          </div>
        </div>
        <Tabs>
          <TabList>
            <Tab
              onClick={() => {
                setstatus(0);
              }}
            >
              Pending Request
              {/* <div className="paymentHits">125</div> */}
            </Tab>
            <Tab
              onClick={() => {
                setstatus(1);
              }}
            >
              Confirmed Request
            </Tab>
          </TabList>
          <TabPanel>
            <table className="payout_table upi-mandate_table">
              <thead>
                <tr className="tableHead">
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Name</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Mobile</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Email</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Amount</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">UPI ID</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Start date</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Update date</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="15">
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {WithdrowlDataList?.length > 0 ? (
                      WithdrowlDataList?.map((item) => (
                        <tr key={item._id}>
                          <td>{item?.userInfo?.name}</td>
                          <td>{item?.userInfo?.mobile}</td>
                          <td>
                            {item?.userInfo?.email.length
                              ? item?.userInfo?.email
                              : "-"}
                          </td>
                          <td>
                            {" "}
                            {"₹"} {item?.amount}
                          </td>
                          <td>{item?.userInfo?.paymentInfo?.upiId} </td>

                          <td>
                            {moment(item?.startDate).format("DD/MM/YYYY")}
                          </td>
                          <td>{moment(item?.endDate).format("DD/MM/YYYY")}</td>

                          {/* <td className="three_btn">
                              <Link to={`/contest/list/${item._id}`}>
                                <img
                                  src={contestIcon}
                                  alt="/"
                                />
                              </Link>
                              <Link to={`/module/edit/${item._id}`}>
                                <img
                                  src={editIcon}
                                  alt="Edit icon"
                                />
                              </Link>
                              <img
                                src={trashIcon}
                                style={{
                                  height: "26px",
                                }}
                                alt="delete icon"
                              />
                            </td> */}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="15" className="text_center">
                          No data available
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
            {/* {categoryList.length > 0 && (  )} */}
            <>
              {/* <Pagination
              currentPage={pageNo}
              totalCount={totalData}
              pageSize={pageSize}
              onPageChange={(page) => setPageNo(page)}
              changePageSize={(page) => {
                setPageSize(page);
                setPageNo(1);
              }}
            /> */}
            </>
          </TabPanel>
          <TabPanel>
            <table className="payout_table upi-mandate_table">
              <thead>
                <tr className="tableHead">
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Name</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Mobile</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Email</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Amount</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">UPI ID</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Start date</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Update date</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan="15">
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {WithdrowlDataList?.length > 0 ? (
                      WithdrowlDataList?.map((item) => (
                        <tr key={item._id}>
                          <td>{item?.userInfo?.name}</td>
                          <td>{item?.userInfo?.mobile}</td>
                          <td>
                            {item?.userInfo?.email.length
                              ? item?.userInfo?.email
                              : "-"}
                          </td>
                          <td>
                            {" "}
                            {"₹"} {item?.amount}
                          </td>
                          <td>{item?.userInfo?.paymentInfo?.upiId} </td>

                          <td>
                            {moment(item?.startDate).format("DD/MM/YYYY")}
                          </td>
                          <td>{moment(item?.endDate).format("DD/MM/YYYY")}</td>

                          {/* <td className="three_btn">
                              <Link to={`/contest/list/${item._id}`}>
                                <img
                                  src={contestIcon}
                                  alt="/"
                                />
                              </Link>
                              <Link to={`/module/edit/${item._id}`}>
                                <img
                                  src={editIcon}
                                  alt="Edit icon"
                                />
                              </Link>
                              <img
                                src={trashIcon}
                                style={{
                                  height: "26px",
                                }}
                                alt="delete icon"
                              />
                            </td> */}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="15" className="text_center">
                          No data available
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};

export default WithdrowlList;
