import { SideMenuItems } from "./sideNavItems";
import {
  adminMenu,
  sidenavData,
  warehouse,
  exporterMenu,
  retailerMenu,
  superAdminMenu,
  userSidenav,
} from "../data/sidenav";
import defaultLogo from "../assets/images/gaon_logo.png";
import logo from "../assets/images/gaon_logo.png";
import logo2 from "../assets/images/frmlinkLogo.png";
import Help from "../assets/images/help-msg.svg";
import dummyProfileImage from "../assets/images/placeholder.png";
import logoutIcon from "../assets/images/log-out.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useState } from "react";
import { useEffect } from "react";
import chevronDownIcon from "../assets/images/chevron-down.svg";
import { Logout } from "../components/logout";
import { getMe } from "../action/users";
import showNotification from "../components/notification/showNotification";
import { Farmlink, Gaon } from "../helper/constants";

export function SideNav({ onClick }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    pic: "",
  });
  const [toggle, setToggle] = useState(false);
  const toggleButton = () => {
    setToggle(!toggle);
  };
  const [open, setOpen] = useState(false);

  const handleClosePopUp = () => {
    setOpen(false);
  };

  const fetchMe = async () => {
    const response = await getMe();
    if (response.status) {
      setUserData(response?.data);
    }
  };

  const handleCloseToaster = () => {
    toast.dismiss();
  };
  const handleLogOut = () => {
    setOpen(true);
  };

  const logout = () => {
    showNotification("Logout", "Logout Successfully", "success");
    localStorage.clear();
    navigate("/login");
    setOpen(false);
  };

  const role = localStorage.getItem("role");
  const roleType = localStorage.getItem("roleType");
  const userId = localStorage.getItem("userId");

  let navList =
    role == "BUYER" || role == "MANUFACTURER"
      ? sidenavData
      : role == "EXPORTER"
        ? exporterMenu
        : role == "RETAILER"
          ? retailerMenu
          : role == "WAREHOUSE"
            ? warehouse
            : role == "SUPERADMIN"
              ? superAdminMenu
              : role == "ADMIN"
                ? adminMenu
                : role == "USER"
                  ? userSidenav
                  : [];

  useEffect(() => {
    fetchMe();
  }, [location.pathname]);
  const HandleLogo = () => {
    if (Farmlink()) {
      return logo2;
    } else {
      return logo;
    }
  }


  return (
    <>
      <div className={`sideMenu ${toggle ? "collapse" : ""}`}>
        <div className="sidemenu_top">
          <div className="sideMenu_head">
            <div className="sidelogo">
              <img
                // src={`${role == "SUPERADMIN" ? logo : logo2}`}
                src={HandleLogo()}
                alt="logo"
                className="sidelogoImg"
              />
            </div>
          </div>

          {navList.map((item, index) => (
            <SideMenuItems key={index} item={item} toggle={toggle} />
          ))}
          {role == "SUPERADMIN" ? (
            <div
              className={open ? "sideMenu-item openActive" : "sideMenu-item "}
            >
              <div
                className="sideLink"
                onClick={() => {
                  navigate("/banner/list");
                }}
              >
                <div className="sideLink_item">
                  <img src={Help} alt="" className="sidelink_icon" />
                  <span>Banner</span>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="sideMenu-bottom">
          {/* <div className="sideMenu-item">
            <div className="sideLink">
              <Link
                onClick={(e) => {
                  window.location.href = "mailto:support@seccure.biz";
                  e.preventDefault();
                }}
                className="sideLink_item"
              >
                <img src={helpInfo} alt="" className="sidelink_icon" /> Need
                Help?
              </Link>
            </div>
          </div>
          <div className="sideMenu-item">
            <div className="sideLink">
              <Link to={"#"} className="sideLink_item">
                <img src={settings} alt="" className="sidelink_icon" /> Settings
              </Link>
            </div>
          </div> */}
          <div className="side_bottom-card-box">
            <div className="side_bottom-card">
              <div className="bottom_crd-image">
                <img
                  src={
                    userData?.pic
                      ? process.env.REACT_APP_S3_URL + "/" + userData?.pic
                      : dummyProfileImage
                  }
                  alt=""
                  onClick={() => {
                    navigate(`/user/profile/${userId}`);
                  }}
                />
              </div>
              <div className="bottom_crd-text">
                <div className="bottom_crd-title ">
                  <div className="user-d-flex">
                    {/* {userData.name.length > 13
                      ? userData.name.substring(0, 13) + "..."
                      : userData.name} */}
                    {userData.name}
                    {/* {userData?.rolesDetails?.shortcode} */}
                    {/* {role !== "SUPERADMIN" && role !== "ADMIN" && (
                      <div>
                        <img
                          className="bell_icon"
                          src={bellIcon}
                          alt=""
                          height="25px"
                          width="25px"
                          onClick={() => {
                            navigate("/notification/list");
                          }}
                        />
                        {userData?.notificationCount > 0 && (
                          <span>{userData.notificationCount}</span>
                        )}
                      </div>
                    )} */}
                    {/* <span>
                      {userData.name.length > 13 && (
                        <Tooltip className="tooltips" title={userData.name}>
                          <img src={circleInfo} alt="" />
                        </Tooltip>
                      )}
                    </span> */}
                  </div>

                  {/* <div className="bottom_crd-desc">{userData.email}</div> */}
                  <div className="bottom_crd-desc">
                    {userData?.rolesDetails?.shortcode}
                  </div>

                  <div className="bottom_crd-desc">{userData.phone}</div>
                </div>
              </div>
              <div className="logout_btn">
                <img
                  src={logoutIcon}
                  alt=""
                  className="logout_img"
                  onClick={handleLogOut}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`collapse_btn ${toggle ? "active" : ""}`}
        onClick={toggleButton}
      >
        <img src={chevronDownIcon} alt="" className="collapse_sideMenu" />
      </div>

      <Logout open={open} onClose={handleClosePopUp} onLogout={logout} />
    </>
  );
}
