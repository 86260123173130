import { usePagination } from "./paginationRange";
import arrow from "../../assets/images/left-chevoron.svg";
import { FirstPage } from "@mui/icons-material";
import { boolean } from "yup";
const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    changePageSize,
    showPageSizeView = true,
    showRowRangeView = true,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const onNext = () => {
    if (currentPage == lastPage) {
      onPageChange(currentPage);
    } else {
      onPageChange(currentPage + 1);
    }
  };

  const onPrevious = () => {
    if (currentPage == 1) {
      onPageChange(currentPage);
    } else {
      onPageChange(currentPage - 1);
    }
  };

  let lastPage = paginationRange
    ? paginationRange[paginationRange?.length - 1]
    : 20;
  const startRow = (currentPage - 1) * pageSize + 1;
  const endRow = Math.min(currentPage * pageSize, totalCount);

  return (
    <div className="drBottom">
      {Boolean(showRowRangeView && totalCount) && (
        <div className="rowRange">
          <span>{`${startRow}-${endRow} of ${totalCount} rows`}</span>
        </div>
      )}
      {paginationRange?.length > 1 ? (
        <div className="pagination">
          <div
            style={{ cursor: currentPage === 1 ? "no-drop" : "pointer" }}
            className={
              currentPage === 1
                ? "pagePrev pageBtn disable"
                : "pagePrev pageBtn"
            }
            onClick={onPrevious}
          >
            <img src={arrow} alt="arrow" />
          </div>
          <div className="pages_no">
            {paginationRange.map((pageNumber) => {
              return pageNumber !== "..." ? (
                <div
                  className={
                    pageNumber === currentPage ? "pageNo active" : "pageNo"
                  }
                  onClick={() => onPageChange(pageNumber)}
                >
                  {pageNumber}
                </div>
              ) : (
                <p className="dots">...</p>
              );
            })}
          </div>
          <div
            style={{
              cursor: currentPage === lastPage ? "no-drop" : "pointer",
            }}
            onClick={onNext}
            className={
              currentPage === lastPage
                ? "pageNext pageBtn disable"
                : "pageNext pageBtn"
            }
          >
            <img src={arrow} alt="arrow" />
          </div>
        </div>
      ) : (
        <></>
      )}

      {showPageSizeView && (
        <div className="pageSelect">
          {/* <span className="pageSelectTxt">view</span> */}
          <select
            className="form-select"
            value={pageSize}
            onChange={(e) => {
              changePageSize(Number(e.target.value));
            }}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
            <option value={25}> 25</option>
          </select>
          {/* <span className="pageSelectTxt">out of 25</span> */}
        </div>
      )}
    </div>
  );
};

export default Pagination;

// export default PaginationView;
