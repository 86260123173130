import { complaintStatusEnum } from "../enumTypes/enums";
import { Farmlink, Gaon, gaonTextBasedOnRoleTYpe } from "../helper/constants";

const role = localStorage.getItem("role");
console.log('BaseUrl Farmlink==>', Farmlink());

const handleGaonText = () => {
  if (Farmlink()) {
    return "Villages";
  } else {
    return "Gaons";
  }
}

export const sidenavData = [
  {
    title: "Dashboard",
    icon: "dashboard",
    path: "/",
  },
  {
    title: "Buyer Request",
    icon: "home",
    path: "/buyerReqest/list",
  },
];

export const warehouse = [
  {
    title: "Dashboard",
    icon: "dashboard",
    path: "/",
  },
  {
    title: "Buyer request for you",
    path: "/bidWarehouse",
    icon: "warehouse",
  },
  {
    title: "Your request to the farmers",
    path: "/warehouseRequest/list",
    icon: "farm",
  },
  {
    title: "Available products nearby",
    path: "/farmer/products",
    icon: "farmer_product",
  },
];

export const adminMenu = [
  {
    title: "Dashboard",
    icon: "dashboard",
    path: "/",
  },
  {
    // title: gaonTextBasedOnRoleTYpe() + "s",
    title: handleGaonText(),
    path: "/gaon",
    icon: "gaon",
  },
  {
    title: "Users",
    path: "/userList",
    icon: "users",
  },
  {
    title: "Units",
    path: "/unitList",
    icon: "units",
  },
  {
    title: "Products",
    path: "/product/list",
    icon: "products",
  },
  {
    title: "Minimum Selling Price",
    path: "/msp/list",
    icon: "priceTag",
  },

  {
    title: "Grade",
    path: "/grade/list",
    icon: "gradeIcon",
  },
];

export const superAdminMenu = [
  {
    title: "Dashboard",
    icon: "dashboard",
    path: "/",
  },
  {
    title: "User Department",
    path: "/user_department",
    icon: "user_control",
  },
  {
    title: handleGaonText(),
    path: "/gaon",
    icon: "gaon",
  },
  {
    title: "Users",
    path: "/userList",
    icon: "users",
  },
  {
    title: "Units",
    path: "/unitList",
    icon: "units",
  },
  {
    title: "Products",
    path: "/product/list",
    icon: "products",
  },
  {
    title: "Contest",
    path: "/module/list",
    icon: "contest",
  },
  {
    title: "Withdrawal",
    path: "/withdrawl",
    icon: "withdrawl",
  },
  {
    title: "Notification",
    path: "/user-notification",
    icon: "users",
  },
  {
    title: "News",
    path: "/news",
    icon: "news",
  },
  {
    title: "Calendar Event",
    path: "/calendar-event",
    icon: "calendar",
  },
  {
    title: "Rural revolution",
    path: "/rural-revolution",
    icon: "users",
  },
  {
    title: "Minimum Selling Price",
    path: "/msp/list",
    icon: "priceTag",
  },
  {
    title: "Grade",
    path: "/grade/list",
    icon: "gradeIcon",
  },
];

export const userSidenav = [
  {
    title: "Dashboard",
    icon: "dashboard",
    path: "/",
  },
  {
    title: "Complaints",
    path: `/complaints/${complaintStatusEnum.PENDING}`,
    icon: "units",
  },
];

export const exporterMenu = [
  {
    title: "Dashboard",
    icon: "dashboard",
    path: "/",
  },
  {
    title: "Exporter Request",
    icon: "home",
    path: "/buyerReqest/list",
  },
];

export const retailerMenu = [
  {
    title: "Dashboard",
    icon: "dashboard",
    path: "/",
  },
  {
    title: "Retailer Request",
    icon: "home",
    path: "/buyerReqest/list",
  },
];
