import { requestWithToken } from "../helper/helper";

const getModuleList = async (pageNo, pageSize) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/module?pageNo=${pageNo}&pageSize=${pageSize}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const deleteModule = async (id) => {
  try {
    const response = await requestWithToken("DELETE", `/admin/module/${id}`);

    return response;
  } catch (err) {
    return err.response.data;
  }
};
const getWithdowlList = async (pageNo, pageSize, status) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/withdrawRequest?pageNo=${pageNo}&pageSize=${pageSize}&status=${status}`

    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const createModule = async (payload) => {
  try {
    const response = await requestWithToken("POST", `/admin/module`, payload);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchModuleDetails = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/moduleDetails/${id}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const fetchModulecategory = async () => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/module-category`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const updateModule = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/admin/module/${id}`,
      payload
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getContestList = async (pageNo, pageSize) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/contest?pageNo=${pageNo}&pageSize=${pageSize}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const createContest = async (payload) => {
  try {
    const response = await requestWithToken("POST", `/admin/contest`, payload);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchContestDetails = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/contestDetails/${id}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const updateContest = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/admin/contest/${id}`,
      payload
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const updateContestStatus = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/admin/moduleStart/${id}`,
      { isDisplay: payload }
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchParticipantList = async (id) => {
  try {
    const response = await requestWithToken("GET", `/admin/participant/${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const fetchElligibleList = async (id) => {
  try {
    const response = await requestWithToken("GET", `/contest/eligible/${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const postWinner = async (id) => {
  try {
    const response = await requestWithToken("POST", `/admin/winners/${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchWinners = async (id) => {
  try {
    const response = await requestWithToken("GET", `/contest/winners/${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const deleteContest = async (id) => {
  try {
    const response = await requestWithToken("DELETE", `/admin/contest/${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const AddDailyEvent = async (payload) => {
  try {
    const response = await requestWithToken("POST", `/daily_post/`, payload);
    return response;
  } catch (err) {
    return err.response.data;
  }
};
const EditDailyEvent = async (id, payload) => {
  try {
    const response = await requestWithToken("PUT", `/daily_post/${id}`, payload);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const GetDailyEvent = async (pageNo, pageSize) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/daily_post/list?pageNo=${pageNo}&pageSize=${pageSize}`
    );
    return response?.data;
  } catch (err) {
    return err.response?.data;
  }
};

const DeleteDailyEvent = async (id) => {
  try {
    const response = await requestWithToken("DELETE", `/daily_post/${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
export {
  AddDailyEvent,
  EditDailyEvent,
  GetDailyEvent,
  DeleteDailyEvent,
  getModuleList,
  createModule,
  fetchModuleDetails,
  updateModule,
  getContestList,
  createContest,
  fetchContestDetails,
  updateContest,
  fetchParticipantList,
  postWinner,
  fetchWinners,
  deleteContest,
  updateContestStatus,
  fetchElligibleList,
  getWithdowlList,
  deleteModule,
  fetchModulecategory
};
