import React, { useState, useEffect } from "react";
import plusWhite from "../../../assets/images/plus-white.svg";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../../styles/style.css";
import "../../../styles/payout.css";
import "../../../styles/upi-mandate.css";
import { TabPanel, Tabs } from "react-tabs";
import trashIcon from "../../../assets/images/trash-03.svg";
import editIcon from "../../../assets/images/edit_btn.svg";
import Modal from "react-bootstrap/Modal";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import { Loader } from "../../../components/loader";
import {
  deleteContest,
  fetchElligibleList,
  fetchModuleDetails,
  fetchParticipantList,
  fetchWinners,
  postWinner,
} from "../../../action/contest";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { SingleErrorMessage } from "../../singleErrorMessage";
import { toast } from "react-hot-toast";

const ContestList = () => {
  const navigate = useNavigate();
  const { moduleId } = useParams();
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isListLoading, setIsListLoading] = useState(true);
  const [isWinnerSelected, setIsWinnerSelected] = useState(true);
  const [openDeleteModel, setIsOpenDeleteModel] = useState(false);
  const [openWinnerModel, setOpenWinnerModel] = useState(false);
  const [viewWinnerModel, setViewWinnerModel] = useState(false);
  const [contestId, setContestId] = useState("");
  const [showModel, setShowModel] = useState(false);
  const [list, setList] = useState([]);
  const [Winerlist, setWinerList] = useState([]);
  const [elligiblelist, setelligibleList] = useState([]);
  const [partTab, setpartTab] = useState(1);
  const getCategoryPage = async () => {
    try {
      setIsLoading(true);
      const response = await fetchModuleDetails(moduleId); // Adjust 10 to the desired number of items per page
      if (response.status) {
        setCategoryList(response.data[0].contests);
        setIsWinnerSelected(response.data[0].winnersSelected);
        if (response.data[0].winnersSelected) {
          getWinners();
        } else {
          getUsers();
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch category data", error);
    }
  };

  const getUsers = async () => {
    try {
      setIsListLoading(true);
      const response = await fetchParticipantList(moduleId);
      if (response.status) {
        if (response.data) {
          setList(response.data?.users);
        }
        setIsListLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch category data", error);
    }
  };
  const getEligible = async () => {
    try {
      setIsListLoading(true);
      const response = await fetchElligibleList(moduleId);
      if (response.status) {
        if (response.data) {
          setelligibleList(response.data);
        }
        setIsListLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch category data", error);
    }
  };

  const selectWinners = async () => {
    try {
      setIsListLoading(true);
      const response = await postWinner(moduleId);
      if (response.status) {
        setList(response?.data);
        setIsListLoading(false);
        getWinners();
      }
    } catch (error) {
      console.error("Failed to fetch category data", error);
    }
  };

  const getWinners = async () => {
    try {
      setIsListLoading(true);
      const response = await fetchWinners(moduleId);
      if (response.status) {
        setWinerList(response?.data?.list);
        setIsListLoading(false);
      }
    } catch (error) {
      console.error("Failed to fetch category data", error);
    }
  };

  const handleDeleteContest = async () => {
    try {
      const response = await deleteContest(contestId);
      if (response.status) {
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
        getCategoryPage();
        setIsOpenDeleteModel(false);
      }
    } catch (error) {
      console.error("Failed to fetch category data", error);
    }
  };

  const onCloseToaste = () => {
    toast.dismiss();
  };

  useEffect(() => {
    getCategoryPage();
    getEligible();
    getWinners();
    getUsers();
  }, []);
  return isLoading ? (
    <Loader />
  ) : (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top mb-2">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              <Breadcrumb
                paths={[
                  { name: "Contest", link: "/module/list" },
                  { name: "Question", link: "/contest/list" },
                ]}
              />
            </div>
          </div>
          <button
            color="info"
            tag="a"
            onClick={() => {
              setShowModel(true);
            }}
            className="btn btn-info addbbtn"
          >
            Participants List
          </button>
          <Link className="btn btn-info addbbtn" color="info" to={`/contest/add/${moduleId}`}>
            Add Question
            <img src={plusWhite} alt="" className="payout_down" />
          </Link>
        </div>
      </div>
      <div className="tab_box">
        <Tabs>
          <TabPanel>
            <div className="">
              <table className="payout_table upi-mandate_table">
                <thead>
                  <tr className="tableHead">
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Title</div>
                    </th>
                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Options</div>
                    </th>

                    <th className="thHeader">
                      <div className="payoutHead hndIcon">Action</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td colSpan="15">
                        <Loader />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {categoryList?.length > 0 ? (
                        categoryList?.map((item) => (
                          <tr key={item._id}>
                            <td>{item?.title?.en}</td>
                            <td>{item?.options?.length}</td>
                            <td className="flex_btn three_btn">
                              <Link to={`/contest/edit/${item._id}`}>
                                <img src={editIcon} alt="editIcon" />
                              </Link>
                              <img
                                src={trashIcon}
                                onClick={() => {
                                  setContestId(item?._id);
                                  setIsOpenDeleteModel(true);
                                }}
                                alt="trash"
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="15" className="text_center">
                            No data available
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </TabPanel>
        </Tabs>
      </div>
      <Offcanvas
        show={showModel}
        onHide={() => {
          setShowModel(false);
          setpartTab(1);
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {/* {isWinnerSelected ? "Winners" : "Participants"} */}
            {partTab == 2 ? (
              <>
                {elligiblelist?.length > 0 ? (
                  <>
                    {Winerlist?.length ? (
                      <button
                        color="info"
                        tag="a"
                        onClick={() => {
                          // selectWinners();
                          setViewWinnerModel(true);
                        }}
                      >
                        View Winners
                      </button>
                    ) : (
                      <button
                        color="info"
                        tag="a"
                        onClick={() => {
                          // selectWinners();
                          setOpenWinnerModel(true);
                        }}
                      >
                        Select Winners
                      </button>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="scroll_v">
            <div className="contest__tabs">
              <div
                className={`contest__tab ${partTab == 1 ? "active" : ""}`}
                onClick={() => {
                  setpartTab(1);
                }}
              >
                Participants List
              </div>
              <div
                className={`contest__tab ${partTab == 2 ? "active" : ""}`}
                onClick={() => {
                  setpartTab(2);
                }}
              >
                Eligible Users
              </div>
            </div>
            <table className="payout_table upi-mandate_table">
              <thead>
                <tr className="tableHead">
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Name</div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {isListLoading ? (
                  <tr>
                    <td colSpan="4">
                      <Loader />
                    </td>
                  </tr>
                ) : partTab == 1 ? (
                  <>
                    {list?.length > 0 ? (
                      list?.map((item) => (
                        <tr key={item?._id}>
                          <td>{item?.name}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text_center">
                          No data available
                        </td>
                      </tr>
                    )}
                  </>
                ) : (
                  <>
                    {elligiblelist?.length > 0 ? (
                      elligiblelist.map((item, i) => (
                        <tr key={item?._id}>
                          <td>{item?.users?.name}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text_center">
                          No data available
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        show={openDeleteModel}
        onHide={() => {
          setIsOpenDeleteModel(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure want to delete this question?</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-dark"
            variant="secondary"
            onClick={() => {
              setIsOpenDeleteModel(false);
            }}
          >
            No
          </button>
          <button
            className="btn btn-success"
            variant="primary"
            onClick={handleDeleteContest}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={openWinnerModel}
        onHide={() => {
          setOpenWinnerModel(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Winner</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to proceed ?</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-dark"
            variant="secondary"
            onClick={() => {
              setOpenWinnerModel(false);
            }}
          >
            No
          </button>
          <button
            className="btn btn-success"
            variant="primary"
            onClick={() => {
              selectWinners();
              setOpenWinnerModel(false);
            }}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={viewWinnerModel}
        onHide={() => {
          setViewWinnerModel(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Winner List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="winnersList">
            <table className="payout_table upi-mandate_table">
              <thead>
                <tr className="tableHead">
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Name</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Price</div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {isListLoading ? (
                  <tr>
                    <td colSpan="4">
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {Winerlist?.length > 0 ? (
                      Winerlist?.map((item) => {
                        return (
                          <tr
                            key={item?._id}
                            onClick={() => {
                              navigate(`/user/profile/${item?.userId}`);
                            }}
                          >
                            <td>{item?.name}</td>
                            <td>Rs. {item?.price}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="3" className="text_center">
                          No data available
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ContestList;
