import { requestWithToken } from "../helper/helper";
const fetchBannerList = async (currentPage, perPage) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/banner?pageNo=${currentPage}&pageSize=${perPage}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const deletebanner = async (id) => {
  try {
    const response = await requestWithToken("DELETE", `/admin/banner/${id}`);

    return response;
  } catch (err) {
    return err.response.data;
  }
};
const AddBannerApi = async (payload) => {
  try {
    const response = await requestWithToken("POST", `/admin/banner/`, payload);
    return response;
  } catch (err) {
    return err.response.data;
  }
};

const EditBannerApi = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/admin/banner/${id}`,
      payload
    );

    return response;
  } catch (err) {
    return err.response.data;
  }
};
const singleBannerApi = async (id) => {
  try {
    const response = await requestWithToken("GET", `/admin/banner/${id}`);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
export {
  fetchBannerList,
  deletebanner,
  AddBannerApi,
  EditBannerApi,
  singleBannerApi,
};
