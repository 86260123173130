import React, { useState, useEffect } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import trashicon from "../../assets/images/trash-03.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteAddress,
  editUserDetails,
  getUserDetails,
} from "../../action/userApi";
import { Button } from "react-bootstrap";
import { useFormik } from "formik";
import { getParentId } from "../../action/category";
import { toast } from "react-hot-toast";
import { SingleErrorMessage } from "../singleErrorMessage";
import uploadImage from "../../action/imageUpload";
import { Addresses } from "../../components/address/userAddress";
import dummyProfileImage from "../../assets/images/placeholder.png";
import { secondsInDay } from "date-fns";
import { getMe } from "../../action/users";
const UserProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const role = localStorage.getItem("role");
  const [details, setDetails] = useState({});
  const [editMode, setEditMode] = useState({
    profile: false,
    address: false,
    products: false,
  });
  const [verietyList, setVerietyList] = useState([]);
  const [productIds, setProductIds] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [deletedAddresses, setDeletedAddresses] = useState([]);
  const [initialAddresses, setInitialAddresses] = useState([]);
  const [initialProductIds, setInitialProductIds] = useState([]);
  const [showAddress, setShowaddress] = useState({});

  const [searchAddress, setSearchAddress] = useState("");

  const handleAddProduct = () => {
    setProductIds([...productIds, ""]); // Add a new empty entry for the next dropdown
  };

  const getDetails = async () => {
    const response = await getUserDetails(id);
    if (response.status) {
      const data = response?.data ? response?.data[0] : [];
      setDetails(data);
      setShowaddress(data?.rolesDetails?.name);
      setProductIds(
        data?.warehouseProducts?.productIds?.length > 0
          ? data?.warehouseProducts?.productIds
          : []
      );
      setAddresses(
        data?.addresses?.length > 0
          ? data?.addresses
          : [{ coordinates: [0, 0], address: "", postalCode: "" }]
      );

      editProfileFormik.setFieldValue("name", data.name);
      editProfileFormik.setFieldValue("email", data?.email);
      editProfileFormik.setFieldValue("mobile", data?.mobile);
      editProfileFormik.setFieldValue("pic", data?.pic);
      editProfileFormik.setFieldValue("userId", data?._id);
      editProfileFormik.setFieldValue(
        "addresses",
        data?.addresses?.length > 0 ? data?.addresses : []
      );

      editProfileFormik.setFieldValue(
        "warehouseProducts",
        data?.warehouseProducts
      );
    }
  };
  const fetchVerieties = async () => {
    const response = await getParentId(1);
    if (response.status) {
      setVerietyList(response.data);
    }
  };

  const editProfileFormik = useFormik({
    initialValues: {
      name: "",
      mobile: "",
      email: "",
      pic: "",
      userId: "",
      addresses: [],
      warehouseProducts: {
        productIds: [],
      },
      section: "",
    },

    onSubmit: async (values) => {
      // values.coordinates = [location.lng, location.lat];
      // values.location = location;
      values.addresses = addresses;

      let deletedIds = [];
      // if (productIds?.length) {
      // values.warehouseProducts = values.warehouseProducts || {};
      //   values.warehouseProducts.productIds = productIds;
      // }
      values.warehouseProducts = values.warehouseProducts || {};
      values.warehouseProducts.productIds = productIds;

      if (deletedAddresses?.length) {
        deletedIds = deletedAddresses.map((id) => {
          return id._id;
        });
      }

      //  payload for different sections
      const sectionPayload = {
        profile: {
          name: values.name,
          mobile: values.mobile,
          email: values.email,
          pic: values.pic,
          userId: values.userId,
        },
        address: {
          addresses: values.addresses,
          userId: values.userId,
        },
        products: {
          warehouseProducts: values.warehouseProducts,
          userId: values.userId,
        },
      };
      const payload = sectionPayload[values.section] || {};

      const response = await editUserDetails(payload);
      console.log("function api called")

      if (response.status) {
        await handleDeleteAddressIds(deletedIds);
        getDetails();
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );

        navigate("/");
      } else {
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
      }
    },
  });

  const onCloseToaste = () => {
    toast.dismiss();
  };

  const handleDeleteAddressIds = async (ids) => {
    const payload = {
      addressIds: ids,
    };
    const response = await deleteAddress(payload);
    if (response.status) {
      console.log("deleted");
    }
  };

  useEffect(() => {
    getDetails();
    fetchVerieties();
  }, [id]);

  const handleSelectChange = (index, value) => {
    if (productIds.includes(value) && productIds[index] !== value) {
      toast.custom(
        <SingleErrorMessage
          data={
            "This product is already selected. Please choose another product."
          }
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
      return;
    }
    // Create a new array with the updated value at the specified index
    const updatedProductIds = productIds.map((product, i) =>
      i === index ? value : product
    );
    setProductIds(updatedProductIds);
  };

  const handleAddAddress = () => {
    setAddresses([
      ...addresses,
      {
        coordinates: [0, 0],
        address: "",
        postalCode: "",
      },
    ]);
  };

  // const handleDeleteAddress = (index) => {
  //   const addressToRemove = addresses[index];
  //   setDeletedAddresses([...deletedAddresses, addressToRemove]);
  //   setAddresses(addresses.filter((_, i) => i !== index));
  // };
  const handleDeleteProduct = (index) =>
    setProductIds(productIds.filter((_, i) => i !== index));

  const handleDeleteAddress = (index) => {
    const deletedAddress = addresses[index];
    setDeletedAddresses([...deletedAddresses, deletedAddress]);
    setAddresses(addresses.filter((_, i) => i !== index));
  };

  const handleCancel = (section) => {
    getDetails();
    setAddresses([...initialAddresses]);
    setProductIds([...initialProductIds]);
    setEditMode((prev) => ({ ...prev, [section]: false }));
    setDeletedAddresses([]); // Reset deleted addresses on cancel
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      await uploadMediaImage(file);
    }
  };

  const uploadMediaImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const res = await uploadImage(formData, "profile");
    if (res.status) {
      editProfileFormik.setFieldValue("pic", res.data[0]);
    }
  };
  const isRetailerOrExporter = () => {
    return Boolean(
      details?.rolesDetails?.shortcode === "EXPORTER" ||
      details?.rolesDetails?.shortcode === "RETAILER"
    );
  };

  const handleProfileSubmit = (section) => {
    editProfileFormik.setFieldValue("section", section);
    editProfileFormik.handleSubmit();
  };
  console.log("product length", productIds.length)
  return (
    <>
      <div className="profile_div">
        <div class="payout_data-head">
          <div class="payout_data-head-top">
            <div class="payout_data-left">
              <div class="payout_data_ttl1">Profile</div>
            </div>
          </div>
        </div>
        {/* used Prevent Default coz of custom handleSubmit */}
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="payout_inner_content">
            <div className="payout_data-content tablePage_content_header">
              <div className="Personal_div">
                <div className="left_img_div">
                  <h6>General Details </h6>
                  <div className="profileImgSec">
                    <input
                      type="file"
                      id="img_upload"
                      hidden
                      onChange={handleImageChange}
                    />
                    <img
                      src={
                        editProfileFormik.values.pic
                          ? process.env.REACT_APP_S3_URL +
                          "/" +
                          editProfileFormik.values.pic
                          : dummyProfileImage
                      }
                      alt="profileimg"
                      className="profile_img"
                    />
                    {editMode?.profile && (
                      <button
                        type="button"
                        className="profile_btn"
                        onClick={() =>
                          document.getElementById("img_upload").click()
                        }
                      >
                        Change Image
                      </button>
                    )}
                  </div>
                </div>
                <div className="right_detail_div">
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {role == "SUPERADMIN" || role == "ADMIN"
                      ? ""
                      : !editMode?.profile && (
                        <button
                          color="info"
                          className="btn btn-primary"
                          tag="a"
                          type="button"
                          onClick={() => {
                            setEditMode((prev) => ({
                              ...prev,
                              profile: true,
                            }));
                          }}
                        >
                          Edit
                        </button>
                      )}
                  </div>
                  <ul>
                    <li>
                      <label>Name</label>
                      <input
                        type="text"
                        value={editProfileFormik.values?.name}
                        onChange={(e) => {
                          editProfileFormik.setFieldValue(
                            "name",
                            e.target.value
                          );
                        }}
                        readOnly={!editMode?.profile}
                      />
                    </li>
                    <li>
                      <label>Email Id</label>
                      <input
                        type="text"
                        value={editProfileFormik.values?.email}
                        // readOnly={!editMode?.profile}
                        onChange={(e) => {
                          editProfileFormik.setFieldValue(
                            "email",
                            e.target.value
                          );
                        }}
                        readOnly
                      />
                    </li>
                    <li>
                      <label>Phone No.</label>
                      <input
                        type="text"
                        value={editProfileFormik.values?.mobile}
                        // readOnly={!editMode.profile}
                        onChange={(e) => {
                          editProfileFormik.setFieldValue(
                            "mobile",
                            e.target.value
                          );
                        }}
                        readOnly
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {editMode?.profile ? (
              <div className="profile_submit_div">
                <button
                  type="submit"
                  className="btn btn-success btn_submit"
                  onClick={() => handleProfileSubmit("profile")}
                >
                  Save
                </button>
                <button
                  className="btn_submit btn btn-dark"
                  type="button"
                  onClick={() => handleCancel("profile")}
                >
                  Cancel
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="payout_inner_content mt-4">
            {([
              "BUYER",
              "EXPORTER",
              "RETAILER",
              "WAREHOUSE",
              "MANUFACTURER",
            ].includes(role) ||
              (["SUPERADMIN", "ADMIN"].includes(role) &&
                addresses.length > 0)) && (
                <div className="payout_data-content tablePage_content_header">
                  <div className="address_section">
                    <div className="address_head_div">
                      <h6>Address Details</h6>
                      {editMode?.address ? (
                        <button
                          type="button"
                          className="profile_btn"
                          onClick={() => {
                            handleAddAddress();
                          }}
                        >
                          Add Address
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                    {addresses && addresses?.length > 0
                      ? addresses?.map((address, index) => {
                        return (
                          <div key={index} className="address_div">
                            <div className="address_label">
                              <h4>
                                Address{" "}
                                {`${addresses?.length > 1 ? index + 1 : ""}`}
                              </h4>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                {role == "SUPERADMIN" || role == "ADMIN"
                                  ? ""
                                  : !editMode?.address && (
                                    <button
                                      color="info"
                                      className="btn btn-primary"
                                      tag="a"
                                      type="button"
                                      onClick={() => {
                                        setEditMode((prev) => ({
                                          ...prev,
                                          address: true,
                                        }));
                                      }}
                                    >
                                      Edit
                                    </button>
                                  )}
                              </div>
                              {editMode?.address && !address?.isDefault ? (
                                <div
                                  className="rm_add"
                                  onClick={() => handleDeleteAddress(index)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img src={trashicon} alt="trash icon" />{" "}
                                  Remove Section
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <Addresses
                              isRetailerOrExporter={isRetailerOrExporter}
                              setAddresses={setAddresses}
                              addresses={addresses}
                              addressesDetails={address}
                              index={index}
                              editMode={editMode?.address}
                              editProfileFormik={editProfileFormik}
                              showAddress={showAddress}
                            />
                          </div>
                        );
                      })
                      : ""}
                  </div>
                </div>
              )}
            {editMode.address ? (
              <div className="profile_submit_div">
                <button
                  type="submit"
                  className="btn btn-success"
                  onClick={() => handleProfileSubmit("address")}
                >
                  Save
                </button>
                <button
                  className="btn_submit btn btn-dark"
                  type="button"
                  onClick={() => handleCancel("address")}
                >
                  Cancel
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="payout_inner_content mt-4">
            {role === "WAREHOUSE" ||
              (role == "SUPERADMIN" && productIds.length > 0) ||
              (role == "ADMIN" && productIds.length > 0) ? (
              <div className="payout_data-content tablePage_content_header">
                <div className="product_details">
                  <div className="product_head">
                    <h6>Product Details</h6>

                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {role == "SUPERADMIN" || role == "ADMIN"
                        ? ""
                        : !editMode?.products && (
                          <button
                            color="info"
                            tag="a"
                            className="btn btn-primary"
                            type="button"
                            onClick={() => {
                              setEditMode((prev) => ({
                                ...prev,
                                products: true,
                              }));
                            }}
                          >
                            Edit
                          </button>
                        )}
                    </div>
                    {editMode.products ? (
                      <button
                        type="button"
                        className="profile_btn"
                        onClick={handleAddProduct}
                      >
                        Add Products
                      </button>
                    ) : (
                      " "
                    )}
                  </div>
                  <div className="product_list">
                    {productIds.length > 0 ?
                      <ul>
                        {productIds?.map((product, index) => {
                          // Filter verietyList to exclude products already in productIds, except the current index
                          const filteredVerietyList = verietyList.filter(
                            (veriety) =>
                              !productIds.includes(veriety._id) ||
                              veriety._id === product
                          );

                          return (


                            <li key={index}>
                              <div className="product_label">
                                <label>Product {index + 1}</label>
                                {editMode.products && (
                                  <img
                                    src={trashicon}
                                    alt="trashicon"
                                    onClick={() => handleDeleteProduct(index)}
                                  />
                                )}
                              </div>
                              <div className="product_dropdn">
                                <select
                                  value={product}
                                  disabled={!editMode.products}
                                  onChange={(e) =>
                                    handleSelectChange(index, e.target.value)
                                  }
                                >
                                  <option>Select Product</option>
                                  {filteredVerietyList.map((veriety) => (
                                    <option key={veriety._id} value={veriety._id}>
                                      {veriety?.name?.en}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </li>

                          );
                        })}
                      </ul> : <div className="text_center">Currently, no products are added.</div>}
                  </div>
                </div>
              </div>
            ) : (
              " "
            )}
            {editMode?.products ? (
              <div className="profile_submit_div">
                <button
                  type="submit"
                  className="btn btn-success"
                  onClick={() => handleProfileSubmit("products")}
                >
                  Save
                </button>
                <button
                  className="btn_submit btn btn-dark"
                  type="button"
                  onClick={() => handleCancel("products")}
                >
                  Cancel
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
          {/* </>
          ) : (
            ""
          )} */}
        </form>
      </div>
    </>
  );
};

export default UserProfile;
