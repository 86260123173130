import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const LocationSearchInput = ({
  address,
  setAddress,
  setLocation,
  editMode,
  disabled,
  required
}) => {
  const handleChange = (address) => {
    setAddress(address);
  };
  const showPlaceHolder = editMode ? "" : "Search Places";
  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        const placeId = results[0].place_id;
        setAddress(address);
        return getLatLng(results[0]).then((latLng) => ({ latLng, placeId }));
      })
      .then(({ latLng, placeId }) => {
        const location = {
          address: address,
          lat: latLng.lat,
          lng: latLng.lng,
          placeId: placeId,
        };
        setLocation(location);
      })
      .catch((error) => console.error("Error", error));
  };

  return (
    <PlacesAutocomplete
      onChange={handleChange}
      onSelect={handleSelect}
      value={address}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="search_location">
          <input
            {...getInputProps({
              placeholder: showPlaceHolder,
              className: "form-control location-search-input",

            })}
            style={{ width: "100%", padding: "10px", fontSize: "16px" }}
            required={required}
            disabled={editMode ?? false}
          />
          <div
              className={`autocomplete-dropdown-container ${
                suggestions.length > 0 ? "show-dropdown" : ""
              }`}
            >
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              const style = suggestion.active
                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                : { backgroundColor: "#ffffff", cursor: "pointer" };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                  key={suggestion.placeId}
                  style={{
                    padding: "10px",
                    borderBottom: "1px solid #ccc",
                    cursor: "pointer",
                    zIndex: 1000,
                  }}>
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationSearchInput;
