import React, { useEffect, useState } from "react";
import {
  bidFarmerRequest,
  completeBid,
  fetchNewFarmerRequestList,
  fetchReject,
  InprogressRequests,
} from "../../action/farmerProducts";
import { Loader } from "../loader";
import moment from "moment";
import {
  handleBuyerTitle,
  handleCurrency,
  handleKeyDown,
} from "../../helper/helper";
import infoBtn from "../../assets/images/info-circle.svg";
import { useNavigate } from "react-router-dom";
import { fetchAddresses } from "../../action/location";
import Pagination from "../pagination/paginationView";

const NewReq = ({
  Getaddress,
  search,
  ShowBidCanvasFunc,
  addBidFormik,
  addressList,
}) => {
  const [newRequest, setNewRequest] = useState({
    loading: false,
    data: null,
    totalRecords: 0,
    currentPage: 1,
    pageSize: 10,
  });
  const [parentId, setParentId] = useState("");

  const [selectOption, setSelectOption] = useState([]);

  const navigate = useNavigate();

  const fetchNewRequestList = async (searchValue = "") => {
    setNewRequest((i) => ({
      ...i,
      loading: true,
    }));
    const response = await fetchNewFarmerRequestList(
      newRequest.currentPage,
      newRequest.pageSize,
      searchValue
    );
    if (response.status) {
      setNewRequest((i) => ({
        ...i,
        loading: false,
        data: response?.data?.list,
        totalRecords: response?.data?.count,
      }));
    } else {
      setNewRequest((i) => ({
        ...i,
        loading: false,
        data: null,
        totalRecords: 0,
      }));
    }
  };

  //   const fetchDeliveryAddress = async () => {
  //     const response = await fetchAddresses();
  //     if (response.status) {
  //       console.log("address", response);
  //       setAddressList(response.list);
  //     }
  //   };

  useEffect(() => {
    fetchNewRequestList(search);
    // fetchDeliveryAddress()
  }, [newRequest.currentPage, newRequest.pageSize, search]);

  const getMSP = (price, unit) => {
    if (price) {
      return `${price} / ${unit}`;
    }

    return "--";
  };
  return (
    <>
      <div className="overflow_auto">
        <table className="payout_table upi-mandate_table">
          <thead>
            <tr className="tableHead">
              <th className="thHeader">
                <div className="payoutHead hndIcon">Order ID</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead hndIcon">Request Date</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead hndIcon">Farmer Name</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead"> Address</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead">Product</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead">Variety</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead">Grade</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead">MSP</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead">Price </div>
              </th>

              <th className="thHeader">
                <div className="payoutHead">Quantity </div>
              </th>
              <th className="thHeader">
                <div className="payoutHead">Total Price </div>
              </th>
              <th className="thHeader">
                <div className="payoutHead text-center">Action </div>
              </th>
            </tr>
          </thead>

          <tbody>
            {newRequest.loading ? (
              <tr>
                <td colSpan="12">
                  <Loader />
                </td>
              </tr>
            ) : newRequest?.data?.length > 0 ? (
              newRequest.data.map((item) => {
                return (
                  <tr key={item._id}>
                    <td className="phone_number noWrap">
                      {item?.orderId ?? "-"}
                    </td>
                    <td className="phone_number noWrap">
                      {moment(item?.createdAt).format("DD-MM-YYYY")}
                    </td>
                    <td className="Status">{item?.userDetails?.name}</td>
                    <td className="Status">{item?.gaons?.name}</td>
                    <td className="table_amount">
                      {
                        item?.product[0]?.verietyDetails[0]?.productDetails?.name
                          .en
                      }
                    </td>

                    <td className="table_amount ">
                      {item?.product[0]?.verietyDetails[0]?.name.en}
                    </td>

                    <td className="">{item?.grade}</td>
                    <td className="table_amount ">
                      {getMSP(
                        item?.product[0]?.verietyDetails[0]?.mspDetails
                          ?.minimum_selling_price,
                        item?.product[0]?.verietyDetails[0]?.mspDetails?.unit
                          ?.shortcode
                      )}
                    </td>

                    <td className="Status bold_txt">
                      <div>
                        {handleCurrency()} {item?.price ?? 0} / {item?.perUnit}
                      </div>
                    </td>

                    <td className="Status bold_txt">
                      <div>
                        {item?.quantity}{" "}
                        {item?.unit ? item?.unit : ""}
                      </div>
                    </td>
                    <td className="bold_txt">
                      <div>
                        {/* {handleCurrency()}{" "} */}
                        {/* {(item?.price ?? 0) *
                        (item?.quantity ?? 0) *
                        (item?.conversion ?? 0)} */}
                        {/* {(
                        (item?.priceConversion ?? 0) *
                        (item?.quantity ?? 0) *
                        (item?.conversion ?? 0)
                      ).toFixed(2)} */}
                        {handleCurrency()}{" "}
                        {(
                          Math.floor(
                            (item?.priceConversion ?? 0) *
                            (item?.quantity ?? 0) *
                            (item?.conversion ?? 0)
                          )
                        ).toFixed(2)}



                      </div>
                    </td>
                    <td>
                      <div className="flex_btn text-center">
                        <img
                          src={infoBtn}
                          onClick={() => {
                            navigate(`/farmer/products/details/${item._id}`);
                          }}
                          alt="info btn"
                        />
                        <button
                          color="dark"
                          className="noWrap btn btn-primary"
                          isLight="btn btn-primary"
                          tag="a"
                          onClick={() => {
                            setParentId(item?._id);
                            addBidFormik.values.parentId = item?._id;
                            ShowBidCanvasFunc(true);
                            addBidFormik.setValues({
                              parentId: item?._id,
                              price: item?.price,
                              quantity: item?.quantity,
                              unitId: item?.unitId,
                              deliveryDate: moment(item?.deliveryDate).format(
                                "YYYY-MM-DD"
                              ),
                            });

                            if (addressList?.length) {
                              console.log("address id", addressList);
                              const options = addressList.map((item) => {
                                console.log("adress id", item._id);
                                return {
                                  value: item._id || "", // Use a unique identifier if available
                                  label: `${item.location?.address || ""} ${item.postalCode || ""
                                    }`,
                                };
                              });
                              Getaddress(options);
                              setSelectOption(options);
                              console.log("selectOption", selectOption);
                            }
                          }}
                        >
                          Apply Bid
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <>
                <tr>
                  <td colSpan="10" className="text_center">
                    No data available
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={newRequest?.currentPage}
        totalCount={newRequest?.totalRecords}
        pageSize={newRequest?.pageSize}
        onPageChange={(page) =>
          setNewRequest((i) => ({
            ...i,
            currentPage: page,
          }))
        }
        changePageSize={(size) =>
          setNewRequest((i) => ({
            ...i,
            currentPage: 1,
            pageSize: size,
          }))
        }
        showPageSizeView={newRequest?.totalRecords > 5 ? true : false}
      />
    </>
  );
};

export default NewReq;
