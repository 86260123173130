import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { Button, Modal } from "react-bootstrap";
import AddEvent from "./AddEvent";
import EditEvent from "./EditEvent";
import { GetDailyEvent, DeleteDailyEvent } from "../../action/contest";
import moment from "moment";
import showNotification from "../../components/notification/showNotification";

export const CalendarEvent = () => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [editModel, setEditModel] = useState(false);
    const [isOpenCanvas, setIsOpenCanvas] = useState(false);
    const [events, setEvents] = useState([]);
    const [showDeleteModel, setShowDeleteModel] = useState(false);
    const [eventId, setEventId] = useState(null);

    const fetchEventsForDate = async (date) => {
        const formattedDate = moment(date).format("YYYY-MM-DD");
        try {
            const response = await GetDailyEvent(1, 10);
            if (response.status === 1) {
                setEvents(response.data.list.filter(event => moment(event.date).format("YYYY-MM-DD") === formattedDate));
            }
        } catch (error) {
            console.error("Failed to fetch events:", error);
        }
    };

    useEffect(() => {
        fetchEventsForDate(selectedDate);
    }, [selectedDate]);

    const handleEditEvent = (event) => {
        setSelectedEvent(event); 
        setEditModel(true);
    };

    const handleDelete = async () => {
        try {
            const res = await DeleteDailyEvent(eventId);
            if (res.status) {
                showNotification("Daily Post", res.message, "success");
                setShowDeleteModel(false);
                fetchEventsForDate(selectedDate);
            }
        } catch (error) {
            console.error("Failed to delete event", error);
        }
    };

    return (
        <div className="payout_data-content">
            <div className="payout_inner_content">
                <div className="payout_data-head">
                    <div className="payout_data-head-top">
                        <div className="payout_data-left">
                            <div className="payout_data_ttl">
                                <Breadcrumb
                                    paths={[
                                        { name: `Dashboard`, link: `/` },
                                        { name: "Calendar Event", link: `/calendar-event` },
                                    ]}
                                />
                            </div>
                        </div>
                        <Button onClick={() => setIsOpenCanvas(true)}>
                            Add Event
                        </Button>
                    </div>
                </div>

                <div className="calendar-event-container">
                    {/* Left: Calendar */}
                    <div className="left_container">
                        <Calendar onChange={setSelectedDate} value={selectedDate} />
                    </div>

                    {/* Right: Event List */}
                    <div className="right_container">
                        <h2 className="event_date">Events on {selectedDate.toDateString()}</h2>
                        {events.length > 0 ? (
                            <ul className="calendar-event-list">
                                {events.map((event) => (
                                    <li key={event._id} className="event-card">
                                        <div className="card-header">
                                            <h3>{event.objective}</h3>
                                            <p>{event.objective_img_prompt}</p>
                                            <p><strong>Date:</strong> {new Date(event.date).toLocaleDateString()}</p>
                                        </div>
                                        <div className="card-actions">
                                            <button className="btn-success" onClick={() => handleEditEvent(event)}>
                                                Edit
                                            </button>
                                            <button className="btn-danger" onClick={() => {
                                                setShowDeleteModel(true);
                                                setEventId(event._id);
                                            }}>
                                                Delete
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p className="event_txt">No events for this date. Add one!</p>
                        )}
                    </div>
                </div>

                {/* Add & Edit Event Components */}
                <AddEvent {...{ isOpenCanvas, setIsOpenCanvas, selectedDate, setSelectedDate, fetchEventsForDate }} />
                <EditEvent {...{ editModel, setEditModel, selectedEvent, fetchEventsForDate, selectedDate }} />

                {/* Delete Confirmation Modal */}
                <Modal show={showDeleteModel} onHide={() => setShowDeleteModel(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Event</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this Event?</Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-dark" onClick={() => setShowDeleteModel(false)}>No</button>
                        <button className="btn btn-success" onClick={handleDelete}>Yes</button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
};
