import { requestWithToken } from "../helper/helper";

const addWarehouseBid = async (payload) => {
  try {
    const response = await requestWithToken("POST", `/bidWarehouse`, payload);

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchWarehouseBidList = async (currentPage, perPage, status, id, search) => {
  try {
    const queryParams = [];
    if (currentPage) queryParams.push(`pageNo=${currentPage}`);
    if (perPage) queryParams.push(`pageSize=${perPage}`);
    if (id) queryParams.push(`id=${id}`);
    if (status) queryParams.push(`status=${status}`);
    if (search) queryParams.push(`search=${search}`);
    // Join the query parameters
    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const response = await requestWithToken(
      "GET",
      // `/buyerRequest/list/en${queryString}`
      `/bidWarehouse/list${queryString}`

    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }


  // try {
  //   const response = await requestWithToken(
  //     "GET",
  //     `/bidWarehouse/list?pageSize=${perPage}&pageNo=${currentPage}&id=${id}&status=${status}`
  //   );

  //   return response.data;
  // } catch (err) {
  //   return err.response.data;
  // }
};

const fetchWarehouseBids = async (currentPage, perPage, status, search) => {
  try {
    // const response = await requestWithToken(
    //   "GET",
    //   `/bidWarehouse/list?pageSize=${perPage}&pageNo=${currentPage}&status=${status}`
    // );

    const queryParams = [];
    if (currentPage) queryParams.push(`pageNo=${currentPage}`);
    if (perPage) queryParams.push(`pageSize=${perPage}`);
    if (status) queryParams.push(`status=${status}`);
    if (search) queryParams.push(`search=${search}`);
    // Join the query parameters
    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const response = await requestWithToken(
      "GET",
      // `/buyerRequest/list/en${queryString}`
      `/bidWarehouse/list${queryString}`

    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const acceptBid = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/buyerRequest/bid/${id}`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const createWarehouseRequest = async (payload) => {
  try {
    const response = await requestWithToken(
      "POST",
      `/warehouseRequest`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchWarehouseRequestList = async (currentPage, perPage, payload, search) => {
  try {

    const queryParams = [];
    if (perPage) queryParams.push(`pageSize=${perPage}`);
    if (currentPage) queryParams.push(`pageNo=${currentPage}`);
    // if (status) queryParams.push(`status=${status}`);
    if (search) queryParams.push(`search=${search}`);
    // Join the query parameters
    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
    const response = await requestWithToken(
      "POST",
      `/warehouseRequest/list/en${queryString}`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchFarmertList = async (currentPage, perPage, id, status, search) => {
  try {
    const queryParams = [];
    if (perPage) queryParams.push(`pageSize=${perPage}`);
    if (currentPage) queryParams.push(`pageNo=${currentPage}`);
    if (status) queryParams.push(`status=${status}`);
    if (id) queryParams.push(`id=${id}`);
    if (search) queryParams.push(`search=${search}`);
    // Join the query parameters
    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";
    const response = await requestWithToken(
      "GET",
      `/farmerBid/list${queryString}`
    );

    return response.data;
  } catch (err) {
    return err?.response.data;
  }
};

const editReuqest = async (payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/warehouseRequest/request`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const editWarehouseBid = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/warehouseRequest/bid/${id}`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getBidFarmers = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/warehouseRequest/acceptBid/${id}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const postCompleteBid = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/warehouseRequest/complete/${id}`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getFarmerBidDetails = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/farmerBid/details/en/${id}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const changeWarhouseBidStatus = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/bidWarehouse/status/${id}`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getWarehouseBidDetails = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/bidWarehouse/details/${id}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const fetchwarehousePendingRequestList = async (pageNo, pageSize, search) => {
  try {
    const queryParams = [];

    if (pageNo) queryParams.push(`pageNo=${pageNo}`);
    if (pageSize) queryParams.push(`pageSize=${pageSize}`);
    if (search) queryParams.push(`search=${search}`);

    // Join the query parameters
    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const response = await requestWithToken(
      "GET",
      `/bidWarehouse/list/new/en${queryString}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const fetchrequestUpdated = async (id) => {
  try {
    const response = await requestWithToken(
      "PUT",
      // `/bidWarehouse/list/new/en${queryString}`
      `/bidWarehouse/hasQuantity/${id}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  addWarehouseBid,
  fetchWarehouseBidList,
  acceptBid,
  createWarehouseRequest,
  fetchWarehouseRequestList,
  fetchFarmertList,
  editReuqest,
  fetchWarehouseBids,
  editWarehouseBid,
  getBidFarmers,
  postCompleteBid,
  getFarmerBidDetails,
  changeWarhouseBidStatus,
  getWarehouseBidDetails,
  fetchwarehousePendingRequestList,
  fetchrequestUpdated
};
