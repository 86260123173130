import React from 'react'
import searchIcon from "../assets/images/search-lg.svg"
export const SearchBox = ({ search, setSearch, label }) => {
    return (
        // <div className='search_container'>
        <div className="input-box mt-0">
            {label == "label" ? <div className="input-label">Search</div> : <></>}
            <div className="input_field">
                <input
                    type="text"
                    className="input"
                    placeholder="Type any keyword"
                    id="beneficiary"
                    name="search"
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                <div className="build_search">
                    <img src={searchIcon} alt="Search Icon" />
                </div>
            </div>
        </div>
        // </div>
    )
}
