import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import searchIcon from "../../assets/images/search-lg.svg";
import filerIcon from "../../assets/images/Up_Down.svg";

import { fetchGaon } from "../../action/gaonApi";
import Pagination from "../../components/pagination/paginationView";
import { TabPanel, Tabs } from "react-tabs";
import EditIcon from "../../assets/images/edit_btn.svg";
import viewIcon from "../../assets/images/maskIcon.svg";
import { Loader } from "../../components/loader";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import {
  fetchDistricts,
  fetchStateS,
  fetchStates,
  fetchSubDistricts,
} from "../../action/location";
import { gaonTextBasedOnRoleTYpe } from "../../helper/constants";

const Gaon = () => {
  const [gaonList, setGaonList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [stateId, setStateId] = useState();
  const [districtId, setDistrictId] = useState("");
  const [blockId, setBlockId] = useState("");
  const fetchGaonList = async (searchValue = "") => {
    setTotalData(0);
    setIsLoading(true);
    const response = await fetchGaon(
      pageNo,
      pageSize,
      searchValue,
      sort,
      order,
      stateId,
      districtId,
      blockId
    );
    if (response.status) {
      setGaonList(response?.data?.list);
      setTotalData(response?.data?.totalRecords);
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setSearch("");
    setStateId("");
    setDistrictId("");
    setBlockId("");

    // Apply timeout before fetching Gaon List
  };

  useEffect(() => {
    fetchGaonList(search);
  }, [stateId, districtId, blockId]);
  const getState = async () => {
    const res = await fetchStateS();
    if (res.status) {
      setStates(res.data);
    }
  };

  const getDistrict = async () => {
    const res = await fetchDistricts(stateId);
    if (res.status) {
      setDistricts(res.data);
    }
  };

  const getSubDistrict = async () => {
    const res = await fetchSubDistricts(districtId);
    if (res.status) {
      setBlocks(res.data);
    }
  };

  useEffect(() => {
    fetchGaonList(search);
  }, [pageNo, pageSize, sort, order, search]);

  useEffect(() => {
    getState();
  }, []);
  useEffect(() => {
    getDistrict();
  }, [stateId]);

  useEffect(() => {
    getSubDistrict();
  }, [districtId]);

  return (
    <>
      <div className="payout_inner_content">
        <div className="payout_data-content tablePage_content_header">
          {/* <div className="payout_data-head">
            <div className="payout_data-head-top">
              <div className="payout_data-left">
                <div className="payout_data_ttl">
                  <Breadcrumb paths={[{ name: "Gaon", link: "/gaon" }]} />
                </div>
              </div>
            </div>
          </div> */}

          <div className="row">
            {/* <div className="col-lg-3">
              <div className="input-box mt-0">
                <div className="input-label">Search</div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    placeholder="Type any keyword"
                    id="beneficiary"
                    name="search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                  <div className="build_search">
                    <img
                      src={searchIcon}
                      alt="Search Icon"
                    />
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-lg-3">
              <div className="input-box mt-0">
                <div className="input-label">State</div>
                <select
                  className="custom_dropdn"
                  onChange={(e) => {
                    setStateId(e.target.value);
                    // getDistrict();
                  }}
                  value={stateId}
                >
                  <option>Select State</option>
                  {states.map((state) => (
                    <option key={state._id} value={state._id}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="input-box mt-0">
                <div className="input-label">District</div>
                <select
                  className="custom_dropdn"
                  onChange={(e) => {
                    setDistrictId(e.target.value);
                  }}
                  value={districtId}
                >
                  <option>Select District</option>
                  {districts.map((district) => (
                    <option key={district._id} value={district._id}>
                      {district.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="input-box mt-0">
                <div className="input-label">Sub District</div>
                <select
                  className="custom_dropdn"
                  onChange={(e) => {
                    setBlockId(e.target.value);
                  }}
                  value={blockId}
                >
                  <option value="">Select Sub District</option>
                  {blocks.map((block) => (
                    <option key={block._id} value={block._id}>
                      {block.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="input-box mt-0">
                <div className="input-label">Search</div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    placeholder="Type any keyword"
                    id="beneficiary"
                    name="search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    disabled={!stateId}
                    title={!stateId ? "please select your state" : "Search your gaon"}
                  />
                  <div className="build_search">
                    <img
                      src={searchIcon}
                      alt="Search Icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="submit_btn_grp">
            <button
              className="btn_submit btn btn-dark"
              type="reset"
              onClick={() => {
                setBlockId("");
                handleReset();
              }}
              disabled={!search && !blockId && !stateId && !districtId}
            >
              Reset
            </button>
            {/* <button
              className={`btn btn-success btn_submit ${search ? "active" : ""}`}
              type="submit"
              disabled={!search && !blockId}
              onClick={() => {
                fetchGaonList();
              }}
            >
              Search
            </button> */}
          </div>
        </div>
        <div className="payout_data-head">
          <div className="payout_data-head-top">
            <div className="payout_data-left">
              <div className="payout_data_ttl1">
                {gaonTextBasedOnRoleTYpe()}
              </div>
            </div>
          </div>
        </div>
        <div className="tab_box">
          <Tabs>
            <TabPanel>
              <div className="">
                <table className="payout_table upi-mandate_table">
                  <thead>
                    <tr className="tableHead">

                      <th className="thHeader">
                        <div className="payoutHead hndIcon">State</div>
                      </th>
                      <th className="thHeader">
                        <div className="payoutHead hndIcon">District</div>
                      </th>
                      <th className="thHeader">
                        <div className="payoutHead hndIcon">Block</div>
                      </th>
                      <th className="thHeader">
                        <div className="payoutHead hndIcon">
                          Gaon
                          <img
                            src={filerIcon}
                            onClick={() => {
                              setSort("name");
                              setOrder(order == 1 ? -1 : 1);
                            }}
                            alt="filter"
                          />
                        </div>
                      </th>
                      <th className="thHeader">
                        <div className="payoutHead hndIcon">Action</div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {stateId?.length ? <>
                      {isLoading ? (
                        <tr>
                          <td colSpan="5">
                            <Loader />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {gaonList?.length > 0 ? (
                            gaonList?.map((item, i) => (
                              <tr key={i}>
                                <td>{item?.stateName}</td>
                                <td>{item?.districtName}</td>
                                <td>{item?.blockName}</td>
                                <td>{item?.gaonName}</td>
                                <td>
                                  <div className="flex_btn">
                                    <Link
                                      to={`/gaon/details/${item?._id}`}
                                      style={{ marginRight: "4px" }}
                                    >
                                      <img
                                        src={viewIcon}
                                        alt="view"
                                        className="eye_icon"
                                      />
                                    </Link>

                                    <Link to={`/gaon/edit/${item?._id}`}>
                                      <img
                                        src={EditIcon}
                                        alt="Edit"
                                        className="edit_icon"
                                      />
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="5" className="text_center">
                                No data available
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </> : <tr><td colSpan={5}> <div className="selectState">Please Select Your State</div></td></tr>}
                  </tbody>
                </table>
              </div>
            </TabPanel>
          </Tabs>
          {gaonList?.length > 0 ? <Pagination
            currentPage={pageNo}
            totalCount={totalData}
            pageSize={pageSize}
            onPageChange={(page) => setPageNo(page)}
            changePageSize={(page) => {
              setPageSize(page);
              setPageNo(1);
            }}
          /> : ""}
        </div>
      </div>
    </>
  );
};

export default Gaon;
