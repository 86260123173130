import { requestWithToken, requestWithoutToken } from "../helper/helper";
import {
  AUTH_USER,
  AUTH_USER_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
} from "../action/types";

// Authenticate User
export const authUser = () => async (dispatch) => {
  try {
    const response = await requestWithToken("GET", "/user/login");

    return response.data;
  } catch (err) {
    // dispatch(error(err));
    dispatch({ type: AUTH_USER_FAIL });

    return err.response.data;
  }
};

// Login User
export const login = async (mobile, otp) => {
  try {
    let body = { mobile, otp };
    const response = await requestWithoutToken("POST", "/user/webLogin", body);

    return response.data;
  } catch (err) {
    return err.response;
  }
};

// Login User
export const sendOTP = async (body) => {
  try {
    const response = await requestWithoutToken(
      "POST",
      "/user/sendLoginOtp",
      body
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

// Logout User
export const logout = () => async (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};
