import axios from "axios";
import moment from "moment";
import { Buffer } from "buffer";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import { write, utils } from "xlsx";

export const requestWithoutToken = async (method, url, body) => {
  let request;
  url = process.env.REACT_APP_API_G_CONSOLE_ENDPOINT + url;

  switch (method) {
    case "GET":
      request = await axios.get(url);
      break;
    case "POST":
      request = await axios.post(url, body);
      break;
    default:
      break;
  }

  return request;
};

export const requestWithToken = async (method, url, body) => {
  let request;
  url = process.env.REACT_APP_API_G_CONSOLE_ENDPOINT + url;

  const headers = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access-token")}`,
    },
  };

  switch (method) {
    case "GET":
      request = await axios.get(url, headers);
      break;
    case "POST":
      request = await axios.post(url, body, headers);
      break;
    case "PUT":
      request = await axios.put(url, body, headers);
      break;
    case "DELETE":
      request = await axios.delete(url, {
        data: body,
        headers: headers.headers,
      });
      break;

    default:
      break;
  }

  return request;
};

export const middleware = (error) => {
  if (error.response && error.response.status === 401) {
    localStorage.removeItem("fstacSecretKey");
    localStorage.removeItem("fstacMid");
    localStorage.removeItem("fstacData");
    localStorage.removeItem("fstacAuthToken");
    window.location.href = "/login";
    return null;
  }
  return Promise.reject(error);
};

function checkLogout(request) {
  const storedLoginTime = localStorage.getItem("loginTime");
  if (storedLoginTime == undefined || storedLoginTime == null) {
    logout();
  }
  if (storedLoginTime) {
    const loginTime = new Date(parseInt(storedLoginTime)).getTime();
    const currentTime = new Date().getTime();
    // console.log(loginTime, currentTime);
    const diffMs = currentTime - loginTime;

    if (diffMs > 86400000) {
      logout();
      return null;
    }
  }
  return request;
}

function logout() {
  localStorage.removeItem("auth-token");
  window.location.href = "/login";
}

export const hasNotch = () => {
  /**
   * For storybook test
   */
  const storybook = window.location !== window.parent.location;
  const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
  const aspect = window.screen.width / window.screen.height;
  const aspectFrame = window.innerWidth / window.innerHeight;
  return (
    (iPhone && aspect.toFixed(3) === "0.462") ||
    (storybook && aspectFrame.toFixed(3) === "0.462")
  );
};

export const handleKeyDown = (event) => {
  if (event.type === "keydown") {
    if (
      (event.key >= "0" && event.key <= "9") ||
      event.key === "Backspace" ||
      event.key === "Delete" ||
      event.key === "ArrowLeft" ||
      event.key === "ArrowRight" ||
      event.key === "Home" ||
      event.key === "End" ||
      event.key === "Tab"
    ) {
      // Allow the key
    } else {
      // Prevent the key press for all other keys
      event.preventDefault();
    }
  }
};

export const handleTitle = () => {
  const role = localStorage.getItem("role");
  const title =
    role == "BUYER"
      ? "Buyer"
      : role == "EXPORTER"
        ? "Exporter"
        : role == "MANUFACTURER"
          ? "Manufacturer"
          : "Buyer";

  return title;
};

export const handleBuyerTitle = () => {
  const roleType = localStorage.getItem("roleType");
  // const title = roleType == "GAON" ? "Buyer" : "Exporter/Retailer/Manufacturer";
  const title = "Buyer";
  return title;
};

export const handleCurrency = () => {
  const roleType = localStorage.getItem("roleType");
  const role = localStorage.getItem("role");
  const title = roleType == "FARMLINK" ? "GYD" : "INR";

  return title;
};

export function getDashboardDateRange(option) {
  const today = new Date();
  let startDate = new Date(today);
  let endDate = new Date(today);

  switch (option.toLowerCase()) {
    case "today":
      // startDate and endDate both are today
      break;

    case "week":
      // Start from the beginning of the week (assuming Sunday as the start day)
      startDate.setDate(today.getDate() - today.getDay());
      endDate.setDate(startDate.getDate() + 6);
      break;

    case "month":
      // Start from the beginning of the month
      startDate.setDate(1);
      // Set to the last day of the month
      endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      break;

    case "year":
      // Start from January 1st
      startDate = new Date(today.getFullYear(), 0, 1);
      // Set to December 31st
      endDate = new Date(today.getFullYear(), 11, 31);
      break;

    default:
      throw new Error(
        "Invalid option. Use 'today', 'week', 'month', or 'year'."
      );
  }

  return {
    startDate: moment(startDate).format("YYYY-MM-DD"),
    endDate: moment(endDate).format("YYYY-MM-DD"),
  };
}

export function sumByKey(arr, key) {
  return arr.reduce((sum, obj) => sum + (obj[key] || 0), 0);
}

export function formatAmount(amount) {
  if (amount >= 1e7) {
    // Crores
    return (amount / 1e7).toFixed(2) + " Cr";
  } else if (amount >= 1e5) {
    // Lakhs
    return (amount / 1e5).toFixed(2) + " L";
  } else if (amount >= 1e3) {
    // Thousands
    return (amount / 1e3).toFixed(2) + " K";
  }
  return amount.toString();
}

export function formatQuantity(quantity) {
  if (quantity >= 1e3) {
    // Tons
    return (quantity / 1e3).toFixed(2) + " tons";
  } else if (quantity >= 1e2) {
    // Quintals
    return (quantity / 1e2).toFixed(2) + " quintals";
  }
  return quantity + " kg";
}

export function getRange(value) {
  let range = 10;

  // Double or multiply by 5 to get the next range until it meets or exceeds the value
  while (range < value) {
    if (range < 100) {
      range *= 2; // Use a smaller multiplier for smaller ranges
    } else {
      range *= 5; // Use a larger multiplier for larger ranges
    }
  }

  return range;
}
export function getEnumIndexByName(enumObject, name) {
  const entries = Object.entries(enumObject);
  for (let i = 0; i < entries.length; i++) {
    if (entries[i][0] === name) {
      return i; // Return the index of the enum
    }
  }
  return -1; // Return -1 if the name is not found
}

export function getEnumIndexByValue(enumObject, value) {
  const entries = Object.entries(enumObject);
  for (let i = 0; i < entries.length; i++) {
    if (entries[i][1] === value) {
      // Compare against the value
      return i; // Return the index of the enum
    }
  }
  return -1; // Return -1 if the value is not found
}

export function getKeyByValue(obj, value) {
  return Object.keys(obj).find((key) => obj[key] === value) || null;
}

export const base64 = (data) => {
  const buffer = Buffer.from(JSON.stringify(data), "utf-8");
  return buffer.toString("base64");
};

export const formatCurrentDate = (currentDate) => {
  // Extract the year, month, and day
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  // Combine year, month, and day into the desired format "YYYY-MM-DD"
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};
export const formatDateTime = (isoDateTimeString) => {
  //
  const date = new Date(isoDateTimeString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear() % 100;
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Format the date and time according to the desired format
  const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}`;

  return formattedDateTime;
};
export const formatDateTable = (dateString) => {
  const date = new Date(dateString);

  // Array of day names
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Array of month names
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get day, month, and time components
  const dayOfWeek = days[date.getDay()];
  const day = date.getDate();
  const month = months[date.getMonth()];
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Convert hours to 12-hour format and determine AM/PM
  const period = hours < 12 ? "AM" : "PM";
  const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format

  // Add leading zero to minutes if needed
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

  // Construct formatted date string
  const formattedDate = `${dayOfWeek} ${day} ${month}, ${formattedHours}:${formattedMinutes} ${period}`;

  return formattedDate;
};

export const formatCurrentDateSlash = (currentDate) => {
  // Extract the year, month, and day
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  // Combine year, month, and day into the desired format "YYYY-MM-DD"
  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
};

export const validatePayment = (paymentData) => {
  const {
    beneficiary_type,
    payment_mode,
    account_number,
    ifsc,
    upi_handle,
    amount,
    beneficiary_name,
  } = paymentData;
  const errors = {};

  if (!beneficiary_type) {
    errors.beneficiary_type = "UPI and account number";
  } else if (
    beneficiary_type !== "upi" &&
    beneficiary_type !== "bank_account"
  ) {
    errors.beneficiary_type = " UPI and account number";
  }

  if (!amount || parseFloat(amount) <= 0) {
    errors.amount = "Amount is required and must be greater than 0";
  }

  if (beneficiary_type === "upi") {
    if (!upi_handle) {
      errors.upi_handle = "UPI handle is required for UPI payment";
    } else {
      if (beneficiary_type === "upi" && upi_handle) {
        const emailRegex = /^[a-zA-Z0-9.-]{2,256}@[a-zA-Z]{2,64}$/;
        if (!emailRegex.test(upi_handle)) {
          errors.upi_handle = "Invalid UPI ID format";
        }
      }
    }

    if (payment_mode !== "UPI") {
      errors.payment_mode = "Payment Should be UPI only For UPI payment";
    }

    // Check for beneficiary name
    if (!beneficiary_name || beneficiary_name.trim() === "") {
      errors.beneficiary_name = "Beneficiary name is required for UPI payment";
    }
  } else if (beneficiary_type === "bank_account") {
    // Check for account number
    if (!account_number) {
      errors.account_number =
        "Account number is required for bank account payment";
    } else {
      // Simple validation for illustration purposes
      //   const accountNumberRegex = /^\d{9,18}$/; // Assuming account number length between 9 and 18 digits
      //   if (!accountNumberRegex.test(account_number)) {
      //     errors.account_number = "Invalid bank account number format";
      // }
    }
    // Check for IFSC code
    if (!ifsc) {
      errors.ifsc = "IFSC code is required for account number payment";
    } else {
      // Use the regular expression to validate IFSC code
      const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
      if (!ifscRegex.test(ifsc)) {
        errors.ifsc = "Invalid IFSC code format";
      }
    }
    if (["NEFT", "RTGS", "IMPS"].indexOf(payment_mode) === -1) {
      errors.payment_mode = "Payment mode should be NEFT, RTGS, or IMPS only";
    }
    if (!payment_mode) {
      errors.payment_mode = "Payment mode should be NEFT, RTGS, or IMPS only";
    }

    // Check for beneficiary name
    if (!beneficiary_name || beneficiary_name.trim() === "") {
      errors.beneficiary_name =
        "Beneficiary name is required for account number payment";
    }
  }

  return errors;
};

export const validatePaymentsArray = (paymentsArray) => {
  const results = [];
  for (const paymentData of paymentsArray) {
    const validationErrors = validatePayment(paymentData);
    if (Object.keys(validationErrors).length > 0) {
      results.push({
        paymentData,
        errors: validationErrors,
      });
    }
  }
  return results;
};

export const downloadCsv = () => {
  const csvHeaders = ["Name", "Age", "Email", "Country"];

  // Define a sample data row (optional)
  const sampleDataRow = ["John Doe", "30", "john@example.com", "USA"];

  // Convert headers and sample data to CSV format
  const csvData = [
    csvHeaders.join(","), // Join headers with a comma
    sampleDataRow.join(","), // Join sample data row with a comma
  ].join("\n"); // Join each row with a newline character

  // Create a Blob from the CSV data
  const csvBlob = new Blob([csvData], { type: "text/csv" });

  // Create a URL for the Blob
  const csvUrl = URL.createObjectURL(csvBlob);
  return csvUrl;
};

export const downloadCSVTemplate = () => {
  const fixedHeader =
    "beneficiary_type,payment_mode,account_number,ifsc,upi_handle,amount,beneficiary_name";
  const blob = new Blob([fixedHeader], { type: "text/csv" });

  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "csv_template.csv");
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const downloadCSVFile = (data, fileName) => {
  const convertToCSV = (data) => {
    const headers = Object.keys(data[0]);
    const csvRows = [];

    csvRows.push(headers.join(","));

    for (const row of data) {
      const values = headers.map((header) => {
        const cell = row[header];
        return `"${cell}"`;
      });
      csvRows.push(values.join(","));
    }

    return csvRows.join("\n");
  };

  const csvData = convertToCSV(data);

  const csvBlob = new Blob([csvData], { type: "text/csv" });

  const downloadLink = document.createElement("a");
  downloadLink.href = URL.createObjectURL(csvBlob);
  downloadLink.download = fileName || "download.csv";

  document.body.appendChild(downloadLink);
  downloadLink.click();

  document.body.removeChild(downloadLink);
};

export const downloadFile = (data, fileName, format) => {
  const convertToCSV = (data) => {
    const headers = Object.keys(data[0]);
    const csvRows = [];

    csvRows.push(headers.join(","));

    for (const row of data) {
      const values = headers.map((header) => {
        const cell = row[header];
        return `"${cell}"`;
      });
      csvRows.push(values.join(","));
    }

    return csvRows.join("\n");
  };

  const convertToXLSX = (data) => {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    return workbook;
  };

  if (format === "csv") {
    const fileData = new Blob([convertToCSV(data)], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(fileData);
    downloadLink.download = fileName || "download.csv";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  } else if (format === "xlsx") {
    const workbook = convertToXLSX(data);
    const xlsxData = new Blob(
      [write(workbook, { bookType: "xlsx", type: "array" })],
      {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }
    );
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(xlsxData);
    downloadLink.download = fileName || "download.xlsx";

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  } else {
    throw new Error(
      'Unsupported format. Please specify either "csv" or "xlsx".'
    );
  }
};

export const createTemplate = async () => {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet("Sheet1");

  const dropdownOptions = {
    A1: ["Option 1", "Option 2", "Option 3"],
    B2: ["Option A", "Option B", "Option C"],
    C3: ["Red", "Green", "Blue"],
    // Add more cells and dropdown options as needed
  };

  // Set up the data validation and cell styling for each cell
  Object.keys(dropdownOptions).forEach((cellAddress) => {
    const options = dropdownOptions[cellAddress];
    const validation = {
      type: "list",
      allowBlank: true,
      formula1: `"${options.join(",")}"`,
    };
    const cell = worksheet.getCell(cellAddress);
    cell.dataValidation = validation;
    // Set cell font color and background color
    cell.font = { color: { argb: "000000" } }; // Black font color
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFFFFF" },
    }; // White background color
  });

  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), "template.xlsx");
};

// export const getDateRange = (type) => {
//   const today = new Date();
//   let startDate, endDate;

//   switch (type) {
//     case "Today":
//       startDate = new Date(today);
//       endDate = new Date(today);
//       break;
//     case "Yesterday":
//       startDate = new Date(today);
//       startDate.setDate(today.getDate() - 1);
//       endDate = new Date(startDate);
//       break;
//     case "This Week":
//       startDate = new Date(today);
//       startDate.setDate(today.getDate() - today.getDay()); // Sunday of the current week
//       endDate = new Date(today);
//       endDate.setDate(today.getDate() + (6 - today.getDay())); // Saturday of the current week
//       break;
//     case "This Month":
//       startDate = new Date(today.getFullYear(), today.getMonth(), 1);
//       endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
//       break;
//     default:
//       throw new Error("Invalid date range type");
//   }

//   // Format dates if needed (e.g., to ISO string)
//   const startDateFormatted = startDate.toISOString().slice(0, 10); // "yyyy-mm-dd"
//   const endDateFormatted = endDate.toISOString().slice(0, 10); // "yyyy-mm-dd"

//   return { startDate: startDateFormatted, endDate: endDateFormatted };
// };

export const getDateRange = (type) => {
  const today = new Date();
  let startDate, endDate;

  switch (type) {
    case "Today":
      startDate = new Date(today);
      endDate = new Date(today);
      break;
    case "Yesterday":
      startDate = new Date(today);
      startDate.setDate(today.getDate() - 1);
      endDate = new Date(today);
      endDate.setDate(today.getDate() - 1); // Yesterday's end date is also yesterday
      break;
    case "This Week":
      startDate = new Date(today);
      startDate.setDate(today.getDate() - today.getDay()); // Sunday of the current week
      endDate = new Date(today);
      break;
    case "This Month":
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = new Date(today);
      break;
    default:
      throw new Error("Invalid date range type");
  }

  // Format dates if needed (e.g., to ISO string)
  const startDateFormatted = startDate.toISOString().slice(0, 10); // "yyyy-mm-dd"
  const endDateFormatted = endDate.toISOString().slice(0, 10); // "yyyy-mm-dd"

  return { startDate: startDateFormatted, endDate: endDateFormatted };
};

export function convertToDatetimeWithTimezone(date, type, timezoneOffset = 330) { // timezoneOffset 330 for IST timezone
  const parsedDate = new Date(date); // Parse the input date (yyyy-mm-dd format)

  // Adjust to the start or end of the day in the given time zone
  if (type === "start") {
    parsedDate.setUTCHours(0, 0, 0, 0); // Set to the start of the day in UTC
  } else if (type === "end") {
    parsedDate.setUTCHours(23, 59, 59, 999); // Set to the end of the day in UTC
  } else {
    throw new Error("Invalid type. Use 'start' or 'end'.");
  }

  // Adjust for the time zone offset (in minutes)
  const adjustedDate = new Date(parsedDate.getTime() - timezoneOffset * 60 * 1000);

  return date; // roleback changes due to some urgent work as per sanket
}