import React, { useState, useEffect } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { getComplaintsHistoryList } from "../../action/complaints";
import Pagination from "../../components/pagination/paginationView";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { Loader } from "../../components/loader";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { complaintStatusEnum } from "../../enumTypes/enums";
import { Button, Modal } from "react-bootstrap";

const ComplaintHistoryList = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showImage, setShowImage] = useState(null);
  const [complaintList, setComplaintList] = useState({
    loading: false,
    data: [],
    totalRecords: 0,
  });
  const getComplaintList = async (parentId) => {
    setComplaintList((k) => ({
      ...k,
      loading: true,
    }));
    const response = await getComplaintsHistoryList(pageSize, pageNo, parentId);
    if (response.status) {
      setComplaintList((k) => ({
        ...k,
        loading: false,
        data: response.data.list,
        totalRecords: response.data.count,
      }));
    }
  };
  // getKeyByValue(complaintStatusEnum, status)
  const handleUpdateStatus = (id, handler) => {
    handler((k) => ({
      ...k,
      showModel: true,
      id,
    }));
  };

  useEffect(() => {
    getComplaintList(params.parentId);
  }, [pageNo, pageSize, params.parentId]);

  return (
    <div className="payout_data-content" style={{ height: "100%" }}>
      <div className="payout_data-head">
        <div className="payout_data-head-top mb-2">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              {" "}
              <Breadcrumb
                paths={[{ name: "Complaints" }, { name: "History" }]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="tab_box">
        <table className="payout_table upi-mandate_table">
          <thead>
            <tr className="tableHead">
              <th className="thHeader">
                <div className="payoutHead hndIcon">Ticket ID</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead hndIcon">Department</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead hndIcon">Complaint Title</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead hndIcon">Created Date</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead hndIcon">Attachment</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead hndIcon">Remarks</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead hndIcon">Status</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {complaintList.loading ? (
              <tr>
                <td colSpan="6" className="text-center">
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {complaintList.data?.length > 0 ? (
                  complaintList.data?.map((item) => {
                    return (
                      <tr key={item._id}>
                        <td>{item?.ticket}</td>
                        <td>{item?.departmentDetails?.name}</td>
                        <td>{item?.feed?.title?.en || "-"}</td>
                        <td>
                          {item?.createdAt
                            ? moment(item.createdAt).format("DD MMM YYYY")
                            : "-"}
                        </td>
                        <td>
                          {item?.feed?.assets?.length
                            ? item?.feed?.assets.map((el, i) => {
                                return (
                                  <div key={`attach-${i}`}>
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                      }}
                                      onClick={() =>
                                        setShowImage(
                                          process.env.REACT_APP_S3_URL +
                                            "/" +
                                            el.url
                                        )
                                      }
                                    >
                                      File {i + 1}
                                    </span>
                                  </div>
                                );
                              })
                            : ""}
                        </td>
                        <td>{item.remark}</td>
                        <td>{complaintStatusEnum?.[item.status] || ""}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="6" className="text_center">
                      No data available
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
        {/* </Tabs> */}
        {complaintList.data?.length > 0 ? (
          <Pagination
            currentPage={pageNo}
            totalCount={complaintList.totalRecords}
            pageSize={pageSize}
            onPageChange={(page) => setPageNo(page)}
            changePageSize={(page) => {
              setPageSize(page);
              setPageNo(1);
            }}
          />
        ) : (
          <></>
        )}
      </div>
      <Modal
        show={showImage}
        centered
        onHide={() => {
          setShowImage(null);
        }}
      >
        <Modal.Body>
          <div
            className="input-box"
            style={{ maxHeight: "60vh", overflow: "hidden" }}
          >
            <img src={showImage} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={"btn btn-dark"}
            onClick={() => {
              setShowImage(null);
            }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ComplaintHistoryList;
