import { requestWithToken } from "../helper/helper";

const getCategoryData = async (pageNo, pageSize, id, search, order) => {
  try {
    const queryParams = [];

    if (pageNo) queryParams.push(`pageNo=${pageNo}`);
    if (pageSize) queryParams.push(`pageSize=${pageSize}`);
    if (id) queryParams.push(`parentId=${id}`);
    if (order) queryParams.push(`order=${order}`);
    if (search) queryParams.push(`search=${search}`);

    // Join the query parameters
    const queryString =
      queryParams.length > 0 ? `?${queryParams.join("&")}` : "";

    const response = await requestWithToken(
      "GET",
      `/admin/categoryList${queryString}`
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const getParentId = async (type) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/categories?type=${type}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const addCategoryData = async (payload) => {
  try {
    const response = await requestWithToken("POST", `/admin/category`, payload);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const deleteCategoryData = async (id) => {
  try {
    const response = await requestWithToken("DELETE", `/admin/category/${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const editCategory = async (id, payload) => {
  try {
    const response = await requestWithToken("PUT", `/category/${id}`, payload);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getCategoryDetails = async (id) => {
  try {
    const response = await requestWithToken("GET", `/category/details/${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getMSP = async (pageNo, pageSize, search) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/mspList?pageNo=${pageNo}&pageSize=${pageSize}&search=${search}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getProductMSP = async (id) => {
  try {
    const response = await requestWithToken("GET", `/admin/msp/product/${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getMSPDetails = async (id) => {
  try {
    const response = await requestWithToken("GET", `/admin/msp/${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const createMSP = async (payload) => {
  try {
    const response = await requestWithToken("POST", `/admin/msp`, payload);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const editMSP = async (id, payload) => {
  try {
    const response = await requestWithToken("PUT", `/admin/msp/${id}`, payload);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const deleteMSP = async (id) => {
  try {
    const response = await requestWithToken("DELETE", `/admin/msp/${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getProductList = async (pageNo, pageSize) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/category/list?pageNo=${pageNo}&pageSize=${pageSize}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getGradeList = async (pageNo, pageSize) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/gradeList?pageNo=${pageNo}&pageSize=${pageSize}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const addGrade = async (payload) => {
  try {
    const response = await requestWithToken("POST", `/admin/grade`, payload);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const editGrade = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/admin/grade/${id}`,
      payload
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const deleteGrade = async (id) => {
  try {
    const response = await requestWithToken("DELETE", `/admin/grade/${id}`);
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getGradeDetails = async (id) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/admin/grade/details/${id}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  getCategoryData,
  addCategoryData,
  deleteCategoryData,
  getParentId,
  getMSP,
  getMSPDetails,
  createMSP,
  editMSP,
  deleteMSP,
  getProductList,
  editCategory,
  getCategoryDetails,
  getProductMSP,
  getGradeList,
  getGradeDetails,
  addGrade,
  editGrade,
  deleteGrade,
};
