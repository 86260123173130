import React from "react";
import ReactECharts from "echarts-for-react";
import { formatAmount, formatQuantity } from "../../helper/helper";

export const QuanttityGraph = ({ data, unit }) => {
  const option = {
    tooltip: {
      trigger: "item",
      formatter: (params) => `${params.name}: ${formatQuantity(params.value)}`,
    },
    legend: {
      bottom: "0%",
      left: "center",
    },
    series: [
      {
        name: "",
        type: "pie",
        radius: ["45%", "70%"],
        avoidLabelOverlap: true,
        label: {
          show: false,
          position: "center",
          fontSize: 20,
          fontWeight: "bold",
          formatter: (params) => `${formatQuantity(params.value)}`,
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: "bold",
            formatter: (params) => `${formatQuantity(params.value)}`,
          },
        },
        labelLine: {
          show: false,
        },
        data,
      },
    ],
  };

  return (
    <ReactECharts option={option} style={{ height: 400, width: "100%" }} />
  );
};

export const AmountGraph = ({ data, unit }) => {
  const option = {
    tooltip: {
      trigger: "item",
      formatter: (params) =>
        `${params.name}: ${unit} ${formatAmount(params.value)}`,
    },
    legend: {
      bottom: "0%",
      left: "center",
    },
    series: [
      {
        name: "",
        type: "pie",
        radius: ["45%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
          fontSize: 20,
          fontWeight: "bold",
          formatter: (params) => `${unit} ${formatAmount(params.value)}`,
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: "bold",
            formatter: (params) => `${unit} ${formatAmount(params.value)}`,
          },
        },
        labelLine: {
          show: false,
        },
        data,
      },
    ],
  };

  return (
    <ReactECharts option={option} style={{ height: 400, width: "100%" }} />
  );
};
