import React, { useEffect, useState } from "react";
import { SideNav } from "./sideNav";
import { useLocation, useNavigate } from "react-router-dom";
import { getMe } from "../action/users";
import bellIcon from "../assets/images/bell.svg";

const Wrapper = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const role = localStorage.getItem("role");
  const [userData, setUserData] = useState({});
  const fetchMe = async () => {
    const response = await getMe();
    if (response.status) {
      setUserData(response?.data);
    }
  };

  useEffect(() => {
    fetchMe();
  }, [location.pathname]);

  return (
    <div className="payout_page">
      <div
        className="sidemenu-container"
        style={{ position: "relative", display: "inline-block" }}
      >
        <SideNav />
      </div>
      <div className="payout_dashboard">
        <div className="payout_content">
          <div className="notification_sec">
            {role !== "SUPERADMIN" && role !== "ADMIN" && (
              <div
                className="notification_icon"
                onClick={() => {
                  navigate("/notification/list");
                }}
              >
                <img
                  className="bell_icon"
                  src={bellIcon}
                  alt="bellIcon"
                  height="25px"
                  width="25px"
                />

                {userData?.notificationCount > 0 && (
                  <span className="">
                    {userData.notificationCount > 9
                      ? "9+"
                      : userData.notificationCount}
                  </span>
                )}
              </div>
            )}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Wrapper;
