import { requestWithToken } from "../helper/helper";

const getComplaintsList = async (pageSize, pageNo, status) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/complaints/list?pageNo=${pageNo}&pageSize=${pageSize}&status=${status}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

const getComplaintsHistoryList = async (pageSize, pageNo, parentId) => {
  try {
    const response = await requestWithToken(
      "GET",
      `/complaints/childList?pageNo=${pageNo}&pageSize=${pageSize}&parentId=${parentId}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};
const compaintStatusUpdate = async (id, payload) => {
  try {
    const response = await requestWithToken(
      "PUT",
      `/complaints/status/${id}`,
      payload
    );

    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export {
  getComplaintsList,
  compaintStatusUpdate,
  getComplaintsHistoryList
};
