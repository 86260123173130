import { useEffect, useState } from "react";
import { fetchBuyerPendingRequestList } from "../../action/buyer";
import { Loader } from "../loader";
import moment from "moment";
import { handleCurrency } from "../../helper/helper";
import { Link } from "react-router-dom";
import Pagination from "../../components/pagination/paginationView";

export const BuyerPendingRequest = ({ search, setSearch }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [pendingList, setPendingList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const fetchBuyerPendingList = async (searchValue = "") => {
    setIsLoading(true);
    const response = await fetchBuyerPendingRequestList(
      currentPage,
      paginationValue,
      "",
      "",
      searchValue
    );
    if (response.status) {
      setPendingList(response.data.list);
      setTotalRecords(response.data.count);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    console.log("fetchBuyerPendingList");
    fetchBuyerPendingList(search);
  }, [currentPage, paginationValue, search]);

  return (
    <>
      <div className="overflow_auto">
        <table className="payout_table upi-mandate_table">
          <thead>
            <tr className="tableHead">
              <th className="thHeader">
                <div className="payoutHead hndIcon">Order ID</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead hndIcon">Order Date</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead">Address</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead">Product</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead">Variety</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead">Grade</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead">MSP </div>
              </th>
              <th className="thHeader">
                <div className="payoutHead">Price </div>
              </th>
              <th className="thHeader">
                <div className="payoutHead">Quantity </div>
              </th>
              <th className="thHeader">
                <div className="payoutHead">Total Price </div>
              </th>
              <th className="thHeader">
                <div className="payoutHead">Delivery Date</div>
              </th>

              <th className="thHeader">
                <div className="payoutHead headerAction text-center">
                  Action{" "}
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="12">
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {pendingList.length > 0 ? (
                  pendingList.map((item) => {
                    return (
                      <tr key={item._id}>
                        <td className="noWrap">{item.orderId}</td>
                        <td className="phone_number noWrap">
                          {moment(item?.createdAt).format("DD-MM-YYYY")}
                        </td>
                        <td className="Status address_td">
                          {item?.location}
                          {/* <div className="noData"></div> */}
                        </td>
                        <td className="table_amount">{item?.product}</td>
                        <td nowrap className="table_amount noWrap">
                          {item?.veriety ?? "-"}
                        </td>
                        <td className="table_amount">{item?.grade}</td>
                        <td className="Status">
                          <div>
                            {item?.mspDetails?.minimum_selling_price ||
                              ""}
                            / {item?.mspDetails?.unit?.shortcode || ""}
                          </div>
                        </td>
                        <td className="Status bold_txt">
                          <div>
                            {handleCurrency()} {item?.price ?? 0}
                          </div>
                        </td>
                        <td nowrap className="Status noWrap bold_txt">
                          {item?.quantity} {item?.unit}
                        </td>
                        <td nowrap className="Status noWrap bold_txt ">
                          {handleCurrency()}{" "}
                          {(item?.price ?? 0) *
                            (item?.quantity ?? 0) *
                            (item?.conversion ?? 0)}
                        </td>
                        <td nowrap className="noWrap">
                          {moment(item?.deliveryDate).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td>
                          <div className="action_payment">
                            <Link
                              className={`flex_btn btn btn-primary ${!(item?.bidsCount > 0) ? "disabled" : ""
                                }`}
                              to={item?.bidsCount > 0 ? `/buyerRequest/bid/list/${item?._id}` : "#"}
                              onClick={(e) => {
                                if (!(item?.bidsCount > 0)) e.preventDefault();
                              }}
                            >
                              {item?.bidsCount > 0 ? (
                                <span>{item?.bidsCount} Bids</span>
                              ) : (
                                "No bids"
                              )}
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    <tr>
                      <td colspan="11" className="text_center">
                        No data available
                      </td>
                    </tr>
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      {pendingList.length > 0 ?
        <Pagination
          currentPage={currentPage}
          totalCount={totalRecords}
          pageSize={paginationValue}
          onPageChange={(page) => setCurrentPage(page)}
          changePageSize={(page) => {
            setPaginationValue(page);
            setCurrentPage(1);
          }}
        />
        : ""}
    </>
  );
};