import React, { useState, useEffect, useRef } from "react";
import infoBtn from "../../assets/images/info-circle.svg";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import moment from "moment";
import {
  editWarehouseBid,
  fetchFarmertList,
  postCompleteBid,
} from "../../action/warehouse";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import Modal from "react-bootstrap/Modal";
import { toast } from "react-hot-toast";
import { SingleErrorMessage } from "../singleErrorMessage";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { Loader } from "../../components/loader";
import Pagination from "../../components/pagination/paginationView";
import { handleCurrency } from "../../helper/helper";
import { Link } from "react-router-dom";
import leftArrow from "../../assets/images/navigate_left.svg";
import { SearchBox } from "../../components/SearchBox";
import showNotification from "../../components/notification/showNotification";

export const FarmerBidList = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [buyerReuqstList, setBuyerReuqstList] = useState([]);
  const [bidList, setBidList] = useState([]);
  const [bidInprogressList, setInprogressBidList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [paginationValue, setPaginationValue] = useState(10);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isUpdateOpenModal, setIsUpdateOpenModal] = useState(false);
  const [warehouseId, setWarehouseId] = useState("");
  const [status, setStatus] = useState(4);
  //   const [perPage, setPerPage] = useState(PER_COUNT["10"]);
  const [farmerBidId, setFarmerBidId] = useState("");
  const [editQty, setEditQty] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [acceptedQty, setAcceptedQty] = useState(0);
  const [unit, setUnit] = useState("");
  const loc = useLocation();
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [search, setSearch] = useState("");
  const [tabIndx, settabIndx] = useState(0);
  console.log("status", status)
  console.log("tabIndx", tabIndx)
  const fetchList = async (searchValue = search) => {
    setIsLoading(true);
    const response = await fetchFarmertList(
      currentPage,
      paginationValue,
      id,
      status,
      searchValue
    );
    if (response.status) {
      setBidList(response.data.list);
      setTotalRecords(response.data.count ?? response?.data?.list?.length);
      setIsLoading(false);
    }
  };

  const inprogressBidListList = async (searchValue = "") => {
    setInprogressBidList([]);
    setIsLoading(true);

    const response = await fetchFarmertList(
      currentPage,
      paginationValue,
      id,
      status,
      searchValue
    );
    if (response.status) {
      setInprogressBidList(response.data.list);
      setTotalRecords(response.data.count ?? response?.data?.list?.length);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchList();
    // inprogressBidListList(search);
  }, [currentPage, paginationValue, id, status, search]);
  const setTabByStatus = (status) => {
    if (status == 4) {
      return 0;
    } else if (status == 1) {
      return 1;
    } else if (status == 5) {
      return 2;
    } else return 0;
  };

  useEffect(() => {
    if (loc.state) {
      setStatus(loc.state);
      settabIndx(setTabByStatus(loc.state));
      // fetchList();
    }
  }, [loc.state]);

  // const handleConfirm = async () => {
  //   setEditQty(true);
  //   setIsOpen(true);
  //   console.log(editQty);
  // };

  const handleCancel = async () => {
    setEditQty(false);
  };

  const handleCheckboxChange = (item) => {
    setCheckedItems((prev) => {
      const isChecked = prev.some(
        (checkedItem) => checkedItem._id === item._id
      );
      if (isChecked) {
        return prev.filter((checkedItem) => checkedItem._id !== item._id);
      } else {
        return [
          ...prev,
          {
            userId: item.farmerId,
            _id: item._id,
            quantity: Number(item.acceptedQty),
            unit: item.reqUnit,
          },
        ];
      }
    });
  };

  const handleQuantityChange = (e, item) => {
    const newQuantity = e.target.value;
    if (Number(newQuantity) > Number(item.quantity)) {
      alert(`Quantity cannot exceed ${item.quantity}`);
      return;
    }
    setCheckedItems((prev) =>
      prev.map((checkedItem) =>
        checkedItem._id === item._id
          ? { ...checkedItem, quantity: newQuantity }
          : checkedItem
      )
    );
  };

  const handleSave = async () => {
    const payload = {
      orderData: checkedItems,
    };
    console.log(payload);
    const response = await postCompleteBid(id, payload);
    if (response.status) {
      showNotification("Complete Bid Request", response.message, "success");
      navigate("/warehouseRequest/list");
    } else {
      showNotification("Complete Bid Request", response.message, "danger");
    }
  };

  const handleUpdateBid = async () => {
    const payload = {
      farmerBidId: farmerBidId,
      status: 1, // status 1 for confirm bid
      acceptedQty: acceptedQty,
    };
    const response = await editWarehouseBid(warehouseId, payload);
    if (response.status) {
      fetchList();
      settabIndx(1);
      setStatus(1);
      setIsUpdateOpenModal(false);
      showNotification("Update Bid Request", response.message, "success");
    } else {
      showNotification("Update Bid Request", response.message, "danger");
    }
  };

  useEffect(() => {
    const handleWheel = (event) => {
      if (event.target.type === "number") {
        event.preventDefault();
      }
    };

    // Add event listener to the entire document
    document.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      // Clean up the event listener
      document.removeEventListener("wheel", handleWheel);
    };
  }, []);
  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left p-0">
            <div className="payout_data_ttl">
              <Breadcrumb
                paths={[
                  {
                    name: "Warehouse Requests",
                    link: ("/warehouseRequest/list", { state: { id: 3 } }),
                  },
                  { name: "Bid List", link: "/farmerBid/:id" },
                ]}
              />
            </div>
          </div>
          <div className="payout_data-right">
            <button
              onClick={() => {
                navigate(-1);
              }}
              className="back_btnn"
            >
              {" "}
              <img
                src={leftArrow}
                alt="Arrow"
                className="navi_left"
              /> Back{" "}
            </button>
          </div>

          {/* {status == 1 ? (
            <button
              color="info"
              tag="a"
              onClick={() => {
                handleConfirm();
              }}
            >
              Complete Bid
            </button>
          ) : (
            ""
          )} */}
        </div>
        <div className="search_container mb-4">
          <SearchBox search={search} setSearch={setSearch} />
        </div>
      </div>
      <div className="tab_box">
        <Tabs selectedIndex={tabIndx} onSelect={(index) => settabIndx(index)}>
          <TabList>
            <Tab
              onClick={() => {
                setStatus(4);
                setCurrentPage(1);
                setPaginationValue(10);
              }}
            >
              New Requests
              {/* <div className="paymentHits">{totalRecords}</div> */}
            </Tab>
            <Tab
              onClick={() => {
                setStatus(1);
                setCurrentPage(1);
                setPaginationValue(10);
              }}
            >
              {" "}
              Inprogress Requests
              {/* <div className="paymentHits">{totalRecords}</div> */}
            </Tab>
            <Tab
              onClick={() => {
                setBidList([]);
                setStatus(5);
                setCurrentPage(1);
                setPaginationValue(10);
              }}
            >
              {" "}
              Confirmed Requests
            </Tab>
            {/* <Tab
              onClick={() => {
                setStatus(2);
              }}
            >
              Completed Request
              <div className="paymentHits">{totalRecords}</div>
            </Tab> */}
          </TabList>
          <TabPanel>
            {/* <div className="payout_table-box upi-mandate_table-box"> */}
            <table className="payout_table upi-mandate_table">
              <thead>
                <tr className="tableHead">
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Order ID</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Farmer Name</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Farmer Address</div>
                  </th>

                  <th className="thHeader">
                    <div className="payoutHead">Product</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Variety</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Grade</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">MSP</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Price </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Quantity </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Total Price </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Delivery Date</div>
                  </th>
                  <th className="thHeader ">
                    <div className="payoutHead">Action </div>
                  </th>
                </tr>
              </thead>

              {isLoading ? (
                <tr>
                  <td colSpan="12">
                    <Loader />
                  </td>
                </tr>
              ) : bidList.length > 0 ? (
                bidList.map((item) => {
                  return (
                    <tr key={item._id}>
                      <td>{item?.orderId}</td>
                      <td className="phone_number">{item?.userName}</td>
                      <td className="Status">
                        {/* {item?.bidDetails?.location?.address} */}
                        {item?.farmerLocation?.address}
                        {/* <div className="noData"></div> */}
                      </td>
                      <td className="table_amount noWrap">{item?.product}</td>
                      <td className="table_amount ">{item?.variety ?? "-"}</td>
                      <td className="">{item?.grade}</td>
                      <td className="Status noWrap">
                        <div>
                          {item?.mspDetails?.minimum_selling_price
                            ? item?.mspDetails?.minimum_selling_price +
                              " " +
                              item?.mspDetails?.unit?.shortcode
                            : "-"}
                        </div>
                      </td>
                      <td className="Status bold_txt">
                        <div>
                          {handleCurrency()} {item?.price ?? 0}
                        </div>
                      </td>
                      <td className="Status bold_txt">
                        <div>{item?.quantity + " " + item?.unit}</div>
                      </td>
                      <td className="bold_txt">
                        <div>
                          {handleCurrency()}{" "}
                          {(item?.price ?? 0) *
                            (item?.quantity ?? 0) *
                            (item?.conversion ?? 0)}
                        </div>
                      </td>
                      <td className="noWrap">
                        {moment(item?.deliveryDate).format("DD-MM-YYYY")}
                      </td>
                      <td className="flex_btn align-items-center justify-content-center">
                        <img
                          src={infoBtn}
                          onClick={() => {
                            navigate(`/farmerBid/details/${item._id}`);
                          }}
                          alt="info icon"
                        />{" "}
                        <button
                          className="btn btn-primary"
                          // isOutline={!darkModeStatus}
                          color="dark"
                          isLight="btn btn-primary"
                          tag="a"
                          onClick={() => {
                            setWarehouseId(item?.bidDetails?._id);
                            setFarmerBidId(item._id);
                            setIsUpdateOpenModal(true);
                            setAcceptedQty(item?.quantity);
                            setUnit(item?.unit);
                          }}
                        >
                          Accept
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <>
                  <tr>
                    <td colSpan="12" className="text_center">
                      No data available
                    </td>
                  </tr>
                </>
              )}
            </table>{" "}
            {/* </div> */}
          </TabPanel>
          <TabPanel>
            <table className="payout_table upi-mandate_table">
              <thead>
                <tr className="tableHead">
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Order ID</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Farmer Name</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Farmer Address</div>
                  </th>

                  <th className="thHeader">
                    <div className="payoutHead">Product</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Variety</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Grade</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Price </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Accepted Quantity </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Total Price</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Delivery Date</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Action</div>
                  </th>
                </tr>
              </thead>

              {isLoading ? (
                <tr>
                  <td colSpan="11">
                    <Loader />
                  </td>
                </tr>
              ) : bidList.length > 0 ? (
                bidList.map((item) => {
                  return (
                    <tr key={item._id}>
                      <td>{item?.orderId}</td>
                      <td className="phone_number">{item?.userName}</td>
                      <td className="Status">
                        {item?.farmerLocation?.address}
                        {/* {item?.location} */}
                        {/* <div className="noData"></div> */}
                      </td>
                      <td className="table_amount">{item?.product}</td>
                      <td className="table_amount ">{item?.variety ?? "-"}</td>
                      <td className="">{item?.grade}</td>

                      <td className="Status bold_txt">
                        <div>
                          {handleCurrency()} {item?.price ?? 0}
                        </div>
                      </td>
                      <td className="Status">
                        {editQty ? (
                          <td>
                            <input
                              type="text"
                              style={{ border: "none" }}
                              value={
                                checkedItems.find(
                                  (checkedItem) => checkedItem._id === item._id
                                )?.acceptedQty || item?.acceptedQty
                              }
                              size={3}
                              max={item?.acceptedQty}
                              min={0}
                              onChange={(e) => handleQuantityChange(e, item)}
                            />
                          </td>
                        ) : (
                          <td className="bold_txt">
                            {item?.acceptedQty} {item?.unit}
                          </td>
                        )}
                      </td>
                      <td className="bold_txt">
                        <div>
                          {handleCurrency()}{" "}
                          {(item?.price ?? 0) *
                            (item?.acceptedQty ?? 0) *
                            (item?.conversion ?? 0)}
                        </div>
                      </td>
                      <td>{moment(item?.deliveryDate).format("DD-MM-YYYY")}</td>
                      <td>
                        <img
                          src={infoBtn}
                          onClick={() => {
                            navigate(`/farmerBid/details/${item._id}`);
                          }}
                          alt="infoIcon"
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <>
                  <tr>
                    <td colSpan="15" className="text_center">
                      No data available
                    </td>
                  </tr>
                </>
              )}
            </table>
          </TabPanel>
          <TabPanel>
            <table className="payout_table upi-mandate_table">
              <thead>
                <tr className="tableHead">
                  <th className="thHeader"></th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Order ID</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead hndIcon">Farmer Name</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Farmer Address</div>
                  </th>

                  <th className="thHeader">
                    <div className="payoutHead">Product</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Variety</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Grade</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Price </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Accepted Quantity </div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Total Price</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Delivery Date</div>
                  </th>
                  <th className="thHeader">
                    <div className="payoutHead">Action</div>
                  </th>
                </tr>
              </thead>

              {isLoading ? (
                <tr>
                  <td colSpan="15">
                    <Loader />
                  </td>
                </tr>
              ) : bidList.length > 0 ? (
                bidList.map((item) => {
                  return (
                    <tr key={item._id}>
                      <td>
                        <input
                          type="checkbox"
                          onChange={() => handleCheckboxChange(item)}
                          checked={checkedItems.some(
                            (checkedItem) => checkedItem._id === item._id
                          )}
                        />
                      </td>
                      <td>{item?.orderId}</td>
                      <td className="phone_number">{item?.userName}</td>
                      <td className="Status">
                        {item?.farmerLocation?.address}
                        {/* {item?.location} */}
                        {/* <div className="noData"></div> */}
                      </td>
                      <td className="table_amount">{item?.product}</td>
                      <td className="table_amount ">{item?.variety ?? "-"}</td>
                      <td className="">{item?.grade}</td>

                      <td className="Status bold_txt">
                        <div>
                          {handleCurrency()} {item?.price ?? 0}
                        </div>
                      </td>
                      <td className="Status">
                        {editQty ? (
                          <td>
                            <input
                              type="text"
                              style={{ border: "none" }}
                              value={
                                checkedItems.find(
                                  (checkedItem) => checkedItem._id === item._id
                                )?.acceptedQty || item?.acceptedQty
                              }
                              size={3}
                              max={item?.acceptedQty}
                              min={0}
                              onChange={(e) => handleQuantityChange(e, item)}
                            />
                          </td>
                        ) : (
                          <td className="bold_txt">
                            {item?.acceptedQty} {item?.unit}
                          </td>
                        )}
                      </td>
                      <td className="bold_txt">
                        <div>
                          {handleCurrency()}{" "}
                          {(item?.price ?? 0) *
                            (item?.acceptedQty ?? 0) *
                            (item?.conversion ?? 0)}
                        </div>
                      </td>
                      <td>{moment(item?.deliveryDate).format("DD-MM-YYYY")}</td>
                      <td>
                        <div className="flex_btn">
                          <img
                            src={infoBtn}
                            onClick={() => {
                              navigate(`/farmerBid/details/${item._id}`);
                            }}
                            alt="infoIcon"
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <>
                  <tr>
                    <td colSpan="13" className="text_center">
                      No data available
                    </td>
                  </tr>
                </>
              )}
            </table>
            {bidList?.length > 0 && (
              <div className="m-4 text-end flex_btn">
                <button
                  className={`btn btn-success ${
                    checkedItems.length > 0 ? "" : "disabled"
                  }`}
                  // isOutline={!darkModeStatus}
                  color="info"
                  variant="secondary"
                  isLight="btn btn-primary"
                  tag="a"
                  isDisable={checkedItems.length > 0 ? false : true}
                  onClick={() => {
                    setIsConfirmModal(true);
                  }}
                >
                  Complete
                </button>
                {"  "}
                {/* <button
                  className="btn btn-dark"
                  // isOutline={!darkModeStatus}
                  color="info"
                  isLight="btn btn-primary"
                  tag="a"
                  onClick={() => {
                    handleCancel();
                  }}
                >
                  Cancel
                </button> */}
              </div>
            )}
          </TabPanel>
          {bidList.length > 0 ?
            <Pagination
              currentPage={currentPage}
              totalCount={totalRecords}
              pageSize={paginationValue}
              onPageChange={(page) => setCurrentPage(page)}
              changePageSize={(page) => {
                setPaginationValue(page);
                setCurrentPage(1);
              }}
            /> : ""}
        </Tabs>
        {/* modal to accept bid */}
        <Modal
          show={isUpdateOpenModal}
          onHide={() => {
            setIsUpdateOpenModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Accept Bid?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Do you want to update the quantity?
            <div
              className="input-box"
              style={{ display: "flex", alignItems: "center", gap: "4px" }}
            >
              <div className="input_field" style={{ flex: "3" }}>
                <input
                  type="number"
                  className="input"
                  name="quantity"
                  placeholder="Enter quantity"
                  required
                  value={acceptedQty}
                  onChange={(e) => {
                    setAcceptedQty(e.target.value);
                  }}
                />
              </div>
              <div>{unit}</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-dark"
              variant="secondary"
              onClick={() => {
                setIsUpdateOpenModal(false);
              }}
            >
              No
            </button>
            <button
              className="btn btn-success"
              variant="primary"
              onClick={handleUpdateBid}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>

        {/* modal to confirm bid */}
        <Modal
          show={isConfirmModal}
          onHide={() => {
            setIsConfirmModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title> </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you Sure, <br />
            You have recieved the goods?
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-dark"
              variant="secondary"
              onClick={() => {
                setIsConfirmModal(false);
              }}
            >
              No
            </button>
            <button
              className="btn btn-success"
              variant="primary"
              onClick={handleSave}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};
