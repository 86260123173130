import { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Pagination from "../pagination/paginationView";
import {
  fetchAppUsersList,
  fetchUsersList,
  notregistereduser,
} from "../../action/userApi";
import filerIcon from "../../assets/images/Up_Down.svg";
import infoBtn from "../../assets/images/info-circle.svg";
import { or } from "ajv/dist/compile/codegen";
import { Loader } from "../../components/loader";

const NotRegisteredUser = ({ setSort, setOrder, order, searchValue }) => {
  const [isnotregisterd, setIsNotregistered] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState();
  const [loading, setLoading] = useState(false);

  const fetchNotRegisteredUsers = async () => {
    try {
      setLoading(true);
      const res = await notregistereduser(pageNo, pageSize, searchValue);
      setLoading(false);
      setIsNotregistered(res.data.list);
      setTotalData(res.data.count);
    } catch (error) { }
  };

  useEffect(() => {
    fetchNotRegisteredUsers();
  }, [pageNo, pageSize, searchValue]);

  return (
    <div className="table-wrapper">
      <table className="payout_table upi-mandate_table">
        <thead>
          <tr className="tableHead">
            <th className="thHeader">
              <div className="payoutHead hndIcon">
                Name
                <img
                  src={filerIcon}
                  onClick={() => {
                    setSort("name");
                    setOrder(order == 1 ? -1 : 1);
                  }}
                  alt="filter"
                />
              </div>
            </th>

            <th className="thHeader">
              <div className="payoutHead hndIcon">Mobile No</div>
            </th>

            <th className="thHeader">
              <div className="payoutHead hndIcon">Joining Date</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <Loader />
          ) : (
            <>
              {isnotregisterd?.length > 0 ? (
                isnotregisterd?.map((users) => (
                  <tr key={users?._id}>
                    <td>{users?.name || "-"}</td>

                    <td>{users?.mobile}</td>

                    <td>{moment(users?.createdAt).format("DD/MM/YYYY")}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colspan="12">
                    <div className="text-center">
                      No data available</div>
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
      {isnotregisterd.length > 0 ? (
        <Pagination
          currentPage={pageNo}
          totalCount={totalData}
          pageSize={pageSize}
          onPageChange={(page) => setPageNo(page)}
          changePageSize={(page) => {
            setPageSize(page);
            setPageNo(1);
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default NotRegisteredUser;
