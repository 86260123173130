import React, { useEffect, useState } from "react";
import { Loader } from "../../components/loader";
import Pagination from "../../components/pagination/paginationView";
import {
  deletebanner,
  AddBannerApi,
  EditBannerApi,
  fetchBannerList,
  singleBannerApi,
} from "../../action/banner";
import trashIcon from "../../assets/images/trash-03.svg";
import editIcon from "../../assets/images/edit_btn.svg";
import plusWhite from "../../assets/images/plus-white.svg";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { SingleErrorMessage } from "../singleErrorMessage";
import { Modal, Offcanvas } from "react-bootstrap";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import uploadImage from "../../action/imageUpload";
import ThumbnailPicker from "../../components/ThumbnailPicker/ThumbnailPicker";
const Bannerlist = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [bannerList, setBannerList] = useState([]);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [banner_Id, setBanner_Id] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isUpdateOpenModal, setIsUpdateOpenModal] = useState(false);
  const [curId, setCurId] = useState("");
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  const getBannerList = async () => {
    setIsLoading(true);
    const response = await fetchBannerList(currentPage, paginationValue);
    if (response.status) {
      setIsLoading(false);
      setBannerList(response.data);
      setTotalRecords(response.count);
    }
  };

  const onCloseToaste = () => {
    toast.dismiss();
  };

  const deletebannerdata = async () => {
    const response = await deletebanner(banner_Id);
    if (response.status) {
      toast.custom(
        <SingleErrorMessage
          data={"Bannner deleted Successfully!"}
          error={false}
          bColor="#175FC9"
          onClose={onCloseToaste}
        />
      );
      getBannerList();
      setShowDeleteModel(false);
    } else {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
  };

  useEffect(() => {
    getBannerList();
  }, [currentPage, paginationValue]);

  const addFormik = useFormik({
    initialValues: {
      title: "",
      desc: "",
      assets: {
        type: 0,
        url: "",
        w: "200px",
        h: "200px",
      },
      languageId: "645219ed7b2c3d0c68c4ca56",
      type: 20,
    },
    onSubmit: async (values) => {
      const response = await AddBannerApi(values);
      if (response?.status) {
        getBannerList(); // refresh the banner list after adding
        setIsOpenModal(false);
        toast.custom(
          <SingleErrorMessage
            data={response.data?.message}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
        addFormik.resetForm();
      } else {
        toast.custom(
          <SingleErrorMessage
            data={"Please upload  banner image"}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
      }
      addFormik.resetForm();
    },
  });
  const EditFormik = useFormik({
    initialValues: {
      title: "",
      desc: "",
      assets: {
        type: 0,
        url: "",
        w: "200px",
        h: "200px",
      },

      type: 20,
      languageId: "",
    },
    onSubmit: async (values) => {
      const response = await EditBannerApi(curId, values);
      if (response?.status) {
        toast.custom(
          <SingleErrorMessage
            data={"Banner Edit Successfully"}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
        setIsOpenEditModal(false);
        getBannerList(); // refresh the banner list after adding

        EditFormik.resetForm();
      } else {
        toast.custom(
          <SingleErrorMessage
            data={"Please upload  banner image"}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
      }
    },
  });
  const getSingleBannerData = async () => {
    const response = await singleBannerApi(curId);
    if (response?.status) {
      EditFormik.setValues({
        title: response?.data?.title ?? "",
        desc: response?.data?.desc ?? "",
        assets: {
          type: response?.data?.assets?.type ?? 0,
          url: response?.data?.assets?.url ?? "banner/1731402670056.png",
          w: response?.data?.assets?.w ?? "200px",
          h: response?.data?.assets?.h ?? "200px",
        },
        type: response?.data?.type === "CONTEST" ? "CONTEST" : 20, // Ensure valid type for main type
        languageId: response?.data?.languageId ?? "",
      });
    }
  };

  useEffect(() => {
    if (curId && isOpenEditModal) {
      getSingleBannerData();
    }
  }, [curId, isOpenEditModal]);

  const uploadMediaImage = async (file, type) => {
    if (file.type.startsWith("image/")) {
      const formData = new FormData();
      formData.append("file", file);
      const res = await uploadImage(formData, "gaon");
      if (res.status) {
        if (type == 1) {
          if (isOpenModal) {
            addFormik.setFieldValue("assets.url", res.data[0]);
          }
          if (isOpenEditModal) {
            EditFormik.setFieldValue("assets.url", res.data[0]);
          }
        } else {
          if (isOpenModal) {
            addFormik.setFieldValue("assets.url", res.data[0]);
          }
          if (isOpenEditModal) {
            EditFormik.setFieldValue("assets.url", res.data[0]);
          }
        }
      }
    } else {
      toast.custom(
        <SingleErrorMessage
          data={"Please upload only image"}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
  };

  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top mb-2">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              {" "}
              <Breadcrumb paths={[{ name: "Banner" }]} />
            </div>
          </div>
          
          <button
            color="info"
            tag="a"
            className="btn btn-info addbbtn"
            onClick={() => {
              setIsOpenModal(true);
            }}
          >
            Create New
            <img src={plusWhite} alt="" className="payout_down" />
          </button>
        </div>
      </div>
      <table className="payout_table upi-mandate_table">
        <thead>
          <tr className="tableHead">
            <th className="thHeader">
              <div className="payoutHead hndIcon">Title</div>
            </th>
            <th className="thHeader">
              <div className="payoutHead hndIcon">Description</div>
            </th>
            <th className="thHeader">
              <div className="payoutHead">Banner Image</div>
            </th>
            <th className="thHeader">
              <div className="payoutHead">Action</div>
            </th>
          </tr>
        </thead>

        {isLoading ? (
          <tr>
            <td colSpan="10">
              <Loader />
            </td>
          </tr>
        ) : (
          <>
            {bannerList?.length > 0 ? (
              bannerList?.map((item) => {
                return (
                  <tr key={item._id}>
                    <td>{item.title}</td>
                    <td>{item.desc}</td>
                    <td>
                      <div className="bannerImageList">
                        <img
                        className="banner_img"
                          src={
                            process.env.REACT_APP_S3_URL + "/" + item.assets.url
                          }
                          alt="banner"
                        />
                      </div>
                    </td>
                    <td>
                      <div className="flex_btn-box justify-content-lg-start gap-2">
                        <div className="flex_btn">
                          <img
                            alt="editIcon"
                            src={editIcon}
                            className="i_button"
                            onClick={() => {
                              setCurId(item._id);
                              setIsOpenEditModal(true);
                            }}
                          />
                        </div>
                        <div
                          className="flex_btn"
                          onClick={() => {
                            setShowDeleteModel(true);
                            setBanner_Id(item._id);
                          }}
                        >
                          <img src={trashIcon} alt="trashIcon" className="i_button" />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <>
                <tr>
                  <td colspan="11" className="text_center">
                    No data available
                  </td>
                </tr>
              </>
            )}
          </>
        )}
      </table>
      <Pagination
        currentPage={currentPage}
        totalCount={totalRecords}
        pageSize={paginationValue}
        onPageChange={(page) => setCurrentPage(page)}
        changePageSize={(page) => {
          setPaginationValue(page);
          setCurrentPage(1);
        }}
      />
      <Offcanvas
        show={isOpenModal}
        onHide={() => {
          setIsOpenModal(false);
          addFormik.handleReset();
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Create Banner</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="add_buyer_content">
            <form onSubmit={addFormik.handleSubmit}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="input-box">
                    <div className="input-label">Title</div>
                    <div className="input_field">
                      <input
                        className="input"
                        required
                        name="title"
                        placeholder="Enter Title"
                        value={addFormik.values.title}
                        onChange={addFormik.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <div className="input-label">Description</div>
                    <div className="input_field">
                      <input
                        className="input"
                        required
                        name="desc"
                        placeholder="Enter Description"
                        value={addFormik.values.desc}
                        onChange={addFormik.handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="option-card"
                  style={{ display: "flex", marginTop: "36px" }}
                >
                  <ThumbnailPicker
                    width="60%"
                    height="250px"
                    onFileSelect={(file) => {
                      const _URL = window.URL || window.webkitURL;

                      const img = new Image();
                      uploadMediaImage(file, 1);

                      img.src = _URL.createObjectURL(file);
                    }}
                    image={addFormik.values.assets.url}
                    required
                  />
                </div>
              </div>
              <div className="mt-3 flex_btn">
                <button className="btn btn-success" type="submit">
                  Create
                </button>
              </div>
            </form>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <Offcanvas
        show={isOpenEditModal}
        onHide={() => {
          setIsOpenEditModal(false); // Close the correct modal
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Edit Request</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="add_buyer_content">
            <form onSubmit={EditFormik.handleSubmit}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="input-box">
                    <div className="input-label">Title</div>
                    <div className="input_field">
                      <input
                        className="input"
                        required
                        name="title"
                        placeholder="Enter Title"
                        onChange={(e) => {
                          EditFormik.setFieldValue("title", e.target.value);
                        }}
                        value={EditFormik.values.title}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <div className="input-label">Description</div>
                    <div className="input_field">
                      <input
                        className="input"
                        required
                        name="desc"
                        placeholder="Enter Description"
                        onChange={(e) => {
                          EditFormik.setFieldValue("desc", e.target.value);
                        }}
                        value={EditFormik.values.desc}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="option-card"
                  style={{ display: "flex", marginTop: "36px" }}
                >
                  <ThumbnailPicker
                    width="60%"
                    height="250px"
                    onFileSelect={(file) => {
                      const _URL = window.URL || window.webkitURL;

                      const img = new Image();
                      uploadMediaImage(file, 1);

                      img.src = _URL.createObjectURL(file);
                    }}
                    image={EditFormik.values.assets.url}
                    accept={"image/*"}
                    required
                  />
                </div>
              </div>
              <div className="mt-3 flex_btn">
                <button className="btn btn-success" type="submit">
                  Edit
                </button>
              </div>
            </form>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <Modal
        show={showDeleteModel}
        onHide={() => {
          setShowDeleteModel(false);
        }}
      >

        <Modal.Header closeButton>
          <Modal.Title>Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this banner ?</Modal.Body>
        <Modal.Footer>
          <button
          className="btn btn-dark"
            variant="secondary"
            onClick={() => {
              setShowDeleteModel(false);
            }}
          >
            No
          </button>
          <button
            className="btn btn-success"
            variant="primary"
            onClick={() => {
              deletebannerdata();
            }}
            disabled={!showDeleteModel}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Bannerlist;
