import React, { useState, useEffect } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { TabPanel, Tabs } from "react-tabs";
import { Loader } from "../../components/loader";
import Pagination from "../../components/pagination/paginationView";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { useNavigate } from "react-router-dom";
import {
  AddUserNotification,
  fetchUserNotifications,
  geUserNotifications,
  putNotifications,
} from "../../action/users";
import infoBtn from "../../assets/images/info-circle.svg";
import ViewBidDetails from "../../components/popup/viewBidDetails";
import { Button, Modal, Offcanvas } from "react-bootstrap";
import plusWhite from "../../assets/images/plus-white.svg";
import moment from "moment";

import Select from "react-select";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { SingleErrorMessage } from "../singleErrorMessage";
import {
  fetchAllDistricts,
  fetchAllStates,
  fetchAllSubDistricts,
  fetchDistricts,
  fetchGaons,
  fetchStates,
  fetchSubDistricts,
} from "../../action/location";
import { fetchAllLanguages } from "../../action/language";
import ThumbnailPicker from "../../components/ThumbnailPicker/ThumbnailPicker";
import uploadImage from "../../action/imageUpload";

export const UserNotification = () => {
  const navigate = useNavigate();

  const [list, setList] = useState([]);
  const [saveSelect, setSaveSelect] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationValue, setPaginationValue] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenCanvas, setIsOpenCanvas] = useState(false);

  const [contest, setContest] = useState("");
  const [gaonList, setGaonList] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [stateId, setStateId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [blockId, setBlockId] = useState("");
  const [selectedImage, setSelectedImage] = useState(null); // State for selected image
  const [showImageModal, setShowImageModal] = useState(false); // State for modal visibility
  const [languageList, setLanguageList] = useState({
    loading: false,
    data: null,
  });

  const handleViewImage = (image) => {
    const imageUrl = `${process.env.REACT_APP_S3_URL}/${image}`; // Construct the image URL
    console.log("Selected Image URL:", imageUrl); // Debugging: Check the constructed image URL
    setSelectedImage(imageUrl); // Set the selected image URL
    setShowImageModal(true); // Show the modal
  };

  const getLanguageList = async () => {
    setLanguageList((prev) => ({ ...prev, loading: true }));


    try {
      const response = await fetchAllLanguages();
      setLanguageList({ loading: false, data: response.data.data });
    } catch (error) {
      console.error("Failed to fetch language list:", error);
      console.error("Failed to fetch language list:", error);
      setLanguageList({ loading: false, data: null });
    }
  };

  const getUserNotifications = async () => {
    setIsLoading(true);
    const response = await fetchUserNotifications(currentPage, paginationValue);
    if (response.status) {
      setList(response.data?.list);
      setTotalRecords(response.data.count);
      setIsLoading(false);
    }
  };
  const onCloseToaste = () => {
    toast.dismiss();
  };
  const addFormik = useFormik({
    initialValues: {
      title: "",
      body: "",
      image: "",
      type: 0,
      status: 0,
      placeId: "6707840f024ee05dfd29a0d1",
      event: "",
      languageId: "",
      languageId: "",
    },
    onSubmit: async (values, { resetForm }) => {
      const response = await AddUserNotification(values);
      if (response?.status) {
        getUserNotifications(); // refresh the banner list after adding
        setIsOpenCanvas(false);
        setSaveSelect("");
        toast.custom(
          <SingleErrorMessage
            data={"Created successfully"}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
        resetForm();
      } else {
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
      }
    },
  });
  const getState = async () => {
    const res = await fetchStates();
    if (res.status) {
      const arr = res?.data?.map((item) => {
        return { value: item._id, label: item.name };
      });
      setStates(arr);
    }
  };
  const getDistrict = async () => {
    const res = await fetchDistricts(stateId);
    if (res.status) {
      const arr = res?.data?.map((item) => {
        return { value: item._id, label: item.name };
      });
      setDistricts(arr);
    }
  };
  const getSubDistrict = async () => {
    const res = await fetchSubDistricts(districtId);
    if (res.status) {
      const arr = res?.data?.map((item) => {
        return { value: item._id, label: item.name };
      });
      setBlocks(arr);
    }
  };
  const fetchGaonList = async () => {
    const response = await fetchGaons(blockId);
    if (response.status) {
      const arr = response?.data.map((item) => {
        return { value: item._id, label: item.name };
      });
      setGaonList(arr);
    }
  };

  useEffect(() => {
    getLanguageList();
  }, []);

  useEffect(() => {
    getUserNotifications();
  }, [currentPage, paginationValue]);
  useEffect(() => {
    getState();
  }, [addFormik.values.type]);
  useEffect(() => {
    if (addFormik.values.type !== 0) getDistrict();
  }, [stateId]);
  useEffect(() => {
    if (addFormik.values.type !== 0) getSubDistrict();
  }, [districtId]);
  useEffect(() => {
    if (addFormik.values.type !== 0) fetchGaonList();
  }, [blockId]);

  const uploadMediaImage = async (file, type) => {
    if(!file.type.includes("image")){
      toast.custom(
        <SingleErrorMessage
          data={"Please select a valid image file"}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    const res = await uploadImage(formData, "gaon");
    if (res.status) {
      addFormik.setFieldValue("image", res.data[0]);
    }
  };

  return (
    <div className="payout_data-content">
      <div className="payout_inner_content">
        <div className="payout_data-head">
          <div className="payout_data-head-top">
            <div className="payout_data-left">
              <div className="payout_data_ttl">
                <Breadcrumb
                  paths={[
                    {
                      name: `Dashboard`,
                      link: `/`,
                    },
                    {
                      name: "User Notifications",
                      link: `/user-notification`,
                    },
                  ]}
                />
              </div>
            </div>
            <button
              color="info"
              tag="a"
              type="button"
              onClick={() => {
                setIsOpenCanvas(true);
              }}
              className="btn btn-info addbbtn"
            >
              Add New
              <img src={plusWhite} alt="" className="payout_down" />
            </button>
          </div>
        </div>
        <table className="payout_table upi-mandate_table">
          <tr className="tableHead">
            <th className="thHeader">
              <div className="payoutHead hndIcon">Title</div>
            </th>
            <th className="thHeader">
              <div className="payoutHead hndIcon">Body</div>
            </th>
            <th className="thHeader">
              <div className="payoutHead hndIcon">Target</div>
            </th>
            <th className="thHeader">
              <div className="payoutHead hndIcon">Status</div>
            </th>
            <th className="thHeader">
              <div className="payoutHead hndIcon">Create On</div>
            </th>
            <th className="thHeader">
              <div className="payoutHead hndIcon">Image</div>
            </th>
            {/* <th className="thHeader">
                    <div className="payoutHead headerAction">Action </div>
                  </th> */}
          </tr>
          {isLoading ? (
            <tr>
              <td colSpan="9">
                <Loader />
              </td>
            </tr>
          ) : (
            <>
              {list.length > 0 ? (
                list.map((item) => {
                  return (
                    <tr>
                      <td>{item?.title}</td>
                      <td>{item?.body}</td>
                      <td>
                        {item.type == 0
                          ? "ALL"
                          : item.type == 1
                            ? "GAON "
                            : item.type == 2
                              ? "BLOCK "
                              : item.type == 3
                                ? "DISTRICT "
                                : item.type == 4
                                  ? "STATE "
                                  : ""}
                      </td>
                      <td>
                        {item?.status == 0
                          ? "Send"
                          : item.status == 1
                            ? "Deleted"
                            : ""}
                      </td>
                      <td> {moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                      <td>
                        {item?.image && (
                          <button
                            className="not_img view_img"
                            onClick={() => handleViewImage(item?.image)}
                          >
                            View Image
                          </button>
                        )}{" "}
                      </td>
                      {/* <td>{item?.createdAt}</td> */}

                      {/* <td>
                              <div className="action_payment addBuyer_submit-btn pd_0 text-center">
                                <img
                                  src={infoBtn}
                                  
                                />
                              </div>
                            </td> */}
                    </tr>
                  );
                })
              ) : (
                <>
                  <tr>
                    <td colSpan="11" className="text_center">
                      No data available
                    </td>
                  </tr>
                </>
              )}
            </>
          )}
        </table>{" "}
        <Modal
          show={showImageModal}
          onHide={() => setShowImageModal(false)}
          className="notification_image"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            {selectedImage && (
              <img
                src={selectedImage}
                alt="Selected"
                style={{ width: "100%" }}
              />
            )}
          </Modal.Body>
        </Modal>
      </div>

      <Pagination
        currentPage={currentPage}
        totalCount={totalRecords}
        pageSize={paginationValue}
        onPageChange={(page) => setCurrentPage(page)}
        changePageSize={(page) => {
          setPaginationValue(page);
          setCurrentPage(1);
        }}
      />

      <Offcanvas
        show={isOpenCanvas}
        onHide={() => {
          setIsOpenCanvas(false);
        }}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add User Notification</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="add_buyer_content">
            <form onSubmit={addFormik.handleSubmit}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="input-box">
                    <div className="input-label">
                      Language <span>*</span>
                    </div>
                    <div className="input_field">
                      <select
                        className="input"
                        name="languageId"
                        value={addFormik.values.languageId}
                        onChange={addFormik.handleChange}
                        required
                      >
                        <option value="" key={`no_lang`}>Select Language</option>
                        {languageList.data?.map((el) => (<option key={el._id} value={el._id}>{el.name}</option>))}
                      </select>
                    </div>

                    {/* Add other conditionals here */}
                  </div>

                  {/* Title Field */}
                  <div className="input-box">
                    <div className="input-label">Title</div>
                    <div className="input_field">
                      <input
                        type="text"
                        className="input"
                        name="title"
                        placeholder="Enter Notification Title"
                        value={addFormik.values.title}
                        onChange={addFormik.handleChange}
                        onBlur={addFormik.handleBlur}
                        required
                      />
                    </div>
                    {addFormik.touched.title && addFormik.errors.title && (
                      <div className="error">{addFormik.errors.title}</div>
                    )}
                  </div>

                  {/* Body Field */}
                  <div className="input-box">
                    <div className="input-label">Body</div>
                    <div className="input_field">
                      <input
                        type="text"
                        className="input"
                        name="body"
                        placeholder="Enter Notification Body"
                        value={addFormik.values.body}
                        onChange={addFormik.handleChange}
                        onBlur={addFormik.handleBlur}
                        required
                      />
                    </div>
                    {addFormik.touched.body && addFormik.errors.body && (
                      <div className="error">{addFormik.errors.body}</div>
                    )}
                  </div>
                  <div className="input-box">
                    <div className="input-label">
                      Contest <span>*</span>
                    </div>
                    <div className="input_field">
                      <select
                        className="input"
                        name="event"
                        value={addFormik.values.event}
                        onChange={(e) => {
                          const newContest = parseInt(e.target.value, 10);
                          addFormik.handleChange(e);
                          setContest((prevType) =>
                            prevType !== newContest ? newContest : prevType
                          );
                        }}
                        onBlur={addFormik.handleBlur}
                        required
                      >
                        <option value="">Select Type</option>
                        <option value="externalUrl">External URL</option>
                        <option value="newContest">New Contest</option>
                      </select>
                    </div>

                    {/* Add other conditionals here */}
                  </div>
                  {/* Body Field */}
                  {addFormik.values.event === "externalUrl" && (
                    <div className="input-box">
                      <div className="input-label">External Url</div>
                      <div className="input_field">
                        <input
                          type="text"
                          className="input"
                          name="extarnalUrl"
                          placeholder="Enter Url"
                          value={addFormik.values.url}
                          onChange={(e) =>
                            addFormik.setFieldValue("url", e.target.value)
                          }
                          onBlur={addFormik.handleBlur}
                          required
                        />
                      </div>
                      {/* {addFormik.touched.extarnalUrl &&
                      addFormik.errors.extarnalUrl && (
                        <div className="error">
                          {addFormik.errors.extarnalUrl}
                        </div>
                      )} */}
                    </div>
                  )}
                  {/* Unit Dropdown */}
                  <div className="input-box">
                    <div className="input-label">
                      Target <span>*</span>
                    </div>
                    <div className="input_field">
                      <select
                        className="input"
                        name="type"
                        value={saveSelect}
                        onChange={(e) => {
                          setSaveSelect(e.target.value);
                          if (e.target.value === "all") {
                            addFormik.setFieldValue("type", "0");
                          }
                        }}
                        onBlur={addFormik.handleBlur}
                        required
                      >
                        <option value="">Select Type</option>
                        <option value="all">All</option>
                        <option value="custom">Custom</option>
                      </select>
                    </div>

                    {/* Add other conditionals here */}
                  </div>
                  {saveSelect === "custom" ? (
                    <div className="input-box">
                      <div className="input-label">
                        Custom <span>*</span>
                      </div>
                      <div className="input_field">
                        <select
                          className="input"
                          name="type"
                          value={addFormik.values.type}
                          onChange={(e) => {
                            addFormik.handleChange(e);
                          }}
                          onBlur={addFormik.handleBlur}
                          required={saveSelect === "custom"}
                        >
                          <option value="0">Select</option>
                          <option value="1">GAON</option>
                          <option value="2">BLOCK</option>
                          <option value="3">DISTRICT</option>
                          <option value="4">STATE</option>
                        </select>
                      </div>

                      {/* Add other conditionals here */}
                    </div>
                  ) : (
                    ""
                  )}

                  {(addFormik.values.type == 1 ||
                    addFormik.values.type == 2 ||
                    addFormik.values.type == 3 ||
                    addFormik.values.type == 4) && (
                      <div className="input-box">
                        <div className="input-label">
                          State
                          <span>*</span>
                        </div>
                        <Select
                          name="stateId"
                          options={states}
                          onChange={(selectedOptions) => {
                            const id = Array.isArray(selectedOptions)
                              ? selectedOptions.map((option) => option.value)
                              : selectedOptions
                                ? [selectedOptions.value]
                                : [];
                            addFormik.setFieldValue("placeId", id[0].value);
                            setStateId(id);
                          }}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          required
                        />
                      </div>
                    )}

                  {(addFormik.values.type == 1 ||
                    addFormik.values.type == 2 ||
                    addFormik.values.type == 3) && (
                      <div className="input-box">
                        <div className="input-label">
                          District
                          <span>*</span>
                        </div>
                        <Select
                          name="districtId"
                          options={districts}
                          onChange={(selectedOptions) => {
                            const id = Array.isArray(selectedOptions)
                              ? selectedOptions.map((option) => option.value)
                              : selectedOptions
                                ? [selectedOptions.value]
                                : [];
                            addFormik.setFieldValue("placeId", id[0]);

                            setDistrictId(id);
                          }}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          required
                        />
                      </div>
                    )}

                  {(addFormik.values.type == 1 ||
                    addFormik.values.type == 2) && (
                      <div className="input-box">
                        <div className="input-label">
                          Sub District
                          <span>*</span>
                        </div>
                        <Select
                          name="blockId"
                          options={blocks}
                          onChange={(selectedOptions) => {
                            const id = Array.isArray(selectedOptions)
                              ? selectedOptions.map((option) => option.value)
                              : selectedOptions
                                ? [selectedOptions.value]
                                : [];
                            addFormik.setFieldValue("placeId", id[0]);
                            setBlockId(id);
                          }}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          required
                        />
                      </div>
                    )}

                  {addFormik.values.type == 1 && (
                    <div className="input-box">
                      <div className="input-label">
                        Gaon
                        <span>*</span>
                      </div>
                      <select
                        name="gaonId"
                        options={gaonList}
                        onChange={(selectedOptions) => {
                          const id = Array.isArray(selectedOptions)
                            ? selectedOptions.map((option) => option.value)
                            : selectedOptions
                              ? [selectedOptions.value]
                              : [];
                          addFormik.setFieldValue("placeId", id[0]);
                        }}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        required
                      />
                    </div>
                  )}
                </div>
                <div
                  className="option-card"
                  style={{ display: "flex", marginTop: "36px" }}
                >
                  <ThumbnailPicker
                    width="60%"
                    height="250px"
                    onFileSelect={(file) => {
                      const _URL = window.URL || window.webkitURL;

                      const img = new Image();
                      uploadMediaImage(file, 1);

                      img.src = _URL.createObjectURL(file);
                    }}
                    image={addFormik.values.image}
                    accept= {"image/*"}
                    required
                  />
                </div>
              </div>

              {/* Submit Button */}
              <div className="addBuyer_submit-btn">
                <button className="btn btn-success" type="submit">
                  Send
                </button>
              </div>
            </form>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};
