import React, { useState, useEffect } from "react";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import ThumbnailPicker from "../../components/ThumbnailPicker/ThumbnailPicker";
import { TabPanel, Tabs } from "react-tabs";

import { fetchGaonDetails } from "../../action/gaonApi";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { Loader } from "../../components/loader";
import { gaonTextBasedOnRoleTYpe } from "../../helper/constants";
// import Breadcrumb from "../../components/bootstrap/Breadcrumb";
const ShowGaon = () => {
  const navigate = useNavigate();
  const [gaonDetails, setGaonDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();

  const showgaonDetails = async () => {
    const response = await fetchGaonDetails(id);
    if (response.status) {
      setGaonDetails(response?.data);
    }
  };
  useEffect(() => {
    showgaonDetails(showgaonDetails);
  }, [id]);

  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              <Breadcrumb
                paths={[
                  { name: gaonTextBasedOnRoleTYpe(), link: "/gaon" },
                  { name: "Details", link: "/gaon/details" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Loader />}
      <div className="tab_box">
        <div className="row">
          <div className="col-lg-6">
            <div className="input-box">
              <div className="input-label">
                {gaonTextBasedOnRoleTYpe()} Name
              </div>
              <div className="input_field">
                <input
                  type="text"
                  className="input"
                  name="name"
                  disabled
                  value={gaonDetails?.name}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-box">
              <div className="input-label">Grampanchayats</div>
              <div className="input_field">
                <input
                  readOnly={true}
                  type="text"
                  className="input"
                  name="grampanchayats"
                  value={gaonDetails?.demography?.grampanchayats}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-box">
              <div className="input-label">Latitude</div>
              <div className="input_field">
                <input
                  type="text"
                  className="input"
                  name="extras"
                  disabled
                  value={gaonDetails?.extras?.lat}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-box">
              <div className="input-label">Longitude</div>
              <div className="input_field">
                <input
                  disabled
                  className="input"
                  type="text"
                  name="extras"
                  value={gaonDetails?.extras?.lng}
                  readOnly={true}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-box">
              <div className="input-label">Sub Disctrict</div>
              <div className="input_field">
                <input
                  readOnly={true}
                  type="text"
                  className="input"
                  name="grampanchayats"
                  value={gaonDetails?.blockDetails?.name}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-box">
              <div className="input-label">District</div>
              <div className="input_field">
                <input
                  readOnly={true}
                  type="text"
                  className="input"
                  name="grampanchayats"
                  value={gaonDetails?.districtDetails?.name}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-box">
              <div className="input-label">State</div>
              <div className="input_field">
                <input
                  readOnly={true}
                  type="text"
                  className="input"
                  name="grampanchayats"
                  value={gaonDetails?.stateDetails?.name}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-box">
              <div className="input-label">Mountain Range</div>
              <div className="input_field">
                <input
                  type="text"
                  readOnly={true}
                  className="input"
                  name="Mountainrange"
                  value={gaonDetails?.geographicalInfo?.mountainrange}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-box">
              <div className="input-label">Population</div>
              <div className="input_field">
                <input
                  readOnly={true}
                  type="text"
                  className="input"
                  name="population"
                  value={gaonDetails?.demography?.population}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-box">
              <div className="input-label">Literacy</div>
              <div className="input_field">
                <input
                  readOnly={true}
                  type="text"
                  className="input"
                  name="literacy"
                  value={gaonDetails?.demography?.literacy}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-box">
              <div className="input-label">Language</div>
              <div className="input_field">
                <input
                  readOnly={true}
                  type="text"
                  className="input"
                  name="langauge"
                  value={gaonDetails?.demography?.langauge}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-box">
              <div className="input-label">Total Area</div>
              <div className="input_field">
                <input
                  readOnly={true}
                  type="text"
                  className="input"
                  name="villages"
                  value={gaonDetails?.geographicalInfo?.totalArea}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-box">
              <div className="input-label">Agriculture</div>
              <div className="input_field">
                <input
                  readOnly={true}
                  type="text"
                  className="input"
                  name="aggriculture"
                  value={gaonDetails?.geographicalInfo?.aggriculture}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-box">
              <div className="input-label">Forest Area</div>
              <div className="input_field">
                <input
                  readOnly={true}
                  type="text"
                  className="input"
                  name="forestarea"
                  value={gaonDetails?.geographicalInfo?.forestarea}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-box">
              <div className="input-label">River Details</div>
              <div className="input_field">
                <input
                  readOnly={true}
                  type="text"
                  className="input"
                  name="zone"
                  value={gaonDetails?.geographicalInfo?.rivers}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="input-box">
              <div className="input-label">Zone</div>
              <div className="input_field">
                <input
                  type="text"
                  readOnly={true}
                  className="input"
                  name="zone"
                  value={gaonDetails?.geographicalInfo?.zone}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="input-box">
              <div className="input-label">Info</div>
              <textarea
                className="textarea_input"
                rows={9}
                name="info"
                value={gaonDetails?.info}
                readOnly={true}
              >
                {gaonDetails?.info}
              </textarea>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          {gaonDetails?.pic?.length > 0 ? (
            gaonDetails?.pic.map((value) => {
              return (
                <div className="col-lg-6 mt-3">
                  <div className="showGaonImg">
                    <img
                      src={process.env.REACT_APP_S3_URL + "/" + value}
                      alt="Preview"
                      style={{
                        width: "60%",
                        height: "250px",
                        marginRight: "20px",
                      }}
                    />
                  </div>
                </div>
              );
            })
          ) : (
            <> </>
          )}
        </div>
      </div>
      {/* <div className="text-end mt-3">
            <button
              className="btn btn-success"
              type="button"
              onClick={() => {
                navigate("/gaon");
              }}
            >
              Go Back
            </button>
          </div> */}
    </div>
  );
};

export default ShowGaon;
