import React, { useState, useEffect } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import { SingleErrorMessage } from "../singleErrorMessage";
import LocationSearchInput from "../../common/searchPlaces";
import { useNavigate } from "react-router-dom";
import { createBuyerUser, fetchFarmlinkRoles } from "../../action/userApi";
import * as Yup from "yup";
import sideImg from "../../assets/images/buyer_res.jpg";
import AOS from "aos";
import "aos/dist/aos.css";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please enter name"),
  roleId: Yup.string().required("Please select role"),

  mobile: Yup.string()
    .required("Please enter mobile")
    .matches(/^\d{10}$/, "Please enter valid mobile"),

  email: Yup.string()
    .required("Please enter email")
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Please enter valid email"),

  address: Yup.string().required("Please enter address"),
  location: Yup.object()
    .required("Please enter your location")
    .test(
      "is-valid-location",
      "Location must have valid keys.",
      (value) =>
        value && typeof value === "object" && Object.keys(value).length > 0
    ),
});

const BuyerRegistration = () => {
  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  const [roleData, setRoleData] = useState([]);

  const userFormik = useFormik({
    initialValues: {
      name: "",
      mobile: "",
      email: "",
      address: "",
      coordinates: [],
      location: "",
      roleId: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      values.coordinates = [values.location.lng, values.location.lat];

      const response = await createBuyerUser(values);
      if (response.status) {
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
        localStorage.setItem("role", response?.data?.role);
        localStorage.setItem("roleType", response?.data?.roleType);
        localStorage.setItem("userId", response?.data?.userId);
        navigate("/");
      } else {
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={true}
            bColor="#D92D20"
            onClose={onCloseToaste}
          />
        );
      }
    },
  });
  console.log("formik", userFormik);
  useEffect(() => {
    fetchRoles();
  }, []);
  const fetchRoles = async () => {
    const res = await fetchFarmlinkRoles();
    if (res.status) {
      setRoleData(res.data);
    }
  };
  const onCloseToaste = () => {
    toast.dismiss();
  };
  useEffect(() => {
    AOS.init({
      duration: 1500, // Animation duration in milliseconds
      once: true, // Whether animation runs only once
    });
  }, []);
  return (
    <div className="buyer_reg_page">
      <div className="buyer_reg_content" data-aos="fade-right">
        <div className="login text-align-center">Buyer Registration</div>
        <div className="tab_box">
          <form onSubmit={userFormik.handleSubmit} className="m-4">
            <div className="buyer_scroll">
              <div className="buyer_input_boxes">
                <div className="input-box">
                  <div className="input-label">
                    Name
                    <span>*</span>
                  </div>
                  <div className="input_field">
                    <input
                      type="text"
                      className="input"
                      placeholder="Enter name"
                      id="beneficiary"
                      name="name"
                      onChange={(e) => {
                        userFormik.setFieldValue("name", e.target.value);
                      }}
                      value={userFormik.values.name}
                    />
                  </div>
                  {userFormik.touched.name && userFormik.errors.name && (
                    <div style={{ color: "red" }}>{userFormik.errors.name}</div>
                  )}
                </div>
                <div className="input-box">
                  <div className="input-label">Email Id</div>
                  <div className="input_field">
                    <input
                      type="text"
                      className="input"
                      placeholder="Enter email"
                      id="beneficiary"
                      name="email"
                      onChange={(e) => {
                        userFormik.setFieldValue("email", e.target.value);
                      }}
                      value={userFormik.values.email}
                    />
                  </div>
                  {userFormik.touched.email && userFormik.errors.email && (
                    <div style={{ color: "red" }}>
                      {userFormik.errors.email}
                    </div>
                  )}
                </div>
                <div className="input-box">
                  <div className="input-label">
                    Address
                    <span>*</span>
                  </div>
                  <div className="input_field">
                    <input
                      type="text"
                      className="input"
                      placeholder="Enter address"
                      id="beneficiary"
                      name="address"
                      onChange={(e) => {
                        userFormik.setFieldValue("address", e.target.value);
                      }}
                      value={userFormik.values.address}
                    />
                  </div>
                  {userFormik.touched.address && userFormik.errors.address && (
                    <div style={{ color: "red" }}>
                      {userFormik.errors.address}
                    </div>
                  )}
                </div>
                {/* <div className="input-box">
                  <div className="input-label">
                    Postal Code
                    <span>*</span>
                  </div>
                  <div className="input_field">
                    <input
                      type="text"
                      className="input"
                      placeholder="Postal code"
                      id="beneficiary"
                      name="postalCode"
                      onChange={(e) => {
                        userFormik.setFieldValue("postalCode", e.target.value);
                      }}
                      value={userFormik.values.postalCode}
                    />
                  </div>
                  {userFormik.touched.postalCode &&
                    userFormik.errors.postalCode && (
                      <div style={{ color: "red" }}>
                        {userFormik.errors.postalCode}
                      </div>
                    )}
                </div> */}

                <div className="input-box">
                  <div className="input-label">
                    Role
                    <span>*</span>
                  </div>
                  <div className="input_field">
                    <select
                      className="input"
                      size="sm"
                      ariaLabel="Role"
                      onChange={(e) => {
                        userFormik.setFieldValue("roleId", e.target.value);
                      }}
                    >
                      <option value="">Select Role</option>
                      {roleData.map((i) => (
                        <option key={i._id} value={i._id}>
                          {i.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {userFormik.touched.roleId && userFormik.errors.roleId && (
                    <div style={{ color: "red" }}>
                      {userFormik.errors.roleId}
                    </div>
                  )}
                </div>
                <div className="input-box">
                  <div className="input-label">
                    Mobile
                    <span>*</span>
                  </div>
                  <div className="input_field">
                    <input
                      type="text"
                      className="input"
                      placeholder="Enter contact no."
                      id="beneficiary"
                      name="mobile"
                      onChange={(e) => {
                        userFormik.setFieldValue("mobile", e.target.value);
                      }}
                      value={userFormik.values.mobile}
                    />
                  </div>
                  {userFormik.touched.mobile && userFormik.errors.mobile && (
                    <div style={{ color: "red" }}>
                      {userFormik.errors.mobile}
                    </div>
                  )}
                </div>
                <div className="input-box">
                  <div className="input-label">
                    Location
                    <span>*</span>
                  </div>
                  <LocationSearchInput
                    address={address}
                    required={false}
                    setAddress={setAddress}
                    setLocation={(value) =>
                      userFormik.setFieldValue("location", value)
                    }
                  />
                  {userFormik.touched.location &&
                    userFormik.errors.location && (
                      <div style={{ color: "red" }}>
                        {userFormik.errors.location}
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className="flex_btn-box">
                  <div className="flex_btn">
                    <button className="btn btn-success" type="submit">
                      Save
                    </button>{" "}
                    <button
                      className="btn btn-primary btn-dark"
                      type="button"
                      onClick={() => {
                        navigate("/");
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="buyer_reg_side" data-aos="fade-left">
        <img className="buyer_reg_side_img" alt="buyerside" src={sideImg} />
      </div>
    </div>
  );
};

export default BuyerRegistration;
