import React, { useState, useEffect } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import { SingleErrorMessage } from "../singleErrorMessage";
import {
  fetchCountry,
  fetchDistricts,
  fetchGaons,
  fetchStatesByCountryId,
  fetchSubDistricts,
} from "../../action/location";
import Select from "react-select";
import LocationSearchInput from "../../common/searchPlaces";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { createUser, fetchAdmin } from "../../action/userApi";
import { Button, Modal } from "react-bootstrap";

const CreateUser = () => {
  const navigate = useNavigate();

  const [gaonList, setGaonList] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [stateId, setStateId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [blockId, setBlockId] = useState("");
  const [countryId, setCountryId] = useState("");
  const [location, setLocation] = useState({});
  const [address, setAddress] = useState("");
  const [roleData, setRoleData] = useState([]);
  const [selectRoletype, setSelectRoletype] = useState([]);
  const roleTyped = localStorage.getItem("roleType");
  const ROLE = localStorage.getItem("role");
  const [roleType, setRoleType] = useState([
    {
      _id: "6708df82c1d7995759995d2e",
      name: "GAON",
    },
    {
      _id: "6708df8cc1d7995759995d30",
      name: "FARMLINK",
    },
  ]);
  const [createUserModal, setcreateUserModal] = useState(false);
  const userFormik = useFormik({
    initialValues: {
      name: "",
      mobile: "",
      email: "",
      roleId: "",
      roleType: [],
      gaonId: "",
      blockId: "",
      districtId: "",
      stateId: "",
      countryId: "",
      address: "",
      coordinates: [],
      location: "",
    },

    onSubmit: async (values) => {
      if (values.email == "") {
        delete values.email;
      }
      if (isExporterOrRetailer(values.roleId, roleData)) {
        delete values.gaonId;
        delete values.blockId;
        delete values.districtId;
        delete values.stateId;
        delete values.countryId;
      }
      values.coordinates = [location.lng, location.lat];
      values.roleType = selectRoletype;
      delete location.coordinates;
      values.location = location;
      if (values.mobile.length < 7 || values.mobile.length > 10) {
        toast.custom(
          <SingleErrorMessage
            data={"Please enter a valid Mobile Number"}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
      } else {
        setcreateUserModal(true);
      }
    },
  });
  const onConfirm = async () => {
    const response = await createUser(userFormik.values);
    if (response.status) {
      toast.custom(
        <SingleErrorMessage
          data={"User Created Successfully"}
          error={false}
          bColor="#175FC9"
          onClose={onCloseToaste}
        />
      );
      setcreateUserModal(false);
      navigate("/userList");
    } else {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
  };
  const onCloseToaste = () => {
    toast.dismiss();
  };

  const fetchGaonList = async () => {
    const response = await fetchGaons(blockId);
    if (response.status) {
      const arr = response?.data.map((item) => {
        return { value: item._id, label: item.name };
      });
      setGaonList(arr);
    }
  };

  const getCountry = async () => {
    const res = await fetchCountry();
    if (res.status) {
      const arr = res?.data?.map((item) => {
        return { value: item._id, label: item.name };
      });
      setCountries(arr);
    }
  };

  const getState = async () => {
    const res = await fetchStatesByCountryId(countryId);
    if (res.status) {
      const arr = res?.data?.map((item) => {
        return { value: item._id, label: item.name };
      });
      setStates(arr);
    }
  };

  const getDistrict = async () => {
    const res = await fetchDistricts(stateId);
    if (res.status) {
      const arr = res?.data?.map((item) => {
        return { value: item._id, label: item.name };
      });
      setDistricts(arr);
    }
  };

  const getSubDistrict = async () => {
    const res = await fetchSubDistricts(districtId);
    if (res.status) {
      const arr = res?.data?.map((item) => {
        return { value: item._id, label: item.name };
      });
      setBlocks(arr);
    }
  };

  const fetchRoles = async () => {
    const res = await fetchAdmin();
    if (res.status) {
      setRoleData(res.data);
    }
  };

  useEffect(() => {
    if (blockId) fetchGaonList();
  }, [blockId]);

  useEffect(() => {
    fetchRoles();
    getCountry();
  }, []);
  useEffect(() => {
    // getState();
    if (countryId) getState();
  }, [countryId]);

  useEffect(() => {
    if (stateId) getDistrict();
  }, [stateId]);

  useEffect(() => {
    if (districtId) getSubDistrict();
  }, [districtId]);
  const isExporterOrRetailer = (seletedRoleId, rolelist) => {
    const selectedRole = rolelist.find((k) => k._id === seletedRoleId);
    return Boolean(
      selectedRole?.shortcode === "RETAILER" ||
      selectedRole?.shortcode === "EXPORTER" ||
      selectedRole?.shortcode === "BUYER" ||
      selectedRole?.shortcode === "MANUFACTURER"
    );
  };
  const roleOptions = roleData.map((role) => ({
    value: role._id,
    label: role.name,
  }));
  const roleTypeOptions = roleType
    .filter((type) => !(roleTyped === "FARMLINK" && type.name === "GAON"))
    .map((type) => ({
      value: type._id,
      label: type.name,
    }));
  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              <Breadcrumb
                paths={[
                  { name: "Users", link: "/userList" },
                  { name: "Add User", link: "/user/add" },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="tab_box">
        <form onSubmit={userFormik.handleSubmit} className="m-4">
          <ul className="form-user-grid">
            <li>
              <div className="input-box">
                <div className="input-label">
                  Name
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    placeholder="Enter name"
                    id="beneficiary"
                    name="name"
                    onChange={(e) => {
                      userFormik.setFieldValue("name", e.target.value);
                    }}
                    value={userFormik.values.name}
                    required
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="input-box">
                <div className="input-label">
                  Mobile
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    placeholder="Enter contact no."
                    id="beneficiary"
                    name="mobile"
                    onChange={(e) => {
                      userFormik.setFieldValue("mobile", e.target.value);
                    }}
                    value={userFormik.values.mobile}
                    required
                    maxLength={10}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="input-box">
                <div className="input-label">Email Id</div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    placeholder="Enter email"
                    id="beneficiary"
                    name="email"
                    onChange={(e) => {
                      userFormik.setFieldValue("email", e.target.value);
                    }}
                    value={userFormik.values.email}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="input-box">
                <div className="input-label">
                  Role
                  <span>*</span>
                </div>
                <Select
                  name="roleId"
                  aria-label="Role"
                  options={roleOptions}
                  onChange={(selectedOption) => {
                    userFormik.setFieldValue("roleId", selectedOption.value);
                  }}
                  required
                />
              </div>
            </li>
            <li><div className="input-box">
              <div className="input-label">
                Role Type
                <span>*</span>
              </div>
              <Select
                name="roleType"
                aria-label="Role Type"
                options={roleTypeOptions}
                onChange={(selectedOption) => {
                  setSelectRoletype(
                    [selectedOption?.value] // Store values as an array
                  );

                }}
                // onChange={(selectedOption) => {
                //   userFormik.setFieldValue("roleType", selectedOption ? [...selectedOption.value] : "");
                // }}
                required
              />
            </div>
            </li>
            {!isExporterOrRetailer(userFormik.values.roleId, roleData) && (
              <li>

                <div className="input-box">
                  <div className="input-label">
                    Country
                    <span>*</span>
                  </div>

                  <Select
                    name="countryId"
                    options={countries.filter(
                      (remove) =>
                        !(
                          roleTyped === "FARMLINK" &&
                          ROLE === "ADMIN" &&
                          remove.label === "India"
                        )
                    )}
                    onChange={(selectedOptions) => {
                      const id = Array.isArray(selectedOptions)
                        ? selectedOptions.map((option) => option.value)
                        : selectedOptions
                          ? [selectedOptions.value]
                          : [];
                      userFormik.setFieldValue("countryId", id[0]);
                      setCountryId(id);
                    }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    required
                  />
                </div>

              </li>
            )}
            {!isExporterOrRetailer(userFormik.values.roleId, roleData) && (
              <li>
                <div className="input-box">
                  <div className="input-label">
                    State
                    <span>*</span>
                  </div>
                  <Select
                    name="stateId"
                    options={states}
                    onChange={(selectedOptions) => {
                      const id = Array.isArray(selectedOptions)
                        ? selectedOptions.map((option) => option.value)
                        : selectedOptions
                          ? [selectedOptions.value]
                          : [];
                      userFormik.setFieldValue("stateId", id[0]);
                      setStateId(id);
                    }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    required
                  />
                </div>

              </li>
            )}
            {!isExporterOrRetailer(userFormik.values.roleId, roleData) && (
              <li>
                <div className="input-box">
                  <div className="input-label">
                    District
                    <span>*</span>
                  </div>

                  <Select
                    name="districtId"
                    options={districts}
                    onChange={(selectedOptions) => {
                      const id = Array.isArray(selectedOptions)
                        ? selectedOptions.map((option) => option.value)
                        : selectedOptions
                          ? [selectedOptions.value]
                          : [];
                      userFormik.setFieldValue("districtId", id[0]);
                      setDistrictId(id);
                    }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    required
                  />
                </div>

              </li>
            )}
            {!isExporterOrRetailer(userFormik.values.roleId, roleData) && (
              <li>
                <div className="input-box">
                  <div className="input-label">
                    Sub District
                    <span>*</span>
                  </div>

                  <Select
                    name="blockId"
                    options={blocks}
                    onChange={(selectedOptions) => {
                      const id = Array.isArray(selectedOptions)
                        ? selectedOptions.map((option) => option.value)
                        : selectedOptions
                          ? [selectedOptions.value]
                          : [];
                      userFormik.setFieldValue("blockId", id[0]);
                      setBlockId(id);
                    }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    required
                  />
                </div>

              </li>
            )}
            {!isExporterOrRetailer(userFormik.values.roleId, roleData) && (
              <li>
                <div className="input-box">
                  <div className="input-label">
                    Village
                    <span>*</span>
                  </div>
                  <Select
                    name="gaonId"
                    options={gaonList}
                    onChange={(selectedOptions) => {
                      const id = Array.isArray(selectedOptions)
                        ? selectedOptions.map((option) => option.value)
                        : selectedOptions
                          ? [selectedOptions.value]
                          : [];
                      userFormik.setFieldValue("gaonId", id[0]);
                    }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    required
                  />
                </div>

              </li>
            )}
            <li>
              <div className="input-box">
                <div className="input-label">
                  Address
                  <span>*</span>
                </div>
                <div className="input_field">
                  <input
                    type="text"
                    className="input"
                    placeholder="Enter address"
                    id="beneficiary"
                    name="address"
                    onChange={(e) => {
                      userFormik.setFieldValue("address", e.target.value);
                    }}
                    value={userFormik.values.address}
                    required
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="input-box location_field">
                <div className="input-label">
                  Location
                  <span>*</span>
                </div>
                <LocationSearchInput
                  required={true}
                  address={address}
                  setAddress={setAddress}
                  setLocation={setLocation}
                />
              </div>
            </li>
            {ROLE !== "ADMIN" && (
              <li>
                <div className="input-box">
                  <div className="input-label">
                    Postal Code
                    <span>*</span>
                  </div>
                  <div className="input_field">
                    <input
                      type="text"
                      className="input"
                      placeholder="Enter Postal Code"
                      id="beneficiary"
                      name="postalCode"
                      onChange={(e) => {
                        userFormik.setFieldValue("postalCode", e.target.value);
                      }}
                      value={userFormik.values.postalCode}
                      required
                      maxLength={6}
                    />
                  </div>
                </div>

              </li>
            )}
          </ul>

          <div>
            <div>
              <div className="flex_btn-box">
                <div className="flex_btn">
                  <button className="btn btn-success" type="submit">
                    Create user
                  </button>{" "}
                  <button
                    className="btn btn-primary btn-dark"
                    type="button"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Modal show={createUserModal}>
            <Modal.Header>
              <Modal.Title>
                Are you sure you want to create this user?{" "}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <span>Please confirm your details before saving</span>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-dark"
                variant="secondary"
                onClick={() => setcreateUserModal(false)}
              >
                Cancel
              </button>
              <button
                className="btn btn-success"
                variant="primary"
                onClick={onConfirm}
              >
                Confirm
              </button>
            </Modal.Footer>
          </Modal>
        </form>
      </div>
    </div>
  );
};

export default CreateUser;
