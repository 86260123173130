import React, { useState, useEffect } from "react";
import plusWhite from "../../assets/images/plus-white.svg";
import { useFormik } from "formik";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import moment from "moment";
import {
  updateBuyerBid,
} from "../../action/buyer";
import { toast } from "react-hot-toast";
import { SingleErrorMessage } from "../singleErrorMessage";
import Modal from "react-bootstrap/Modal";
import { handleCurrency, handleTitle } from "../../helper/helper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { changeWarhouseBidStatus } from "../../action/warehouse";
import { SearchBox } from "../../components/SearchBox";
import { BuyerPendingRequest } from "../../components/Buyerscomponents/BuyerPendingRequest";
import { BuyerConfirmedRequest } from "../../components/Buyerscomponents/BuyerConfirmedRequest";
import { BuyerInprogressRequest } from "../../components/Buyerscomponents/BuyerInprogressRequest";
import { BuyerClosedRequest } from "../../components/Buyerscomponents/BuyerClosedRequest";

export const BuyerRequestList = () => {
  const [isUpdateOpenModal, setIsUpdateOpenModal] = useState(false);
  const [warehouseId, setWarehouseId] = useState("");

  const [filteredList, setFilteredList] = useState([]);
  const [buyerId, setBuyerId] = useState("");
  const [warehouseStatus, setWarehouseStatus] = useState(0);

  const loc = useLocation();
  const [status, setStatus] = useState(0);
  const [tabIndx, settabIndx] = useState(0);
  const [locState] = useState(loc.state?.id ?? 0);
  const [search, setSearch] = useState("");
  const handleChangeStatus = (v) => {
    setStatus(v);
  };


  const onCloseToaste = () => {
    toast.dismiss();
  };
  const handleCompleteBuyerBid = async () => {
    const payload = {
      status: 2,
    };
    const response = await updateBuyerBid(buyerId, payload);
    if (response.status) {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={false}
          bColor="#175FC9"
          onClose={onCloseToaste}
        />
      );
      setIsUpdateOpenModal(false);
    } else {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
  };

  //complete warehouse request
  const handleUpdateWarehouseStatus = async () => {
    const payload = {
      status: warehouseStatus,
    };

    const response = await changeWarhouseBidStatus(warehouseId, payload);
    if (response.status) {
      const fetchRecord = filteredList.find((id) => id._id == buyerId);

      if (fetchRecord?.confirmRequests == 1) {
        handleCompleteBuyerBid();
      } else {
        toast.custom(
          <SingleErrorMessage
            data={response.message}
            error={false}
            bColor="#175FC9"
            onClose={onCloseToaste}
          />
        );
        setIsUpdateOpenModal(false);
      }
    } else {
      toast.custom(
        <SingleErrorMessage
          data={response.message}
          error={true}
          bColor="#D92D20"
          onClose={onCloseToaste}
        />
      );
    }
  };


  useEffect(() => {
    if (tabIndx == 3) {
      handleChangeStatus(2);
    }
    if (tabIndx == 2) {
      handleChangeStatus(5);
    }
    if (tabIndx == 1) {
      handleChangeStatus(1);
    }
    if (tabIndx == 0) {
      handleChangeStatus(0);
    }
  }, []);
  useEffect(() => {
    if (locState == 4) {
      settabIndx(0)
    } else if (locState == 1) {
      settabIndx(1)
    }
    else if (locState == 2) {
      settabIndx(2)
    }
    else if (locState == 3) {
      settabIndx(3)
    }
  }, []);

  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left p-0">
            <div className="payout_data_ttl"> {handleTitle()} Request List</div>
          </div>
          {/* <div className="payout_data-right"> */}

          <Link
            color="info"
            tag="a"
            className="btn btn-primary"
            // onClick={handleShow}
            to="/buyerRequest/add"
          >
            Create New
            <img src={plusWhite} alt="" className="payout_down" />
          </Link>
          {/* </div> */}
        </div>
        <div className="search_container mb-4">
          <SearchBox search={search} setSearch={setSearch} />
        </div>
      </div>
      <div className="tab_box">
        <Tabs selectedIndex={tabIndx} onSelect={(index) => settabIndx(index)}>
          <TabList>
            <Tab
              onClick={() => {
                handleChangeStatus(0);
              }}
            >
              Pending Request
            </Tab>
            <Tab
              onClick={() => {
                handleChangeStatus(1);
              }}
            >
              {" "}
              Confirmed Request
            </Tab>
            <Tab
              onClick={() => {
                handleChangeStatus(5);
              }}
            >
              {" "}
              Inprogress Request
            </Tab>
            <Tab
              onClick={() => {
                handleChangeStatus(2);
              }}
            >
              Closed Request
            </Tab>
          </TabList>
          <TabPanel>
            <BuyerPendingRequest
              search={search}
              setSearch={setSearch}
            />
          </TabPanel>
          <TabPanel>
            <BuyerConfirmedRequest
              search={search}
              setSearch={setSearch}
            />
          </TabPanel>
          <TabPanel>
            <BuyerInprogressRequest
              search={search}
              setSearch={setSearch}
            />
          </TabPanel>
          <TabPanel>
            <BuyerClosedRequest
              search={search}
              setSearch={setSearch}
            />
          </TabPanel>
        </Tabs>

        {/* complet bid model */}
        <Modal
          show={isUpdateOpenModal}
          onHide={() => {
            setIsUpdateOpenModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Bid</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to close bid?</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-dark"
              variant="secondary"
              onClick={() => {
                setIsUpdateOpenModal(false);
              }}
            >
              No
            </button>
            <button
              className="btn btn-success"
              variant="primary"
              onClick={handleUpdateWarehouseStatus}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};
