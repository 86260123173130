import React from "react";
import arr1 from "../assets/images/v_single.png";

export const CelenderCard = ({ data, viewDetails }) => {
  console.log("celender data", data)
  return (
    <div className="card2">
      <div className="card2__header">
        <div className="card2__header-left">{data.orderId}</div>
        <div
          className="card2__header-right"
          onClick={() => viewDetails(data._id)}>
          View Details
          <img
            src={arr1}
            alt="rightArrow"
            className="card2__header-right-arrow"
          />
        </div>
      </div>
      <div className="card2__content">
        <div className="card2__items">
          <div className="card2__item">
            <div className="card2__item-ttl">User Name</div>
            <div className="card2__item-dtl">{data.userDetails?.name}</div>
          </div>
          <div className="card2__item">
            <div className="card2__item-ttl">Product</div>
            <div className="card2__item-dtl">
              {data?.verietyDetails?.name?.en || ""}
            </div>
          </div>
          <div className="card2__item">
            <div className="card2__item-ttl">Quantity</div>
            <div className="card2__item-dtl">
              {data.quantity} {data?.unitDetails?.shortcode || ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
