import { useState, useEffect } from "react";
import LocationSearchInput from "../../common/searchPlaces";
import {
  fetchAllDistricts,
  fetchAllGaons,
  fetchAllStates,
  fetchAllSubDistricts,
  fetchCountry,
  fetchGaons,
} from "../../action/location";
import Select from "react-select";
import { gaonTextBasedOnRoleTYpe } from "../../helper/constants";

export const Addresses = ({
  isRetailerOrExporter,
  setAddresses,
  addresses,
  addressesDetails,
  index,
  editMode,
  editProfileFormik,
  showAddress,
}) => {
  const [searchAddress, setSearchAddress] = useState(
    addressesDetails?.location?.address
      ? addressesDetails?.location?.address
      : addressesDetails?.location || ""
  );
  const role = localStorage.getItem("role");
  const roleType = localStorage.getItem("roleType");
  const [location, setLocation] = useState(addressesDetails?.coordinates || {});
  const [gaonList, setGaonList] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [stateId, setStateId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [blockId, setBlockId] = useState(addressesDetails?.blockId || "");
  const [countryId, setCountryId] = useState("");
  const [viewAddress, setViewAddress] = useState(true);
  useEffect(() => {
    if (showAddress === "Gaon admin" || showAddress === "User") {
      setViewAddress(false);
    } else {
      setViewAddress(true);
    }
  }, [showAddress]);

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    const updatedAddresses = [...addresses];
    updatedAddresses[index] = {
      ...updatedAddresses[index],
      [name]: value,
    };
    setAddresses(updatedAddresses);
  };

  // Handle the location change when a new location is selected
  const handleLocationChange = (location) => {
    setSearchAddress(location.address); // Assuming location has an address property
    setLocation(location);

    const updatedAddresses = [...addresses];
    updatedAddresses[index] = {
      ...updatedAddresses[index],
      location: location, // Store the full address or location name
      coordinates: [location.lng, location.lat], // Store longitude and latitude
    };
    setAddresses(updatedAddresses);
  };

  const fetchGaonList = async () => {
    const response = await fetchGaons(blockId);
    if (response.status) {
      const arr = response?.data.map((item) => {
        return { value: item._id, label: item.name };
      });
      setGaonList(arr);
    }
  };

  const getCountry = async () => {
    const res = await fetchCountry();
    if (res.status) {
      const arr = res?.data?.map((item) => {
        return { value: item._id, label: item.name };
      });
      setCountries(arr);
    }
  };

  const getState = async () => {
    const res = await fetchAllStates(countryId);
    if (res.status) {
      const arr = res?.data?.map((item) => {
        return { value: item._id, label: item.name };
      });
      setStates(arr);
    }
  };

  const getDistrict = async () => {
    const res = await fetchAllDistricts(stateId);
    if (res.status) {
      const arr = res?.data?.map((item) => {
        return { value: item._id, label: item.name };
      });
      setDistricts(arr);
    }
  };

  const getSubDistrict = async () => {
    const res = await fetchAllSubDistricts(districtId);
    if (res.status) {
      const arr = res?.data?.map((item) => {
        return { value: item._id, label: item.name };
      });
      setBlocks(arr);
    }
  };

  useEffect(() => {
    getCountry();
  }, []);

  useEffect(() => {
    getState();
  }, [countryId]);

  useEffect(() => {
    getDistrict();
  }, [stateId]);

  useEffect(() => {
    getSubDistrict();
  }, [districtId]);

  useEffect(() => {
    fetchGaonList();
  }, [blockId]);
  return (
    <div className="address_option">
      {role == "EXPORTER" || role == "RETAILER" || role == "MANUFACTURER" ? (
        <ul>
          <li>
            <label>Location</label>
            <LocationSearchInput
              required={true}
              address={searchAddress}
              setAddress={setSearchAddress}
              setLocation={handleLocationChange} // Pass the handler
              editMode={!editMode}
            />
          </li>
          <li>
            <label>Address</label>
            <input
              type="text"
              name="address" // Specify name for the input
              value={addressesDetails?.address}
              onChange={handleAddressChange} // Handle input change
              disabled={!editMode}
            />
          </li>
          {roleType !== "FARMLINK" && (
            <li>
              <label>Postal code</label>
              <input
                type="text"
                name="postalCode" // Specify name for the input
                value={addressesDetails?.postalCode}
                onChange={handleAddressChange} // Handle input change
                disabled={!editMode}
              />
            </li>
          )}
        </ul>
      ) : (
        <ul>
          {viewAddress && (
            <li>
              <label>Location</label>
              <LocationSearchInput
                required={true}
                address={searchAddress}
                setAddress={setSearchAddress}
                setLocation={handleLocationChange} // Pass the handler
                editMode={!editMode}
              />
            </li>
          )}
          {viewAddress && (
            <li>
              <label>Address</label>
              <input
                type="text"
                name="address" // Specify name for the input
                value={addressesDetails?.address}
                onChange={handleAddressChange} // Handle input change
                disabled={!editMode}
              />
            </li>
          )}

          {roleType !== "FARMLINK" && (
            <li>
              <label>Postal code</label>
              <input
                type="text"
                name="postalCode" // Specify name for the input
                value={addressesDetails?.postalCode}
                onChange={handleAddressChange} // Handle input change
                disabled={!editMode}
              />
            </li>
          )}
          {!isRetailerOrExporter() && (
            <li>
              <label>Country</label>
              {editMode ? (
                <select
                  name="countryId"
                  options={countries}
                  value={
                    countries.find(
                      (option) => option.value === addressesDetails?.countryId
                    ) || null
                  }
                  onChange={(selectedOptions) => {
                    const id = Array.isArray(selectedOptions)
                      ? selectedOptions.map((option) => option.value)
                      : selectedOptions
                      ? [selectedOptions.value]
                      : [];
                    // editProfileFormik.setFieldValue(
                    //   `addresses[${index}].countryId`,
                    //   id
                    // );
                    addressesDetails.countryId = id[0];
                    setCountryId(id);
                  }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  required
                  isDisabled={!editMode}
                />
              ) : (
                <input
                  type="text"
                  name="countryId" // Specify name for the input
                  value={
                    addressesDetails?.countryDetails?.length > 0
                      ? addressesDetails?.countryDetails[0]?.name
                      : ""
                  }
                  disabled
                />
              )}
            </li>
          )}
          {!isRetailerOrExporter() && (
            <li>
              <label>State</label>
              {editMode ? (
                <select
                  name="stateId"
                  options={states}
                  value={
                    states.find(
                      (option) => option.value === addressesDetails?.stateId
                    ) || null
                  }
                  onChange={(selectedOptions) => {
                    const id = Array.isArray(selectedOptions)
                      ? selectedOptions.map((option) => option.value)
                      : selectedOptions
                      ? [selectedOptions.value]
                      : [];
                    // editProfileFormik.setFieldValue(
                    //   `addresses[${index}].stateId`,
                    //   id
                    // );
                    addressesDetails.stateId = id[0];
                    setStateId(id);
                  }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  required
                  isDisabled={!editMode}
                />
              ) : (
                <input
                  type="text"
                  name="stateId" // Specify name for the input
                  value={
                    addressesDetails?.stateDetails?.length > 0
                      ? addressesDetails?.stateDetails[0]?.name
                      : ""
                  }
                  disabled
                />
              )}
            </li>
          )}
          {!isRetailerOrExporter() && (
            <li>
              <label>District</label>
              {editMode ? (
                <select
                  name="districtId"
                  options={districts}
                  value={
                    districts.find(
                      (option) => option.value === addressesDetails?.districtId
                    ) || null
                  }
                  onChange={(selectedOptions) => {
                    const id = Array.isArray(selectedOptions)
                      ? selectedOptions.map((option) => option.value)
                      : selectedOptions
                      ? [selectedOptions.value]
                      : [];
                    editProfileFormik.setFieldValue(
                      `addresses[${index}].districtId`,
                      id[0]
                    );
                    addressesDetails.districtId = id[0];
                    setDistrictId(id);
                  }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  required
                  isDisabled={!editMode}
                />
              ) : (
                <input
                  type="text"
                  name="districtId" // Specify name for the input
                  value={
                    addressesDetails?.districtDetails?.length > 0
                      ? addressesDetails?.districtDetails[0]?.name
                      : ""
                  }
                  disabled
                />
              )}
            </li>
          )}
          {!isRetailerOrExporter() && (
            <li>
              <label>Sub District</label>
              {editMode ? (
                <select
                  name="blockId"
                  options={blocks}
                  value={
                    blocks.find(
                      (option) => option.value === addressesDetails?.blockId
                    ) || null
                  }
                  onChange={(selectedOptions) => {
                    const id = Array.isArray(selectedOptions)
                      ? selectedOptions.map((option) => option.value)
                      : selectedOptions
                      ? [selectedOptions.value]
                      : [];
                    editProfileFormik.setFieldValue(
                      `addresses[${index}].blockId`,
                      id[0]
                    );
                    addressesDetails.blockId = id[0];
                    setBlockId(id);
                  }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  required
                  isDisabled={!editMode}
                />
              ) : (
                <input
                  type="text"
                  name="blockId" // Specify name for the input
                  value={
                    addressesDetails?.blockDetails?.length > 0
                      ? addressesDetails?.blockDetails[0]?.name
                      : ""
                  }
                  disabled
                />
              )}
            </li>
          )}
          {!isRetailerOrExporter() && (
            <li>
              <label>{gaonTextBasedOnRoleTYpe()}</label>
              {editMode ? (
                <select
                  name="gaonId"
                  options={gaonList}
                  value={
                    gaonList.find(
                      (option) => option.value === addressesDetails?.gaonId
                    ) || null
                  }
                  onChange={(selectedOptions) => {
                    const id = Array.isArray(selectedOptions)
                      ? selectedOptions.map((option) => option.value)
                      : selectedOptions
                      ? [selectedOptions.value]
                      : [];
                    addressesDetails.gaonId = id[0];
                    editProfileFormik.setFieldValue(
                      `addresses[${index}].gaonId`,
                      id[0]
                    );
                  }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  required
                  isDisabled={!editMode}
                />
              ) : (
                <input
                  type="text"
                  name="gaonId" // Specify name for the input
                  value={
                    addressesDetails?.gaonDetails?.length > 0
                      ? addressesDetails?.gaonDetails[0]?.name
                      : ""
                  }
                  disabled
                />
              )}
            </li>
          )}
        </ul>
      )}
    </div>
  );
};
