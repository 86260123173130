import React, { useState, useEffect, useRef } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import moment from "moment";
import {
    addWarehouseBid,
    createWarehouseRequest,
    fetchrequestUpdated,
    fetchWarehouseBids,
    fetchwarehousePendingRequestList,
} from "../../action/warehouse";
import { toast } from "react-hot-toast";
import { getProducts, getunits } from "../../action/users";
import { useFormik } from "formik";
import Offcanvas from "react-bootstrap/Offcanvas";

import {
    handleBuyerTitle,
    handleCurrency,
    handleKeyDown,
} from "../../helper/helper";
import { Loader } from "../../components/loader";
import Pagination from "../../components/pagination/paginationView";

import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import searchIcon from "../../assets/images/search-lg.svg";
import { SearchBox } from "../../components/SearchBox";
import { SingleErrorMessage } from "../../pages/singleErrorMessage";
import showNotification from "../notification/showNotification";

export const WarehouseNewRequestList = ({ setTabIndex, setStatus, status, search }) => {
    const navigate = useNavigate();
    const [bidList, setBidList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [paginationValue, setPaginationValue] = useState(10);
    const [id, setId] = useState("");
    const [showBidCanvas, setShowBidCanvas] = useState(false);
    const [units, setUnits] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pendingList, setPendingList] = useState([]);
    const [addressId, setAddressId] = useState("");
    const addBidFormik = useFormik({
        initialValues: {
            bidId: "",
            price: "",
            deliveryDate: "",
            quantity: "",
            unitId: "",
            addressId: "",
        },
        onSubmit: async (values) => {
            values.bidId = id;
            values.addressId = addressId;
            const response = await addWarehouseBid(values);
            if (response.status) {
  
                showNotification(
                    "Apply Bid Request",
                    response.message,
                    "success",
                )
                fetchBuyerPendingList();
                setShowBidCanvas(false);
                addBidFormik.handleReset();
                setTabIndex(1);
                setStatus(4)
            } else {

                showNotification(
                    "Apply Bid Request",
                    response.message,
                    "danger",
                )
                setShowBidCanvas(false);
            }
            addBidFormik.handleReset();
        },
    });
    const fetchBuyerPendingList = async (searchValue = "") => {
        setIsLoading(true);
        const response = await fetchwarehousePendingRequestList(
            currentPage,
            paginationValue,
            searchValue
        );

        if (response.status) {
            setPendingList(response.data.list);
            setTotalRecords(response.data.count);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchBuyerPendingList(search);
    }, [currentPage, paginationValue, search, status]);
    useEffect(() => {
        fetchUnits();
    }, []);

    const fetchUnits = async () => {
        const response = await getunits();
        if (response.status) {
            setUnits(response.data);
        } else {
        }
    };

    const onCloseToaste = () => {
        toast.dismiss();
    };
    useEffect(() => {
        const handleWheel = (event) => {
            if (event.target.type === "number") {
                event.preventDefault();
            }
        };

        // Add event listener to the entire document
        document.addEventListener("wheel", handleWheel, { passive: false });

        return () => {
            // Clean up the event listener
            document.removeEventListener("wheel", handleWheel);
        };
    }, []);
    return (
        <>
            <div className="overflow_auto">
                <table className="payout_table upi-mandate_table">
                    <thead>
                        <tr className="tableHead">
                            <th className="thHeader">
                                <div className="payoutHead hndIcon">Order ID</div>
                            </th>
                            <th className="thHeader">
                                <div className="payoutHead hndIcon">Order Date</div>
                            </th>
                            <th className="thHeader">
                                <div className="payoutHead hndIcon">
                                    {handleBuyerTitle()} Name
                                </div>
                            </th>
                            <th className="thHeader">
                                <div className="payoutHead hndIcon">Role</div>
                            </th>
                            <th className="thHeader">
                                <div className="payoutHead">Delivery Address</div>
                            </th>
                            <th className="thHeader">
                                <div className="payoutHead">MSP</div>
                            </th>
                            <th className="thHeader">
                                <div className="payoutHead">Product</div>
                            </th>
                            <th className="thHeader">
                                <div className="payoutHead">Variety</div>
                            </th>
                            <th className="thHeader">
                                <div className="payoutHead">Grade</div>
                            </th>
                            <th className="thHeader">
                                <div className="payoutHead">Price </div>
                            </th>
                            <th className="thHeader">
                                <div className="payoutHead">Quantity </div>
                            </th>
                            <th className="thHeader">
                                <div className="payoutHead">Total Price </div>
                            </th>
                            <th className="thHeader">
                                <div className="payoutHead">Delivery Date</div>
                            </th>
                            <th className="thHeader">
                                <div className="payoutHead">Action </div>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {isLoading ? (
                            <tr>
                                <td colSpan="14">
                                    <Loader />
                                </td>
                            </tr>
                        ) : pendingList.length > 0 ? (
                            pendingList.map((item) => {
                                return (
                                    <tr key={item._id}>
                                        <td className="noWrap">{item.orderId}</td>
                                        <td className="phone_number noWrap">
                                            {moment(item?.createdAt).format("DD-MM-YYYY")}
                                        </td>
                                        <td className="Status">{item?.userDetails?.name}</td>
                                        <td className="Status">{item?.roleDetails?.name}</td>
                                        <td className="Status">
                                            {item?.location}
                                            {/* {item?.warehouseAddress ? item?.warehouseAddress?.location?.address : item?.warehouseDefaultAddress[0]?.location?.address} */}
                                            {/* <div className="noData"></div> */}
                                        </td>
                                        <td className="table_amount">
                                            <div>
                                                {item?.mspDetails?.minimum_selling_price
                                                    ? item?.mspDetails?.minimum_selling_price +
                                                    " /" +
                                                    item?.mspDetails?.unit?.shortcode
                                                    : "-"}
                                            </div>
                                        </td>
                                        <td className="table_amount">{item?.product}</td>
                                        <td className="table_amount ">
                                            {item?.veriety ?? "-"}
                                        </td>
                                        <td className="">{item?.grade}</td>
                                        <td className="Status bold_txt">
                                            <div>
                                                {handleCurrency()} {item?.price ?? 0}
                                            </div>
                                        </td>
                                        <td className="Status bold_txt">
                                            <div>
                                                {item?.quantity} {item?.unit ? item?.unit : ""}
                                            </div>
                                        </td>
                                        {/* <td className="Status">
                          {item?.warehouseAddress?.address ?? "-"} 
                        </td> */}
                                        <td className="bold_txt">
                                            <div>
                                                {handleCurrency()}{" "}
                                                {(item?.price ?? 0) *
                                                    (item?.quantity ?? 0) *
                                                    (item?.conversion ?? 0)}
                                            </div>
                                        </td>

                                        <td className="noWrap">
                                            {moment(item?.deliveryDate).format("DD-MM-YYYY")}
                                        </td>

                                        <td>
                                            {/* <div className="action_payment"> */}
                                            <button
                                                className="btn btn-primary noWrap"
                                                color="dark"

                                                isLight="btn btn-primary"
                                                tag="a"
                                                onClick={() => {
                                                    let id = item?.warehouseAddress?._id
                                                        ? item?.warehouseAddress?._id
                                                        : item?.warehouseDefaultAddress[0]?._id;
                                                    setAddressId(id);
                                                    setId(item._id);

                                                    // Prefill form values using the selected row data
                                                    addBidFormik.setValues({
                                                        bidId: item._id,
                                                        quantity: item.quantity,
                                                        unitId: item.unitId,
                                                        price: item.price,
                                                        deliveryDate: moment(
                                                            item.deliveryDate
                                                        ).format("YYYY-MM-DD"),
                                                        addressId: id,
                                                    });

                                                    setShowBidCanvas(true);
                                                }}
                                            >
                                                Apply Bid
                                            </button>

                                            {/* </div> */}
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <>
                                <tr>
                                    <td colSpan="15" className="text-align-center">
                                        No data available
                                    </td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </table>{" "}
            </div>
            {pendingList.length > 0 ? <Pagination
                currentPage={currentPage}
                totalCount={totalRecords}
                pageSize={paginationValue}
                onPageChange={(page) => setCurrentPage(page)}
                changePageSize={(page) => {
                    setPaginationValue(page);
                    setCurrentPage(1);
                }}
            /> : ""}

            <Offcanvas
                show={showBidCanvas}
                onHide={() => {
                    setShowBidCanvas(false);
                    addBidFormik.handleReset();
                }}
                placement="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Apply Bid</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="add_buyer_content">
                        <form action="" onSubmit={addBidFormik.handleSubmit}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <p className="modal_desc">
                                        Note: This section is for entering details specific to the
                                        buyer's request, including the available quantity and the
                                        price offered by you in response to the request by the
                                        buyer.
                                    </p>
                                    <div className="input-box">
                                        <div className="input-label">
                                            Quantity
                                            <span>*</span>
                                        </div>
                                        <div className="input_field">
                                            <input
                                                type="number"
                                                className="input"
                                                placeholder="Enter quantity"
                                                id="beneficiary"
                                                name="quantity"
                                                min="1"
                                                max="100000000"
                                                onChange={(e) => {
                                                    addBidFormik.setFieldValue(
                                                        "quantity",
                                                        Number(e.target.value)
                                                    );
                                                }}
                                                value={
                                                    addBidFormik.values.quantity == 0
                                                        ? ""
                                                        : addBidFormik.values.quantity
                                                }
                                                required
                                                onKeyDown={handleKeyDown}
                                            />
                                        </div>
                                    </div>
                                    <div className="input-box">
                                        <div className="input-label">
                                            Unit
                                            <span>*</span>
                                        </div>
                                        <div className="input_field">
                                            <select
                                                className="input"
                                                onChange={(e) => {
                                                    addBidFormik.setFieldValue(
                                                        "unitId",
                                                        e.target.value
                                                    );
                                                }}
                                                value={addBidFormik.values.unitId ?? ""}
                                                required
                                            >
                                                <option value="">Select Unit</option>
                                                {units.map((unit) => {
                                                    return (
                                                        <option
                                                            key={unit._id}
                                                            id={unit._id}
                                                            value={unit._id}
                                                        >
                                                            {unit.shortcode}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="input-box">
                                        <div className="input-label">
                                            Price/kg
                                            <span>*</span>
                                        </div>
                                        <div className="input_field">
                                            <input
                                                type="number"
                                                className="input"
                                                placeholder="Enter price"
                                                id="beneficiary"
                                                name="price"
                                                min="1"
                                                max="1000000"
                                                onChange={(e) => {
                                                    addBidFormik.setFieldValue("price", e.target.value);
                                                }}
                                                value={
                                                    addBidFormik.values.price == 0
                                                        ? ""
                                                        : addBidFormik.values.price
                                                }
                                                onKeyDown={handleKeyDown}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-box">
                                    <div className="input-label">
                                        Select a date before delivery date
                                        <span>*</span>
                                    </div>
                                    <div className="input_field">
                                        <input
                                            required
                                            name="deliveryDate"
                                            className="input"
                                            type="date"
                                            placeholder="select date"
                                            value={addBidFormik.values.deliveryDate}
                                            min={moment(new Date()).format("YYYY-MM-DD")}
                                            onChange={addBidFormik.handleChange}
                                            onKeyDown={(e) => e.preventDefault()}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex_btn mt-3 ">
                                <button className="btn btn-success" type="submit">
                                    Send request
                                </button>
                            </div>
                        </form>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};
