import React, { useEffect, useState } from "react";
import { Loader } from "./../components/loader";
import Pagination from "../components/pagination/paginationView";
import { fetchRevolutionList } from "../action/userApi";

const Revolution = () => {
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalData, setTotalData] = useState();
  const getRevolutionData = async () => {
    setIsLoading(true);
    const response = await fetchRevolutionList(pageNo, pageSize); // Adjust 10 to the desired number of items per page
    if (response.status) {
      setUserList(response.data?.list);
      setTotalData(response.data?.count);
      setIsLoading(false);
      console.log("Revolution data", response.data.count);
    }
  };
  useEffect(() => {
    getRevolutionData();
  }, [pageNo, pageSize]);
  return (
    <div class="payout_data-content">
      <div className="payout_inner_content">
        <div className="payout_data-head">
          <div className="payout_data-head-top">
            <div className="payout_data-left">
              <div className="payout_data_ttl1">Rural Revolution</div>
            </div>
          </div>
        </div>
        <table className="payout_table upi-mandate_table">
          <thead>
            <tr className="tableHead">
              <th className="thHeader">
                <div className="payoutHead hndIcon">Name</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead hndIcon">Email</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead hndIcon">Mobile No</div>
              </th>
              {/* <th className="thHeader">
                        <div className="payoutHead hndIcon">Place</div>
                      </th> */}
              <th className="thHeader">
                <div className="payoutHead hndIcon">Company Name</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead hndIcon">About Us</div>
              </th>
              <th className="thHeader">
                <div className="payoutHead hndIcon">Message</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="6">
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {userList?.length > 0 ? (
                  userList?.map((item) => (
                    <tr key={item?._id}>
                      <td>{item?.name}</td>
                      <td>{item?.email || "-"}</td>
                      <td>{item?.mobile}</td>
                      <td>
                        <div className="userAddress_col">
                          {item?.companyName}
                        </div>
                      </td>
                      <td>
                        {" "}
                        <div className="userAddress_col">{item?.aboutUs}</div>
                      </td>
                      <td>
                        <div className="userAddress_col">{item?.message}</div>
                      </td>
                      {/* <td>{item?.gaon}</td> */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">
                      No data available
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
        <>
          {totalData > 0 ? (
            <Pagination
              currentPage={pageNo}
              totalCount={totalData}
              pageSize={pageSize}
              onPageChange={(page) => setPageNo(page)}
              changePageSize={(page) => {
                setPageSize(page);
                setPageNo(1);
              }}
            />
          ) : (
            <></>
          )}
        </>
      </div>
    </div>
  );
};

export default Revolution;
