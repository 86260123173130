import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/style.css";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { handleCurrency } from "../../helper/helper";
import moment from "moment";
import { fetchRquestDetails } from "../../action/farmerProducts";
import { ImagePopup } from "../../components/common/ImagePopup.js";
import { Link } from "react-router-dom";
import leftArrow from "../../assets/images/navigate_left.svg";
export const FarmerProductDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [bidDetails, setBidDetails] = useState();

  const fetchDetails = async () => {
    const response = await fetchRquestDetails(id);
    if (response.status) {
      setBidDetails(response.data);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, [id]);

  return (
    <div className="payout_data-content request_detail">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left">
            <div className="payout_data_ttl">
              <Breadcrumb
                paths={[
                  {
                    name: "Farmer Product List",
                    link: `/farmer/products`,
                  },
                  {
                    name: "Request Details",
                    link: `/farmer/products/details/${id}`,
                  },
                ]}
              />
            </div>
          </div>
          <div className="payout_data-right">
            <button
              onClick={() => {
                navigate(-1);
              }}
              className="back_btnn"
            >
              {" "}
              <img
                src={leftArrow}
                alt="Arrow"
                className="navi_left"
              /> Back{" "}
            </button>
          </div>
        </div>
      </div>
      <div className="payout_data-content mb-3 user_detail">
        <h4>User Details</h4>
        <div>
          <span>Name : </span> {bidDetails?.userDetails?.name}
        </div>
        <div>
          <span>Mobile : </span> {bidDetails?.userDetails?.mobile}
        </div>
        <div>
          <span>Address : </span> {bidDetails?.gaons?.name}
        </div>
        {bidDetails?.userDetails?.email ? (
          <div>
            <span>Email : </span> {bidDetails?.userDetails?.email}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="payout_data-content bid_detail">
        <h4>Bid Details</h4>
        <div>
          <span>Product : </span>{" "}
          {bidDetails?.product[0]?.verietyDetails[0]?.productDetails?.name.en}{" "}
        </div>
        <div>
          {" "}
          <span>Variety : </span>{" "}
          {bidDetails?.product[0]?.verietyDetails[0]?.name.en}{" "}
        </div>
        <div>
          <span>Quantity : </span>{" "}
          {bidDetails?.status == 1
            ? bidDetails?.acceptedQty
            : bidDetails?.quantity}{" "}
          {bidDetails?.unit}
        </div>
        <div>
          <span>Grade : </span> {bidDetails?.grade}
        </div>
        <div>
          <span>Price : </span> {handleCurrency()} {bidDetails?.price}
        </div>
        <div>
          <span>Request Date : </span>{" "}
          {moment(bidDetails?.createdAt).format("DD-MM-YYYY")}
        </div>
        <div className="uploaded_img">
          <ul>
            {bidDetails?.pics &&
              bidDetails.pics.map((url, index) => (
                <li key={index} className="img_list_item">
                  <ImagePopup
                    className="img_click"
                    src={`${process.env.REACT_APP_S3_URL}/${url}`}
                    alt="product"
                  />
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
