import React, { useState, useEffect, useRef } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/style.css";
import "../../styles/payout.css";
import "../../styles/upi-mandate.css";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import moment from "moment";
import { toast } from "react-hot-toast";
import { SingleErrorMessage } from "../singleErrorMessage";
import { getunits } from "../../action/users";
import { useFormik } from "formik";
import Offcanvas from "react-bootstrap/Offcanvas";

import { handleKeyDown } from "../../helper/helper";
import ReactSelect from "react-select";

import {
  bidFarmerRequest,
  completeBid,
  fetchNewFarmerRequestList,
  fetchReject,
  InprogressRequests,
} from "../../action/farmerProducts";
import { fetchAddresses } from "../../action/location";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import leftArrow from "../../assets/images/navigate_left.svg";
import { SearchBox } from "../../components/SearchBox";
import NewReq from "../../components/farmerProductsComponents/NewRequests";
import AppliedReq from "../../components/farmerProductsComponents/AppliedRequests";
import ConfirmReq from "../../components/farmerProductsComponents/ConfirmRequests";
import RejectReq from "../../components/farmerProductsComponents/RejectRequests";
import ClosedReq from "../../components/farmerProductsComponents/ClosedRequests";
import showNotification from "../../components/notification/showNotification";

export const FarmerProducts = () => {
  const navigate = useNavigate();

  const [showBidCanvas, setShowBidCanvas] = useState(false);
  const [units, setUnits] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [isUpdateOpenModal, setIsUpdateOpenModal] = useState(false);
  const [selectOption, setSelectOption] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [tabIndx, settabIndx] = useState(0);
  const [search, setSearch] = useState("");

  const addBidFormik = useFormik({
    initialValues: {
      parentId: "",
      price: "",
      deliveryDate: "",
      quantity: "",
      unitId: "",
      addressId: "",
    },
    onSubmit: async (values) => {
      if (!selectedOption?.value) {
        showNotification(
          "Select Address",
          "Please select delivery address",
          "danger"
        );
        return;
      }
      values.addressId = selectedOption.value;

      const response = await bidFarmerRequest(values);
      if (response.status) {
        settabIndx(1);
        showNotification("Applied Bid Request", response.message, "success");

        setShowBidCanvas(false);
      } else {
        showNotification("Applied Bid Request", response.message, "danger");
        setShowBidCanvas(false);
      }
      addBidFormik.handleReset();
    },
  });

  const fetchUnits = async () => {
    const response = await getunits();
    if (response.status) {
      setUnits(response.data);
    }
  };

  const fetchDeliveryAddress = async () => {
    const response = await fetchAddresses();
    if (response.status) {
      setAddressList(response.list);
    }
  };

  const handleUpdateBid = async () => {
    const response = await completeBid(addBidFormik.values.parentId);
    if (response.status) {
      setIsUpdateOpenModal(false);
      settabIndx(4);

      showNotification("Confirm Bid Request ", response.message, "success");
    } else {
      showNotification("Confirm Bid Request", response.message, "danger");
    }
  };

  useEffect(() => {
    fetchUnits();
    fetchDeliveryAddress();
  }, []);

  const statusColorMapping = {
    6: "failed",
    2: "success",
  };
  const getStatusClassName = (status) => {
    return statusColorMapping[status] || "";
  };

  useEffect(() => {
    const handleWheel = (event) => {
      if (event.target.type === "number") {
        event.preventDefault();
      }
    };

    // Add event listener to the entire document
    document.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      // Clean up the event listener
      document.removeEventListener("wheel", handleWheel);
    };
  }, []);

  const ShowBidCanvasFunc = (show) => {
    setShowBidCanvas(show);
  };
  const Getaddress = (address) => {
    setSelectOption(address);
  };

  const handleUpdateModel = (model) => {
    setIsUpdateOpenModal(model);
  };
  return (
    <div className="payout_data-content">
      <div className="payout_data-head">
        <div className="payout_data-head-top">
          <div className="payout_data-left p-0">
            <div className="payout_data_ttl">
              Request List
              {/* <div className="hits">1321 Hits</div> */}
            </div>
            {/* <div className="payout_data-desc">
              Keep track of your daily payouts.
            </div> */}
          </div>
          <div className="payout_data-right">
            <button
              onClick={() => {
                navigate(-1);
              }}
              className="back_btnn"
            >
              {" "}
              <img
                src={leftArrow}
                alt="Arrow"
                className="navi_left"
              /> Back{" "}
            </button>
          </div>
        </div>
      </div>
      <div className="search_container mb-4">
        <SearchBox search={search} setSearch={setSearch} />
      </div>
      <div className="tab_box">
        <Tabs selectedIndex={tabIndx} onSelect={(index) => settabIndx(index)}>
          <TabList>
            <Tab>New Requests</Tab>
            <Tab>Applied Requests</Tab>
            <Tab>Confirmed Request</Tab>
            <Tab>Rejected Request</Tab>
            <Tab>Closed Request</Tab>
          </TabList>

          <TabPanel>
            <NewReq
              search={search}
              ShowBidCanvasFunc={ShowBidCanvasFunc}
              addBidFormik={addBidFormik}
              addressList={addressList}
              Getaddress={Getaddress}
            />
          </TabPanel>

          <TabPanel>
            <AppliedReq search={search} />
          </TabPanel>

          <TabPanel>
            <ConfirmReq
              search={search}
              addBidFormik={addBidFormik}
              handleUpdateModel={handleUpdateModel}
            />
          </TabPanel>

          <TabPanel>
            <RejectReq search={search} />
          </TabPanel>

          <TabPanel>
            <ClosedReq
              search={search}
              getStatusClassName={getStatusClassName}
            />
          </TabPanel>
        </Tabs>
        {/* off canvas to create warehouse new request to farmer*/}
        <Offcanvas
          show={showBidCanvas}
          onHide={() => {
            setShowBidCanvas(false);
            addBidFormik.handleReset();
          }}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Apply Bid</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="add_buyer_content">
              <form action="" onSubmit={addBidFormik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-12">
                    <p className="modal_desc">
                      Note: This section is for entering the request details to
                      be sent to the farmer, including the required quantity,
                      the offered price, and the preferred delivery date for the
                      products.
                    </p>
                    <div className="input-box">
                      <div className="input-label">
                        Delivery Address
                        <span>*</span>
                      </div>

                      <ReactSelect
                        options={selectOption}
                        value={selectedOption}
                        onChange={(e) => {
                          setSelectedOption(e);
                        }}
                        placeholder="Select Delivery Address"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="input-box">
                      <div className="input-label">
                        Quantity
                        <span>*</span>
                      </div>
                      <div className="input_field">
                        <input
                          type="number"
                          className="input"
                          placeholder="Enter quantity"
                          id="beneficiary"
                          name="quantity"
                          max="100000000"
                          onChange={(e) => {
                            addBidFormik.setFieldValue(
                              "quantity",
                              Number(e.target.value)
                            );
                          }}
                          value={
                            addBidFormik.values.quantity == 0
                              ? ""
                              : addBidFormik.values.quantity
                          }
                          required
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="input-box">
                      <div className="input-label">
                        Unit
                        <span>*</span>
                      </div>
                      <div className="input_field">
                        <select
                          className="input"
                          value={addBidFormik?.values?.unitId ?? ""}
                          onChange={(e) => {
                            addBidFormik.setFieldValue(
                              "unitId",
                              e.target.value
                            );
                          }}
                          required
                        >
                          <option value="">Select Unit</option>
                          {units.map((unit) => {
                            return (
                              <option
                                key={unit._id}
                                id={unit._id}
                                value={unit._id}
                              >
                                {unit.shortcode}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="input-box">
                      <div className="input-label">
                        Price/Kg
                        <span>*</span>
                      </div>
                      <div className="input_field">
                        <input
                          type="number"
                          className="input"
                          placeholder="Enter price"
                          id="beneficiary"
                          name="price"
                          max="1000000"
                          onChange={(e) => {
                            addBidFormik.setFieldValue(
                              "price",
                              Number(e.target.value)
                            );
                          }}
                          value={
                            addBidFormik.values.price == 0
                              ? ""
                              : addBidFormik.values.price
                          }
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="input-box">
                      <div className="input-label">
                        Select a delivery date
                        <span>*</span>
                      </div>
                      <div className="input_field">
                        <input
                          required
                          name="deliveryDate"
                          className="input"
                          type="date"
                          placeholder="select date"
                          value={addBidFormik.values.deliveryDate}
                          min={moment(new Date()).format("YYYY-MM-DD")}
                          onChange={addBidFormik.handleChange}
                          onKeyDown={(e) => e.preventDefault()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex_btn mt-3 ">
                  <button className="btn btn-success" type="submit">
                    Send Request
                  </button>
                </div>
              </form>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        <Modal
          show={isUpdateOpenModal}
          onHide={() => {
            setIsUpdateOpenModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Complete Bid</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure want to complete this request?</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-dark"
              variant="secondary"
              onClick={() => {
                setIsUpdateOpenModal(false);
              }}
            >
              No
            </button>
            <button
              className="btn btn-success"
              variant="primary"
              onClick={handleUpdateBid}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};
