import { RouterProvider } from "react-router-dom";
import route from "../src/routes/route";
import "./styles/payout.css";
import "./styles/style.css";
import "./styles/dashboard.css";
import "./styles/addPayout.css";
import "./styles/bulkUpload.css";
import "./styles/upi-mandate.css";
import "./styles/pagination.css";
import "./styles/table.css";
import "./styles/summary.css";
import "./styles/successPayout.css";
import "./styles/payIn.css";

function App() {
  console.log("domain Name", window.location.hostname);   // Logs the domain

  const role = localStorage.getItem("roleType");
  document.getElementsByTagName("TITLE")[0].text =
    role == "GAON" ? "TheGaon" : "Farmlink";

  return <RouterProvider router={route} />;
}

export default App;
